import React, { Component, PropTypes } from "react";
import Translate from "react-translate-component";
import { TableColumn } from "../../libs/react-mpk";
import {
  Tabs,
  Tab,
  List,
  ListItem,
  Avatar,
  FontIcon,
  TextField,
  TabsContainer
} from "react-md";
import TableSearchCustom from "./TableSearchCustom";
import { METHODS } from "http";

export default class TableSettingCustom extends Component {
  static defaultProps = {
    tabs: ["search", "column"],
    tabComponents: {},
    width: 240
  };

  constructor() {
    super();
    this.state = {
      currentTab: 0
    };
  }

  onTabChange = (index, tabId) => {
    this.setState({ currentTab: index });
  };

  switchTab() {
    if (this.props.tabs[this.state.currentTab] === "search") {
      return (
        <TableSearchCustom
          table={this.props.table}
          dispatch={this.props.dispatch}
          tableFilter={this.props.tableFilter}
          tableFilter2={this.props.tableFilter2}
          service={this.props.service}
          columns={this.props.table.columns}
          tableActions={this.props.tableActions}
          onSearch={this.props.onSearch}
          searchForm={this.props.searchForm}
        />
      );
    } else if (this.props.tabs[this.state.currentTab] === "column") {
      return (
        <TableColumn
          translate={this.props.translate}
          columns={this.props.table.columns}
          tableActions={this.props.tableActions}
        />
      );
    } else {
      return this.props.tabComponents[this.props.tabs[this.state.currentTab]]();
    }
  }

  render() {
    return (
      <div
        style={{ width: this.props.width }}
        className="mpk-full height mpk-layout column"
      >
        <Tabs
          className="mpk-tab-white"
          tabId="simple-tab"
          mobile={true}
          onTabChange={this.onTabChange}
        >
          {this.props.tabs.map((d, i) => (
            <Tab
              key={i}
              label={<Translate content={"word." + String(d).toLowerCase()} />}
            />
          ))}
        </Tabs>
        <div className="flex">{this.switchTab()}</div>
      </div>
    );
  }
}
