
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import env from 'env';
import izitoast from 'izitoast';

function defer() {
  const d = { }
  d.promise = new Promise((y, n) => (d.resolve = y, d.reject = n))
  return d
}

export default {
  loc: window.location,
  url: '',
  token: '',

  client: {},

  connected: defer(),
  isConnected: false,
  subscriptionList: [],

  current: {
    unsubscribe: () => {}
  },

  defer,

  buildUrl() {
    var host = env.host.ws;
    if(host === '') host = window.location.host;
    this.token = JSON.parse(localStorage.getItem('token')).access_token;
    this.url = '//' +  host + '/ws?access_token=' + this.token;
  },

  async subscribe(topic, action, directUrl=false) {
    await this.connected.promise;
    var url = '/topic/'+topic;
    if(directUrl) url = topic;
    this.current = this.client.subscribe(url, (data)=> {
      action(data)
    });
  },

  async unsubscribe(topic, action) {
    this.current.unsubscribe()
  },

  connect() {
    this.buildUrl()
    var socket = new SockJS(this.url);
    this.client = Stomp.over(socket);

    var token = JSON.parse(localStorage.getItem('token'));

    console.info('[WEBSOCKET]', 'Connecting...');
    izitoast.info({title: 'Info', message: 'Connecting to Server...'})

    console.warn('access', {
      Authorization: 'Bearer '+token.access_token
    })

    this.client.connect({
      Authorization: 'Bearer '+token.access_token
    }, (frame) => {
      izitoast.success({title: 'Info', message: 'Connected'})
      this.connected.resolve("success");
      this.isConnected = true;
      console.info('[WEBSOCKET]', 'websocket was connected');
    }, (t) => {
      console.error('[WEBSOCKET]', 'websocket was disconnected', t);
      izitoast.error({title: 'Info', message: 'Disconnected'})
      this.connected = this.defer();

      izitoast.show({
        title: 'Reconnect?',
        message: '',
        timeout: 0,
        progressBarColor: 'rgb(0, 255, 184)',
        buttons: [
            ['<button>Ok</button>', (instance, toast)=> {
                this.connect()
                instance.hide({
                  transitionOut: 'fadeOutUp'
              }, toast, 'buttonName');
            }, true], // true to focus
            ['<button>Close</button>', (instance, toast)=> {
                instance.hide({
                    transitionOut: 'fadeOutUp',
                }, toast, 'buttonName');
            }]
        ]
      });

      // this.reconnect();
    });
  },

  reconnect() {
    console.info('[WEBSOCKET]', 'Reconnecting in 3 second...');
    izitoast.info({title: 'Info', message: 'Reconnecting in 3 second...'});
    this.connected = this.defer();
    setTimeout(()=> {
      this.buildUrl()

      var socket = new SockJS(this.url);
      this.client = Stomp.over(socket);

      console.info('[WEBSOCKET]', 'Connecting...');
      izitoast.info({title: 'Info', message: 'Connecting to Server...'})

      this.client.connect({}, (frame) => {
        izitoast.success({title: 'Info', message: 'Connected'})
        this.connected.resolve("success");
        this.isConnected = true;
        console.info('[WEBSOCKET]', 'websocket was connected');
      }, (t) => {
        console.error('[WEBSOCKET]', 'websocket was disconnected', t);
        izitoast.error({title: 'Info', message: 'Disconnected'})
        this.connected = this.defer();
        this.reconnect();
      });


    }, 3000);
  }
}
