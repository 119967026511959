import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues, change} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  Card,
  CardActions,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer,
  Tab,
  Tabs,
  TabsContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox, Progress} from '../../../components/form/index';
import LaporanBulananService from './LaporanBulananService';
import utilsService from '../../../services/utils.service';
import counterpart from 'counterpart';
import PernyataanSaldoView from './PernyataanSaldoView';
import RekeningKoranLaporanView from './RekeningKoranLaporanView';
import http from '../../../services/http.service';
import hosts from '../../../services/hosts';
import iziToast from 'izitoast';
import download from 'downloadjs'

@reduxForm({form: 'LaporanBulananForm', destroyOnUnmount: true})
@connect((state) => ({
  auth        : state.auth.user,
  formData    : getFormValues('LaporanBulananForm')(state)
}))
export default class LaporanBulananForm extends FormView {
  service = LaporanBulananService
  viewType=2

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isRincian: false,
      permissionMap: {}
    }
  }

  componentDidMount() {
    this.initData()

    var pList = []
    if(this.props.auth && this.props.auth.servicePermission) pList = this.props.auth.servicePermission
    var pMap = pList.reduce(function(pv, cv) {
      pv[cv.method+"__"+cv.clientPath] = cv
      return pv
    }, {})

    this.setState({permissionMap: pMap})
  }

  pernyataanSaldoFetch = ()=> {}
  rekeningKoranFetch = ()=> {}
  initialData={
  }



  // async initData() {
  // }

  formView() {
    return <div>
      <Card>
        <div className='md-grid'>
        <Field
          label         = 'Bulan'
          name          = 'month'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {[
            {id: 1, name: 'Januari'},
            {id: 2, name: 'Februari'},
            {id: 3, name: 'Maret'},
            {id: 4, name: 'April'},
            {id: 5, name: 'Mei'},
            {id: 6, name: 'Juni'},
            {id: 7, name: 'Juli'},
            {id: 8, name: 'Agustus'},
            {id: 9, name: 'September'},
            {id: 10, name: 'Oktober'},
            {id: 11, name: 'November'},
            {id: 12, name: 'Desember'}
          ]}
          validate      = {validation.required}
          disabled = {this.props.match.params.id != 'new'}
        />
          <Field
            label         = {"Tahun"}
            name          = 'year'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
            disabled = {this.props.match.params.id != 'new'}
          />
        </div>
      </Card>

      <br/>

      {this.props.match.params.id != 'new' && <Card>
        <TabsContainer colored panelClassName="asd" panelStyle={{overflow: 'hidden'}}>
          <Tabs tabId="simple-tab">
            <Tab label="Profile">
              <div className="md-grid">
                <Field
                  label         = 'Jumlah Transaksi'
                  name          = 'total'
                  className     = 'md-cell md-cell--12'
                  component     = {Textfield}
                  disabled
                />
              </div>

            </Tab>
            <Tab label="Pernyataan Saldo">
              <div className="md-grid">
                {this.props.formData && this.props.formData.pernyataanSaldo && <Button disabled className= 'md-cell md-cell--4 md-grid'>Status {this.props.formData.pernyataanSaldo.status}</Button>}
                <Button primary raised onClick={() => this.generatePs()} className     = 'md-cell md-cell--4 md-grid'>Generate PS</Button>
                {this.props.formData && this.props.formData.pernyataanSaldo && this.props.formData.pernyataanSaldo.status == 'PREPARED' &&<Button secondary raised onClick={() => this._downloadPS()} className     = 'md-cell md-cell--4 md-grid'>Download</Button>}
              </div>


              <PernyataanSaldoView parentId={this.props.match.params.id} setParent={this._setParent} />
            </Tab>
            <Tab label="Rekening Koran">
              <div className="md-grid">
                {this.props.formData && this.props.formData.rekeningKoranBulanan && <Button disabled className= 'md-cell md-cell--4 md-grid'>Status {this.props.formData.rekeningKoranBulanan.status}</Button>}
                <Button primary raised onClick={() => this.generateRk()} className     = 'md-cell md-cell--4 md-grid'>Generate RK</Button>
                {this.props.formData && this.props.formData.rekeningKoranBulanan && this.props.formData.rekeningKoranBulanan.status == 'PREPARED' &&<Button secondary raised onClick={() => this._downloadRk()} className     = 'md-cell md-cell--4 md-grid'>Download</Button>}
              </div>

              <RekeningKoranLaporanView parentId={this.props.match.params.id} setParent={this._setParent} />
            </Tab>
          </Tabs>
        </TabsContainer>
      </Card>}
    </div>
  }



  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

        {this.state.permissionMap['PUT__/transaction_type/{id}'] && <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>}
      </div>
    )
  }

  async beforeSave(val) {
    val.year = parseInt(val.year)
    return val
  }

  async generatePs() {



    var token = http.defaults.headers.common['Authorization'].replace("Bearer ", "")
    var url = (hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl+`/websocket/pelaporan?access_token=${token}`).replace('http', 'ws').replace('https', 'wss')
    var socket = new WebSocket(url)

    var post = await this.service.api.generatePs(this.props.match.params.id)


    socket.onopen = async (e) => {
      iziToast.success({title: 'Info', message: 'Data sedang di generate'})
      console.log(`[/websocket/pelaporan/${this.props.match.params.id}][open] Connection established`);
    }

    socket.onmessage = (event) => {
      console.log(`/websocket/pelaporan/${this.props.match.params.id}][message] Data received from server: ${event.data}`);
      // this.initData()
      if(event.data.search("{") >= 0) {
        var data = JSON.parse(event.data)

        if(data.error) {
          iziToast.error({title: "Warning", message: data.error})
        }

        if(data.data) {
          this.initData()
          this.pernyataanSaldoFetch()

          console.log(this.pernyataanSaldoFetch, 'asd')

          iziToast.success({title: "success", message: "Data telah di generate"})
        }
      }
    };

    socket.onclose = (event)=> {
      if (event.wasClean) {
        console.log(`[/websocket/pelaporan/${this.props.match.params.id}][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.log(`[/websocket/pelaporan/${this.props.match.params.id}][close] Connection died`);
      }
    };

    socket.onerror = (error) => {
      console.log(`[error] ${error.message}`);
    };
  }

  async _downloadPS(type) {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Download Report',
      initialValue: {d : 'asd'},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan men-download?"
    }))

    if(p) {

      var res = await this.service.api.reqPs(this.props.match.params.id)

      var token = http.defaults.headers.common['Authorization'].replace("Bearer ", "")
      var url = (hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl+`/websocket/report/pernyataan_saldo/${this.props.match.params.id}?access_token=${token}`).replace('http', 'ws').replace('https', 'wss')
      var socket = new WebSocket(url)
      var progress = this.context.showDialog((props, res, rej) =>({
        title: 'Mohon ditunggu',
        initialValue: {progress: undefined},
        height: 'auto',
        width: 400,
        okText: 'Submit',
        disableOk: true,
        cancelText: 'Close',
        // contentStyle: {overflow: 'inherit'},
        text: <div className="md-grid">
          <Field
              label         = {"Nama File"}
              name          = 'progress'
              className     = 'md-cell md-cell--12'
              component     = {Progress}
            />
        </div>
      }))

      var progressNum = undefined;
      var interval = setInterval(()=> {
        this.props.dispatch(change('global_dialog', 'progress', undefined))
      }, 1000)

      socket.onopen = async (e) => {
        iziToast.success({title: 'Info', message: 'File Sedang di proses'})

        console.log(`[/websocket/report/pernyataan_saldo/${this.props.match.params.id}][open] Connection established`);
      }

      socket.onmessage = async (event) => {
        console.log(`[/websocket/report/pernyataan_saldo/${this.props.match.params.id}][message] Data received from server: ${event.data}`);
        // this.initData()
        if(event.data.search("{") >= 0) {
          var data = JSON.parse(event.data)
          if(data.count) {
            progressNum = parseInt(data.count)
            this.props.dispatch(change('global_dialog', 'progress', progressNum))
          }

          if(data.file) {
            this.props.dispatch(change('global_dialog', 'progress', 100))
            var dInfo = await this.context.showDialog((props, res, rej) =>({
              title: 'Berhasil',
              height: 'auto',
              width: 400,
              okText: 'Download',
              // disableOk: true,
              cancelText: 'Close',
              // contentStyle: {overflow: 'inherit'},
              text: 'File report telah terbentuk'
            }))

            if(dInfo) {
              let res = await this.service.api.download(data.file);
              let filename = data.file
              download(res.data, filename);
            }
          }
        }
      };

      socket.onclose = async (event)=> {
        if (event.wasClean) {
          console.log(`[/websocket/report/pernyataan_saldo/${this.props.match.params.id}][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
          console.log(`[/websocket/report/pernyataan_saldo/${this.props.match.params.id}][close] Connection died`);
        }

      };

      socket.onerror = (error) => {
        console.log(`[error] ${error.message}`);
      };
    }
  }

  async generateRk() {



    var token = http.defaults.headers.common['Authorization'].replace("Bearer ", "")
    var url = (hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl+`/websocket/pelaporan?access_token=${token}`).replace('http', 'ws').replace('https', 'wss')
    var socket = new WebSocket(url)

    var post = await this.service.api.generateRk(this.props.match.params.id)


    socket.onopen = async (e) => {
      iziToast.success({title: 'Info', message: 'Data sedang di generate'})
      console.log(`[/websocket/pelaporan/${this.props.match.params.id}][open] Connection established`);
    }

    socket.onmessage = (event) => {
      console.log(`/websocket/pelaporan/${this.props.match.params.id}][message] Data received from server: ${event.data}`);
      // this.initData()
      if(event.data.search("{") >= 0) {
        var data = JSON.parse(event.data)

        if(data.error) {
          iziToast.error({title: "Warning", message: data.error})
        }

        if(data.data) {
          this.initData()
          this.rekeningKoranFetch()


          iziToast.success({title: "success", message: "Data telah di generate"})
        }
      }
    };

    socket.onclose = (event)=> {
      if (event.wasClean) {
        console.log(`[/websocket/pelaporan/${this.props.match.params.id}][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.log(`[/websocket/pelaporan/${this.props.match.params.id}][close] Connection died`);
      }
    };

    socket.onerror = (error) => {
      console.log(`[error] ${error.message}`);
    };
  }

  async _downloadRk(type) {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Download Report',
      initialValue: {d : 'asd'},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan men-download?"
    }))

    if(p) {

      var res = await this.service.api.reqRk(this.props.match.params.id)

      var token = http.defaults.headers.common['Authorization'].replace("Bearer ", "")
      var url = (hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl+`/websocket/report/rekening_koran_bulanan/${this.props.match.params.id}?access_token=${token}`).replace('http', 'ws').replace('https', 'wss')
      var socket = new WebSocket(url)
      var progress = this.context.showDialog((props, res, rej) =>({
        title: 'Mohon ditunggu',
        initialValue: {progress: undefined},
        height: 'auto',
        width: 400,
        okText: 'Submit',
        disableOk: true,
        cancelText: 'Close',
        // contentStyle: {overflow: 'inherit'},
        text: <div className="md-grid">
          <Field
              label         = {"Nama File"}
              name          = 'progress'
              className     = 'md-cell md-cell--12'
              component     = {Progress}
            />
        </div>
      }))

      var progressNum = undefined;
      var interval = setInterval(()=> {
        this.props.dispatch(change('global_dialog', 'progress', undefined))
      }, 1000)

      socket.onopen = async (e) => {
        iziToast.success({title: 'Info', message: 'File Sedang di proses'})

        console.log(`[/websocket/report/rekening_koran_bulanan/${this.props.match.params.id}][open] Connection established`);
      }

      socket.onmessage = async (event) => {
        console.log(`[/websocket/report/rekening_koran_bulanan/${this.props.match.params.id}][message] Data received from server: ${event.data}`);
        // this.initData()
        if(event.data.search("{") >= 0) {
          var data = JSON.parse(event.data)
          if(data.count) {
            progressNum = parseInt(data.count)
            this.props.dispatch(change('global_dialog', 'progress', progressNum))
          }

          if(data.file) {
            this.props.dispatch(change('global_dialog', 'progress', 100))
            var dInfo = await this.context.showDialog((props, res, rej) =>({
              title: 'Berhasil',
              height: 'auto',
              width: 400,
              okText: 'Download',
              // disableOk: true,
              cancelText: 'Close',
              // contentStyle: {overflow: 'inherit'},
              text: 'File report telah terbentuk'
            }))

            if(dInfo) {
              let res = await this.service.api.download(data.file);
              let filename = data.file
              download(res.data, filename);
            }
          }
        }
      };

      socket.onclose = async (event)=> {
        if (event.wasClean) {
          console.log(`[/websocket/report/rekening_koran_bulanan/${this.props.match.params.id}][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
          console.log(`[/websocket/report/rekening_koran_bulanan/${this.props.match.params.id}][close] Connection died`);
        }

      };

      socket.onerror = (error) => {
        console.log(`[error] ${error.message}`);
      };
    }
  }

  _setParent = (d, func)=> {
    this[d] = func
  }


}
