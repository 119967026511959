import * as CONSTANT from '../../config/constant';

const none = [
  //   {
  //   label: {
  //     id: 'Home',
  //     en: 'Home'
  //   },
  //   children: [{
  //     label: {
  //       id: 'Dashboard',
  //       en: 'Dashboard'
  //     },
  //     iconClassName: 'mdi mdi-view-list',
  //     path: '/',
  //     permission: ''
  //   }]
  // }
  ]

  export default {
    //root
    none,
  }
