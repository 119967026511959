import React, { Component, PureComponent } from "react";
import { Commandbar } from "../../libs/react-mpk";
import { Button, Card, Grid, Cell, LinearProgress, FontIcon } from "react-md";
import izitoast from "izitoast";
import counterpart from "counterpart";

export default class FormView extends Component {
  service;
  translate = true;
  initialData = {};
  viewType = 1;

  toast = izitoast;

  static contextTypes = {
    showDialog: "React.PropTypes.func"
  };

  titleHeader() {
    return `entities.${this.service.name}.title`;
  }

  constructor(props) {
    super(props);
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    this.initData();
  }

  async initData() {
    if (this.props.match.params.id == "new") {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
    }
  }

  async beforeSave(val) {
    return true;
  }

  async afterSave(res, val) {}

  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if (beforeSave) {
      if (typeof beforeSave === "object") value = beforeSave;
      try {
        this.setState({ onProgress: true });
        if (value.id) {
          res = await this.service.api.update(value);
        } else {
          res = await this.service.api.save(value);
        }
        this.setState({ onProgress: false });
        this.afterSave(res, value);

        var pathArray = this.props.location.pathname.split("/");
        var path = "";
        pathArray.forEach((d, i) => {
          if (pathArray.length - 1 != i) {
            path = path + d;
            if (i != pathArray.length - 2) path = path + "/";
          }
        });

        this.props.history.push(path);
      } catch (e) {
        var msg = e.message;
        if (e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        });
      }
    }
  }

  render() {
    if (this.viewType === 1) {
      return this.view1();
    } else {
      return this.view2();
    }
  }

  view1() {
    return (
      <this.viewContainer>
        {this.commandBar()}
        <div className="mpk-padding-N all flex">
          {this.state.onProgress ? (
            <LinearProgress style={{ margin: 0 }} query={true} />
          ) : null}

          <Card className="mpk-dialog mpk-content column">
            {this.formView()}
          </Card>
        </div>
        {this.bottomBar()}
      </this.viewContainer>
    );
  }

  view2() {
    return (
      <this.viewContainer>
        {this.commandBar()}
        <div className="mpk-padding-N all flex mpk-content">
          {this.state.onProgress ? (
            <LinearProgress style={{ margin: 0 }} query={true} />
          ) : null}

          {this.formView()}
        </div>
        {this.bottomBar()}
      </this.viewContainer>
    );
  }

  errorView() {
    if (this.state.isError) {
      return (
        <div className="error-block flex-none md-cell md-cell--12">
          <FontIcon>error</FontIcon>
          <div>{this.state.errorMessage}</div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  formView() {
    return <div />;
  }

  _viewContainer(props) {
    return (
      <div className="mpk-layout column fill" style={{ overflow: "hidden" }}>
        {props.children}
      </div>
    );
  }

  viewContainer = props => {
    return this._viewContainer(props);
  };

  _barActions = [];

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate("word.save");
    if (this.props.match.params.id == "new")
      saveWord = counterpart.translate("word.create");
    if (this.props.match.params.sptId == "new")
      saveWord = counterpart.translate("word.create");

    return (
      <div>
        <Button
          secondary
          flat
          onClick={() => {
            this.props.history.goBack();
          }}
          style={{ marginRight: 16 }}
        >
          {counterpart.translate("word.back")}
        </Button>

        <Button
          raised
          primary
          onClick={handleSubmit(this.handleSave.bind(this))}
        >
          {saveWord}
        </Button>
      </div>
    );
  }

  barItem() {
    return this._barItem();
  }

  _commandBar(props) {
    var barActions = this._barActions;
    if (this.barActions) barActions = this.barActions;

    return (
      <Commandbar
        title={this.titleHeader()}
        translate={this.translate}
        actions={barActions}
        rightCorner={this.barItem()}
      />
    );
  }

  bottomBar() {
    return null;
  }

  commandBar(props) {
    return this._commandBar(props);
  }
}
