import * as CONSTANT from '../../config/constant';

const mainMenus = [
//   {
//   label: {
//     id: 'Pembayaran',
//     en: 'Payment'
//   },
//   children: [{
//     label: {
//       id: 'Bayar',
//       en: 'Payment'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/payment/new'
//   }, {
//     label: {
//       id: 'Daftar Bayar',
//       en: 'Payment List'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/payment'
//   }]
// },
{
  label: {
    id: 'Pelaporan',
    en: 'Reporting'
  },
  children: [
  {
    label: {
      id: 'Daftar Bayar (All)',
      en: 'Payment List (All)'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/payment-all',
    ssoPath: 'GET__/mpn_payment'
  },
  {
    label: {
      id: 'Daftar Mutasi',
      en: 'Mutation List'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/mutasi',
    ssoPath: 'GET__/balance'
  },
  // {
  //   label: {
  //     id: 'Notif Bank',
  //     en: 'Bank Notif'
  //   },
  //   iconClassName: 'mdi mdi-view-grid',
  //   path: '/notif-bank',
  // }
  ,{
    label: {
      id: 'Pelimpahan',
      en: 'Pelimpahan'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/pelimpahan',
    ssoPath: 'GET__/pelimpahan'
  },
  // {
  //   label: {
  //     id: 'Rekening Koran',
  //     en: 'Rekening Koran'
  //   },
  //   iconClassName: 'mdi mdi-view-grid',
  //   path: '/rekening-koran'
  // },
  {
    label: {
      id: 'Pelaporan',
      en: 'Pelaporan'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/pelaporan',
    ssoPath: 'GET__/lhp'
  },
  // {
  //   label: {
  //     id: 'Pengaturan',
  //     en: 'setting'
  //   },
  //   iconClassName: 'mdi mdi-view-grid',
  //   path: '/pengaturan'
  // }
  {
    label: {
      id: 'Laporan Bulanan',
      en: 'Laporan Bulanan'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/laporan-bulanan',
    ssoPath: 'GET__/pelaporan_bulanan'
  },

]
},
{
  label: {
    id: 'Pengaturan',
    en: 'Setting'
  },
  children: [
    {
      label: {
        id: 'Periode Pelimpahan',
        en: 'Periode Pelimpahan'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/periode-pelimpahan',
      ssoPath: 'GET__/periode_pelimpahan'
    },
    {
      label: {
        id: 'Tipe Transaksi',
        en: 'Tipe Transaksi'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/TipeTransaksi',
      ssoPath: 'GET__/transaction_type'
    },
    {
      label: {
        id: 'Hari Libur',
        en: 'Hari Libur'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/hari-libur',
      ssoPath: 'GET__/mpn_holiday'
    },

  ]
}
]


export default {
  mainMenus
};
