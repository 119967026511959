import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import {
  Divider,
  Subheader,
  CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  Card,
  CardActions,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer,
  TabsContainer,
  Tabs,
  Tab
} from "react-md";
import FormView from "../../../components/entity/form.view";
import {
  validation,
  Textfield,
  Searchfield,
  TextfieldMask,
  Switch,
  Datepicker,
  Checkbox,
  Datetimepicker
} from "../../../components/form/index";
import PembayaranAllService from "./PembayaranAll.service";
import utilsService from "../../../services/utils.service";
import counterpart from "counterpart";
import { numberService } from "../../../libs/react-mpk";
import { BpnPrintView } from "./BpnPrint.view";
import PembayaranRefundView from "./PembayaranRefund.view";
import izitoast from "izitoast";

@reduxForm({
  form: "PembayaranAllForm",
  destroyOnUnmount: true,
  initialValues: { refund: {} }
})
@connect(state => ({
  formData: getFormValues("PembayaranAllForm")(state),
  auth: state.auth.user
}))
export default class PembayaranAllForm extends FormView {
  service = PembayaranAllService;
  viewType = 2;

  titleHeader() {
    return `entities.${this.service.name}.formTitle`;
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isRincian: false,
      activeTabIndex: 0,
      bpnPrintView: false,
      showRefund: false,
      permissionMap: {}
    };
  }

  msgMap = {
    "00": "Transaksi berhasil",
    "01": "Kode billing tidak terdaftar / Tagihan tidak tersedia",
    "02": "Kode billing kadaluarsa/expired",
    "03": "Total tagihan tidak sama / Data pembayaran tidak sesuai",
    "04": "No. Rekening Persepsi tidak terdaftar/ditemukan",
    "05": "Kode partner tidak terdaftar",
    "06": "authCode salah",
    "27": "Tagihan telah terbayar di Bank/Lembaga lai",
    "31": "Kode partner salah",
    "32": "Kode mata uang tidak terdaftar",
    "88": "Tagihan telah terbayar",
    "90": "Time out",
    "92": "Exception error",
    "97": "CA belum terdaftar di zona waktu tertentu",
    "98": "Panjang message (length) tidak sesuai",
    "99": "Failed/Entity data billing salah"
  };

  initialData = {
    refund: {}
  };

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate("word.save");
    if (this.props.match.params.id == "new")
      saveWord = counterpart.translate("word.create");
    if (this.props.match.params.sptId == "new")
      saveWord = counterpart.translate("word.create");

    return (
      <div>
        <Button
          secondary
          flat
          onClick={() => {
            this.props.history.goBack();
          }}
          style={{ marginRight: 16 }}
        >
          {counterpart.translate("word.back")}
        </Button>
        {(this.props.formData && this.props.formData.status) == "Paid" && (
          <Button
            flat
            primary
            onClick={() => this.setState({ bpnPrintView: true })}
            style={{ marginRight: 16 }}
          >
            Lihat BPN
          </Button>
        )}

        {this.state.permissionMap["GET__/mpn_payment/{paymentCode}/resend"] && (
          <Button
            raised
            primary
            onClick={() => this._handleResend()}
            style={{ marginRight: 16 }}
          >
            Send/Resend
          </Button>
        )}

        {/* <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button> */}
      </div>
    );
  }

  async initData() {
    this.setState({ showRefund: false });
    if (this.props.match.params.id == "new") {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.responseMsg = this.msgMap[res.data.responseCode]
        ? this.msgMap[res.data.responseCode]
        : "";
      res.data.refund = {};
      try {
        let refund = await this.service.api.getRefund(res.data.paymentCode);
        if (refund.data) {
          res.data.refund = refund.data;
          if (res.data.refund.localDateTime)
            res.data.refund.localDateTime = res.data.refund.localDateTime.replace(
              "T",
              " "
            );
          this.setState({ showRefund: true });
        }
      } catch (e) {}
      this.props.initialize(res.data);
    }

    var pList = [];
    if (this.props.auth && this.props.auth.servicePermission)
      pList = this.props.auth.servicePermission;
    var pMap = pList.reduce(function(pv, cv) {
      pv[cv.method + "__" + cv.clientPath] = cv;
      return pv;
    }, {});

    this.setState({ permissionMap: pMap });
  }

  // async initData() {
  // }

  formView() {
    return (
      <div>
        <Card>
          <TabsContainer
            panelClassName="md-grid"
            colored
            activeTabIndex={this.state.activeTabIndex}
            onTabChange={i => this.setState({ activeTabIndex: i })}
          >
            <Tabs tabId="simple-tab" mobile={true}>
              <Tab label="Data Pembayaran"></Tab>
              <Tab label="Data Setoran"></Tab>
              <Tab label="Data Sistem"></Tab>
              {this.props.formData.status &&
                (this.props.formData.status.toLowerCase() == "waiting refund" ||
                  this.props.formData.status.toLowerCase() == "refunded") && (
                  <Tab label="Data Refund"></Tab>
                )}
            </Tabs>
          </TabsContainer>
        </Card>

        {this.switchView()}

        <BpnPrintView
          data={this.props.formData}
          show={this.state.bpnPrintView}
          onHide={() => this.setState({ bpnPrintView: false })}
        />
      </div>
    );
  }

  switchView() {
    if (this.state.activeTabIndex == 0) {
      return this.pembayaranView();
    } else if (this.state.activeTabIndex == 1) {
      return this.setoranView();
    } else if (this.state.activeTabIndex == 2) {
      return this.sistemView();
    } else {
      return this.refundView();
    }
  }

  _tambahRefund = async arr => {
    var p = await this.context.showDialog((props, res, rej) => ({
      title: "Entri Data Pengembalian/Refund Untuk EC",
      initialValue: {},
      height: "auto",
      width: 400,
      height: 800,
      // contentStyle: {overflow: 'inherit'},
      text: (
        <div className="md-grid">
          <Field
            label={"Tanggal Refund"}
            name="proses"
            className="md-cell md-cell--12"
            component={Datepicker}
            validate={validation.required}
          />
          <Field
            label={"Kode Bank"}
            name="proses"
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label={"Nama Bank"}
            name="proses"
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label={"Nomor Rekening"}
            name="proses"
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label={"Nama Pemilik Rekening"}
            name="proses"
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label={"Jumlah"}
            name="proses"
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
          <Field
            label={"Alasan Pengembalian/Refund"}
            name="proses"
            className="md-cell md-cell--12"
            component={Textfield}
            validate={validation.required}
          />
        </div>
      )
    }));
  };

  pembayaranView() {
    return (
      <div style={{ marginTop: -14 }}>
        <Card>
          <div className="md-grid">
            <Field
              label={counterpart(`entities.${this.service.name}.kodeBilling`)}
              name="paymentCode"
              className="md-cell md-cell--6"
              component={Textfield}
            />
            <Field
              label={counterpart(`entities.${this.service.name}.status`)}
              name="status"
              className="md-cell md-cell--6"
              component={Textfield}
            />
            <Field
              label={"Kode RC"}
              name="responseCode"
              className="md-cell md-cell--6"
              component={Textfield}
              // disabled
            />
            <Field
              label={"Pesan"}
              name="responseMsg"
              className="md-cell md-cell--6"
              component={Textfield}
              // disabled
            />
            <Field
              label="Tanggal Pembayaran"
              name="localDateTime"
              className="md-cell md-cell--6"
              component={Datepicker}
            />
            <Field
              label={counterpart(`entities.${this.service.name}.ntl`)}
              name="ntb"
              className="md-cell md-cell--6"
              component={Textfield}
            />
            <Field
              label="Tanggal Buku"
              name="settlementDate"
              className="md-cell md-cell--6"
              component={Datepicker}
            />
            <Field
              label={counterpart(`entities.${this.service.name}.ntpn`)}
              name="ntpn"
              className="md-cell md-cell--6"
              component={Textfield}
            />
            <Field
              label="Kode CA/Bank/Lembaga"
              name="partnerCode"
              className="md-cell md-cell--6"
              component={Textfield}
            />
            <Field
              label="STAN"
              name="transactionId"
              className="md-cell md-cell--6"
              component={Textfield}
            />
            {/* <Field
            label         = 'Nama CA/Bank/Lembaga'
            name          = 'namaBank'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          /> */}
            <Field
              label="Kode Cabang"
              name="branchCode"
              className="md-cell md-cell--6"
              component={Textfield}
            />
          </div>
        </Card>
      </div>
    );
  }

  setoranView() {
    return (
      <div style={{ marginTop: -14 }}>
        {this.props.formData.billingType == "DJP" && (
          <Card>
            <CardTitle
              title=""
              style={{ padding: "10px 16px" }}
              subtitle="Pajak"
            />
            <Divider />
            <div className="md-grid">
              <div className="md-cell md-cell--6">
                <List className="">
                  <ListItem
                    primaryText="Kode Billing"
                    secondaryText={this.props.formData.paymentCode}
                  />
                  <ListItem
                    primaryText="NPWP"
                    secondaryText={this.props.formData.billingInfo.billingInfo1}
                  />
                  <ListItem
                    primaryText="Nama Wajib Pajak"
                    secondaryText={this.props.formData.customerName}
                  />
                  <ListItem
                    primaryText="Alamat"
                    secondaryText={this.props.formData.billingInfo.billingInfo6}
                  />
                  <ListItem
                    primaryText="KAP"
                    secondaryText={this.props.formData.billingInfo.billingInfo2}
                  />
                  <ListItem
                    primaryText="KJS"
                    secondaryText={this.props.formData.billingInfo.billingInfo3}
                  />
                </List>
              </div>
              <div className="md-cell md-cell--6">
                <List className="">
                  <ListItem
                    primaryText="Masa Pajak"
                    secondaryText={this.props.formData.billingInfo.billingInfo4}
                  />
                  <ListItem
                    primaryText="NOP"
                    secondaryText={this.props.formData.billingInfo.billingInfo7}
                  />
                  <ListItem
                    primaryText="NO. SK"
                    secondaryText={this.props.formData.billingInfo.billingInfo5}
                  />
                  <ListItem
                    primaryText="Jumlah Setoran"
                    secondaryText={this.props.formData.amount}
                  />
                  <ListItem
                    primaryText="Terbilang"
                    secondaryText={`${numberService.terbilang(
                      this.props.formData.amount
                    )} Rupiah`}
                  />
                  {/* <ListItem primaryText="Mata Uang" secondaryText="Rupiah"/> */}
                </List>
              </div>
            </div>
          </Card>
        )}
        {this.props.formData.billingType == "DJBC" && (
          <Card>
            <CardTitle
              title=""
              style={{ padding: "10px 16px" }}
              subtitle="Bea Cukai"
            />
            <Divider />
            <div className="md-grid">
              <div className="md-cell md-cell--6">
                <List className="">
                  <ListItem
                    primaryText="Kode Billing"
                    secondaryText={this.props.formData.paymentCode}
                  />
                  <ListItem
                    primaryText="NPWP"
                    secondaryText={this.props.formData.billingInfo.billingInfo1}
                  />
                  <ListItem
                    primaryText="Nama Wajib Pajak"
                    secondaryText={this.props.formData.customerName}
                  />
                  <ListItem
                    primaryText="Alamat"
                    secondaryText={this.props.formData.billingInfo.billingInfo6}
                  />
                  <ListItem
                    primaryText="Jenis Dokumen"
                    secondaryText={this.props.formData.billingInfo.billingInfo2}
                  />
                  <ListItem
                    primaryText="Nomor Dokumen"
                    secondaryText={this.props.formData.billingInfo.billingInfo3}
                  />
                  <ListItem
                    primaryText="Jumlah Setoran"
                    secondaryText={this.props.formData.amount}
                  />
                  <ListItem
                    primaryText="Terbilang"
                    secondaryText={`${numberService.terbilang(
                      this.props.formData.amount
                    )} Rupiah`}
                  />
                  {/* <ListItem primaryText="Mata Uang" secondaryText="Rupiah"/> */}
                </List>
              </div>
            </div>
          </Card>
        )}

        {this.props.formData.billingType == "DJA" && (
          <Card>
            <CardTitle
              title=""
              style={{ padding: "10px 16px" }}
              subtitle="Penerimaan Negara Bukan Pajak"
            />
            <Divider />
            <div className="md-grid">
              <div className="md-cell md-cell--6">
                <List className="">
                  <ListItem
                    primaryText="Kode Billing"
                    secondaryText={this.props.formData.paymentCode}
                  />
                  <ListItem
                    primaryText="Nama Wajib Pajak"
                    secondaryText={this.props.formData.customerName}
                  />
                  <ListItem
                    primaryText="Kementrian/Lembaga"
                    ssecondaryText={
                      this.props.formData.billingInfo.billingInfo1
                    }
                  />
                  <ListItem
                    primaryText="Kode Eselon 1"
                    secondaryText={this.props.formData.billingInfo.billingInfo2}
                  />
                  <ListItem
                    primaryText="Kode Unit Kerja"
                    secondaryText={this.props.formData.billingInfo.billingInfo3}
                  />
                  <ListItem
                    primaryText="Jumlah Setoran"
                    secondaryText={this.props.formData.amount}
                  />
                  <ListItem
                    primaryText="Terbilang"
                    secondaryText={`${numberService.terbilang(
                      this.props.formData.amount
                    )} Rupiah`}
                  />
                  {/* <ListItem primaryText="Mata Uang" secondaryText="Rupiah"/> */}
                </List>
              </div>
            </div>
          </Card>
        )}
      </div>
    );
  }

  sistemView() {
    return (
      <div style={{ marginTop: -14 }}>
        <Card>
          <CardTitle
            title=""
            style={{ padding: "10px 16px" }}
            subtitle="Sistem"
          />
          <Divider />
          <div className="md-grid">
            <div className="md-cell md-cell--6">
              <List className="">
                {/* <ListItem primaryText="Biller Account Number" secondaryText="" /> */}
                <ListItem
                  primaryText="Channel Type"
                  secondaryText={this.props.formData.channelType}
                />
                <ListItem
                  primaryText="Switcher code"
                  secondaryText={this.props.formData.switcherCode}
                />
                <ListItem
                  primaryText="Terminal ID"
                  secondaryText={this.props.formData.terminalId}
                />
                <ListItem
                  primaryText="Terminal Location"
                  secondaryText={this.props.formData.terminalLocation}
                />
                {/* <ListItem primaryText="Nomor Sakti" secondaryText={""} /> */}
              </List>
            </div>
          </div>
        </Card>
        <br />
        <Card>
          <CardTitle
            title=""
            style={{ padding: "10px 16px" }}
            subtitle="Inquiry"
          />
          <Divider />
          <div className="md-grid">
            <div className="md-cell md-cell--6">
              <List className="">
                <ListItem
                  primaryText="Local Date Time"
                  secondaryText={this.props.formData.inquiryDate.localDateTime}
                />
                <ListItem
                  primaryText="GMT"
                  secondaryText={this.props.formData.inquiryDate.gmt}
                />
                <ListItem
                  primaryText="Response Code"
                  secondaryText={this.props.formData.inquiryDate.responseCode}
                />
              </List>
            </div>
          </div>
        </Card>
        <br />
        <Card>
          <CardTitle
            title=""
            style={{ padding: "10px 16px" }}
            subtitle="Payment"
          />
          <Divider />
          <div className="md-grid">
            <div className="md-cell md-cell--6">
              <List className="">
                <ListItem
                  primaryText="Local Date Time"
                  secondaryText={this.props.formData.localDateTime}
                />
                <ListItem
                  primaryText="GMT"
                  secondaryText={this.props.formData.gmt}
                />
                <ListItem
                  primaryText="Response Code"
                  secondaryText={this.props.formData.responseCode}
                />
              </List>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  refundView2() {
    return (
      <div style={{ marginTop: -14 }}>
        <PembayaranRefundView mpnId={this.props.formData.id} />
      </div>
    );
  }

  refundView() {
    return (
      <div style={{ marginTop: -14 }}>
        <Card>
          {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Data Refund" /> */}
          {/* <Divider/> */}
          <div className="md-grid md-text-middle">
            <div className="md-cell md-cell--12 md-text-center">
              {this.props.formData.status &&
                (this.props.formData.status.toLowerCase() == "waiting refund" ||
                  this.props.formData.status.toLowerCase() == "refunded") &&
                !this.state.showRefund && (
                  <Button
                    primary
                    raised
                    onClick={() => this.setState({ showRefund: true })}
                  >
                    Tambah
                  </Button>
                )}
            </div>
          </div>

          {this.state.showRefund && (
            <div className="md-grid">
              <Field
                label={"Kode Bank"}
                name="refund.bankCode"
                className="md-cell md-cell--6"
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label={"Nama Bank"}
                name="refund.bankName"
                className="md-cell md-cell--6"
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label={"Nomor Rekening"}
                name="refund.accountNumber"
                className="md-cell md-cell--6"
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label={"Nama Pemilik Rekening"}
                name="refund.accountHolderName"
                className="md-cell md-cell--6"
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label={"Status"}
                name="refund.status"
                className="md-cell md-cell--6"
                component={Textfield}
                disabled
                // validate      = {validation.required}
              />
              <Field
                label={"Tanggal Refund"}
                name="refund.localDateTime"
                className="md-cell md-cell--6"
                component={Textfield}
                disabled
                // validate      = {validation.required}
              />
              <Field
                label={"Jumlah"}
                name="refund.amount"
                className="md-cell md-cell--12"
                component={TextfieldMask}
                validate={validation.required}
                money={","}
                disabled={this.props.formData.refund.id}
              />
              <Field
                label={"Alasan Pengembalian/Refund"}
                name="refund.description"
                className="md-cell md-cell--12"
                component={Textfield}
                // validate      = {validation.required}
              />

              <div className="md-cell md-cell--12 md-text-right">
                <Button primary flat onClick={this._hapusRefund}>
                  Hapus
                </Button>

                <Button primary flat onClick={this._refunded}>
                  Refund
                </Button>

                <Button primary raised onClick={this._simpanRefund}>
                  Simpan Refund
                </Button>
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  }

  _hapusRefund = async () => {
    var p = await this.context.showDialog((props, res, rej) => ({
      title: "Hapus data Refund ",
      initialValue: { d: "asd" },
      height: "auto",
      width: "auto",
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan menghapus data ini?"
    }));
    if (p) {
      await this.service.api.deleteRefund(this.props.formData.id);

      this.initData();
    }
  };

  _refunded = async () => {
    var p = await this.context.showDialog((props, res, rej) => ({
      title: "Refund ",
      initialValue: {},
      height: "auto",
      width: 500,
      okText: "Refund",
      contentStyle: { overflow: "inherit" },
      text: (
        <div className="md-grid">
          <Field
            label={"Tanggal Refund"}
            name="localDateTime"
            className="md-cell md-cell--12"
            component={Datetimepicker}
            // validate      = {validation.required}
          />
        </div>
      )
    }));
    if (p) {
      p.localDateTime = p.localDateTime.replace(" ", "T");
      await this.service.api.refunded(this.props.formData.id, p);

      this.initData();
    }
  };

  _simpanRefund = async () => {
    var p = await this.context.showDialog((props, res, rej) => ({
      title: "Simpan/Update data Refund ",
      initialValue: { d: "asd" },
      height: "auto",
      width: "auto",
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan menyimpan data ini?"
    }));
    if (p) {
      if (!this.props.formData.refund.id) {
        await this.service.api.createRefund(
          this.props.formData.paymentCode,
          this.props.formData.refund
        );
      } else {
        await this.service.api.uppdateRefund(
          this.props.formData.paymentCode,
          this.props.formData.refund
        );
      }
      this.initData();
    }
  };

  _handleResend = async () => {
    var p = await this.context.showDialog((props, res, rej) => ({
      title: "Resend data pembayaran? ",
      initialValue: { d: "asd" },
      height: "auto",
      width: "auto",
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan mengirim ulang data ini?"
    }));
    if (p) {
      await this.service.api.resend(this.props.formData.paymentCode);
      this.initData();

      izitoast.success({
        title: "Success",
        message: "Data telah di kirim ulang."
      });
    }
  };
}
