import { themes, portals } from "./constant";
import { merge } from "lodash";

class Env {
  theme = themes.SOBATPAJAK;
  themes = themes;
  portal = portals[themes.SOBATPAJAK];

  mappingApps = {
    efiling: "Sobat Lapor",
    ebilling: "Sobat Setor",
    eppt: "Sobat Hitung",
  };

  setTheme(theme) {
    this.theme = theme;
    this.portal = portals[theme];
  }

  setPortal(portal) {
    this.portal = merge(this.portal, portal);
  }
}

let env = new Env();
export default env;
