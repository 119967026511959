import React from 'react';
import TextField from "react-md/lib/TextFields/TextField";
import MaskedInput from 'react-text-mask';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';

export default class TextfieldMask extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            clicked: false,
            value: undefined,
            realValue: 0
        }
    }

    componentWillReceiveProps(nextProps) {
      if(this.props.input.value !== nextProps.input.value) {
        if(nextProps.input.value || nextProps.input.value == 0){
          this.setState({value: nextProps.input.value})

        }
      }
    }

    componentDidMount(){
        var value = this.props.input.value;
        var realValue = this.props.input.value;
        if(this.props.input.value || this.props.input.value == 0){
            this.setState({value: value})
            this.setState({realValue: realValue})
        }
        if(this.props.money){
            if(!this.props.input.value){
                this.setState({value: 0})
                this.setState({realValue: 0})
                this.setState({clicked: true})
            }
        }
    }
    handleClick(){
        this.setState({clicked: true})
    }
    handleMouseLeave(){
        this.setState({clicked: false})
    }

    handleOnBlur(){
        this.props.input.onBlur()
    }

    handleOnFocus(){
        this.props.input.onFocus()
    }

    render(){
        var disabled = false;
        var disabledClass = ''
        if(this.props.disabled){
            disabled = true;
            disabledClass = 'md-text--disabled'
        }
        var error = false;
        if(this.props.meta.error == "Required" && this.props.meta.touched){error = true}
        var labelClass = "";
        var hrClass = "";
        if(this.state.clicked){
            hrClass = "md-divider md-divider--text-field md-divider--expand-from-left md-divider--text-field-expanded"
            labelClass = "md-floating-label md-floating-label--floating md-text--error"
        } else {
            var valExist = false;
            if(this.state.value){
                valExist = true
            } else {
                if(this.state.value == 0){
                    valExist = true
                }
            }
            if(valExist){
                if(this.props.length){
                    if(this.state.realValue.length != this.props.length){
                      // console.log('asdasd', this.state.realValue.length, this.props.length)
                        error = true
                    } else {
                        error = false
                        // console.log('false')
                    }
                }
                if(error){
                    hrClass = "md-divider md-divider--text-field md-divider--expand-from-left md-divider--text-field-error"
                    labelClass = "md-floating-label md-floating-label--floating md-text--error"
                } else {
                    hrClass = "md-divider md-divider--text-field md-divider--expand-from-left"
                    labelClass = "md-floating-label md-floating-label--floating"
                }
            } else {
                if(error){
                    hrClass = "md-divider md-divider--text-field md-divider--expand-from-left md-divider--text-field-error"
                    labelClass = "md-floating-label md-floating-label--inactive md-floating-label--inactive-sized md-text--error"
                } else {
                    hrClass = "md-divider md-divider--text-field md-divider--expand-from-left"
                    labelClass = "md-floating-label md-floating-label--inactive md-floating-label--inactive-sized"
                }
            }
        }

        var label = this.props.label;
        if(this.props.meta.error === "Required") label = this.props.label+' (*)'

        return (
            <div style={this.props.style} className={"md-text-field-container md-full-width md-text-field-container--input " + this.props.className}>
                <label className={ labelClass } >
                {label}
                </label>
                <NumberFormat
                    thousandSeparator={this.props.money}
                    prefix={this.props.prefix}
                    format={this.props.maskFormat}
                    placeholder={this.props.placeholder}
                    mask={this.props.mask}
                    value={this.state.value}
                    onMouseLeave={this.handleMouseLeave.bind(this)}
                    onClick={this.handleClick.bind(this)}
                    onSelect={this.handleClick.bind(this)}
                    // onBlur={this.handleOnBlur.bind(this)}
                    onFocus={this.handleOnFocus.bind(this)}
                    onValueChange={(values) => {

                    const {formattedValue, value} = values;
                        this.setState({ value: formattedValue })
                        this.setState({ realValue: value })
                        var length = 0;
                        if(this.props.length){
                            length = this.props.length
                        }
                        if(length > 0){
                            var checkLength = value + ""
                            if(checkLength.length == length){
                              if(this.props.money) {
                                this.props.input.onChange(parseFloat(value))
                              } else {
                                this.props.input.onChange(value)
                              }
                            } else {
                                this.props.input.onChange(null)
                            }
                        } else {
                          if(this.props.money) {
                            this.props.input.onChange(parseFloat(value))
                          } else {
                            this.props.input.onChange(value)
                          }

                        }
                    }}
                    disabled={disabled}
                    name={"maskInputFor"+this.props.input.name}
                    className={"md-text-field md-text-field--floating-margin md-full-width md-text "+disabledClass+" "+this.props.className}
                    />
                <hr className={hrClass} />
                {error &&
                <div className="md-text-field-message-container md-full-width md-text--error" >
                    <div className="md-text-field-message md-text-field-message--active">
                        {this.props.meta.error}
                    </div>
                </div>
                }
                {/* <input
                    type="hidden"
                    {...this.props.input}
                    value={this.state.realValue}
                    className={this.props.className}
                /> */}
            </div>
        )
    }
}
