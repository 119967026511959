import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
// import {formatDate,parseDate,} from 'react-day-picker/moment';
import "react-datepicker/dist/react-datepicker.css";
import utilsService from "../../services/utils.service";

export default class Datetimepicker extends React.Component {
  render() {
    let {
      input,
      meta: { touched, error },

      label,
      className,
      labelClassName,
      inputClassName,
      style,
      labelStyle,
      inputStyle,
      autoComplete,
      ...others
    } = this.props;
    var value = null;
    if (input.value)
      value = moment(input.value, "YYYY-MM-DD HH:mm:ss").toDate();

    window.ddd = moment(input.value, "yyyy-MM-dd HH:mm:ss");

    return (
      <div
        style={style}
        className={
          "md-text-field-container md-full-width md-text-field-container--input " +
          className
        }
      >
        <label
          className={
            "md-floating-label md-floating-label--floating md-text--error" +
            labelClassName
          }
          style={labelStyle}
        >
          {label}
        </label>
        <DatePicker
          selected={value}
          showTimeSelect
          showTimeInput
          timeFormat="HH:mm:ss"
          timeIntervals={1}
          timeCaption="time"
          utcOffset={420}
          dateFormat="yyyy-MM-dd HH:mm:ss"
          timeInputLabel="Time:"
          className={
            "md-text-field md-text-field--floating-margin md-full-width md-text " +
            inputClassName
          }
          {...input}
          {...others}
          onChange={d => {
            this.handleChange(d, input);
          }}
        />
        <hr
          className={
            "md-divider md-divider--text-field md-divider--expand-from-left md-divider--text-field-expanded"
          }
        />
        {error && touched && (
          <div className="md-text-field-message-container md-full-width md-text--error">
            <div className="md-text-field-message md-text-field-message--active">
              {this.props.meta.error}
            </div>
          </div>
        )}
      </div>
    );
  }

  // @utilsService.Debounce()
  handleChange(d, input) {
    // console.log(d, 'asd')
    input.onChange(d);
  }
}
