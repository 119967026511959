import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Toolbar,
  List,
  ListItem,
  Avatar,
  DropdownMenu,
  FontIcon,
  TextField,
  Subheader,
  Divider,
  Button
} from "react-md";
import { navService } from "../../libs/react-mpk";
import authService from "../../services/auth.service";

@connect(state => ({
  isLoginIn: state.authHalona.isLoginIn,
  user: state.authHalona.user
}))
export default class ModuleMenu extends Component {
  changeModule = d => {
    authService.api.changeModule(d, this.props.dispatch);
  };

  render() {
    return (
      <div>
        <DropdownMenu
          id="moduleSwitcher"
          closeOnOutsideClick={false}
          menuItems={[
            <Subheader primaryText="MODULES" />,
            {
              primaryText: "PPh 21",
              secondaryText: "Lorem ipsum dolor",
              leftAvatar: <Avatar suffix="green">21</Avatar>,
              onClick: () => this.changeModule("pph21")
            },
            {
              primaryText: "PPh 4 ayat 2",
              secondaryText: "Lorem ipsum dolor",
              leftAvatar: <Avatar suffix="green">4</Avatar>,
              onClick: () => this.changeModule("pph4a2")
            },
            {
              primaryText: "Organisasi",
              secondaryText: "Lorem ipsum dolor",
              leftAvatar: (
                <Avatar suffix="blue">
                  <FontIcon>people</FontIcon>
                </Avatar>
              ),
              onClick: () => this.changeModule("organization")
            },
            {
              primaryText: "Setup",
              secondaryText: "Lorem ipsum dolor",
              leftAvatar: (
                <Avatar suffix="grey">
                  <FontIcon>settings</FontIcon>
                </Avatar>
              ),
              onClick: () => this.changeModule("setup")
            },
            {
              primaryText: "SIE",
              secondaryText: "Lorem ipsum dolor",
              leftAvatar: (
                <Avatar suffix="red">
                  <FontIcon>assignment</FontIcon>
                </Avatar>
              ),
              onClick: () => this.changeModule("sie")
            }
          ]}
          anchor={{
            x: DropdownMenu.HorizontalAnchors.CENTER,
            y: DropdownMenu.VerticalAnchors.OVERLAP
          }}
          position={DropdownMenu.Positions.BELOW}
          animationPosition="below"
          fullWidth
          sameWidth
          listStyle={{
            marginTop: "-67px"
          }}
        >
          <Button floating mini className="module-menu">
            dashboard
          </Button>
        </DropdownMenu>
      </div>
    );
  }
}
