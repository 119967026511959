import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import PelimpahanService from './Pelimpahan.service';
import ListView from '../../../components/entity/listView';
import moment from 'moment';

export class PelimpahanView extends ListView {
  editDialog  = false;
  addDialog   = false;
  service     = PelimpahanService
  stream      = true;

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showDialogConfirmDeleteFinishFailedSelected:false,
      showForm:false,
      formData: {},
      data: [],
      permissionMap: {}
    }
  }

  componentDidMount() {
    this.firstCall = false;

    var pList = []
    if(this.props.auth && this.props.auth.servicePermission) pList = this.props.auth.servicePermission
    var pMap = pList.reduce(function(pv, cv) {
      pv[cv.method+"__"+cv.clientPath] = cv
      return pv
    }, {})

    this.setState({permissionMap: pMap})
  }

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tanggal`,       value: (d)=> {
      if(d.submissionDateTime) return moment(d.submissionDateTime).format("YYYY-MM-DD HH:mm:ss")
    }, type: 'func'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tglBuku`,       value: "bookDate"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.nilai`,         value: "amount", type: 'number'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.trn`,       value: "trn"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.nomorSakti`,       value: "nomorSakti"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.status`,       value: "status"},
  ]

  _barActions = () => {
    var arr = [

      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => {

          window.location.hash = window.location.hash.split('?')[0]
          this.fetchData()
        }
      }
    ]

    if(this.state.permissionMap['POST__/pelimpahan']) {
      arr.push({
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      })
    }

    return arr
  }
}

export default connect((state) => ({
  ...PelimpahanService.stateConnectSetting()(state),
  auth        : state.auth.user
}), PelimpahanService.actionConnectSetting())(PelimpahanView)
