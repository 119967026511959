import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form/index';
import DashboardService from './Dashboard.service';
import utilsService from '../../../services/utils.service';

@reduxForm({form: 'DashboardForm', destroyOnUnmount: true})
@connect((state) => ({
  // access      : state.entity.access.api.find,
  company     : state.auth.company,
  formData    : getFormValues('DashboardForm')(state)
}))
export default class DashboardView extends FormView {
  translate = false
  titleHeader = () =>  'Beranda'
  service = DashboardService
  viewType=2


  initialData={
  }


  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div></div>
    )
  }

  async initData() {
    console.log(this.props.company, 'asdasd')

    // var res = await this.service.api.test(this.props.formData)
  }


}
