import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import authService from "../services/authService";
import httpService from "../services/httpService";
import locationService from "../services/locationService";
import * as authActions from "../redux/actions/authActions";
import * as globalActions from "../redux/actions/globalActions";
import * as toastActions from "../redux/actions/toastActions";
import * as dialogActions from "../redux/actions/dialogActions";
import qs from "query-string";
import axios from "axios";
import LoaderInfo from "../components/LoaderInfo/LoaderInfo";
import _ from "lodash";
import { Dialog } from "../components/Dialog";
import navService from "../services/navService";
import mpkEnv from "../config/env";
import NewsTicker from "../components/NewsTicker";
import Promo from "../components/Promo";
import t from "counterpart";
import UserBalance from "../components/UserBalance";
import TFAPage from "./TFAPage";
import hostMpk from "../services/hosts";
import PasswordExpiration from "./PasswordExpiration";
const TFAAktif = false;

let _history = null;
let isRefreshingToken = false;
let resolveQueue = [];

const onError = (error, callback) => {
  if (error !== undefined && error.response) {
    let { status, statusText, data } = error.response;

    if (status === 401) logout();
    else {
      console.log(
        "🚀 ~ file: ssoConnect.js ~ line 28 ~ onError ~ error",
        error
      );
      navService.redirectTo(
        "/error?" +
          qs.stringify({
            status: status,
            statusText: statusText,
            errorMessage:
              data.errorMessage || data.error_description || data.error || data,
          })
      );
      // callback(true);
    }
  } else {
    callback(true);
  }
};

const logout = () => {
  authService.logout(null, window.encodeURIComponent(window.location.href));
};

const login = (url, redirectUri) => {
  httpService
    .get({
      url: `${url.login}?redirect_uri=${window.encodeURIComponent(
        redirectUri
      )}`,
    })
    .then((response) => {
      window.open(response.data, "_self");
    });
};

const getNewAccessToken = async (url, callback = () => {}) => {
  let refreshToken = authService.getRefreshToken();
  try {
    let res = await axios.get(
      `${url.refreshToken.replace(":refreshToken", refreshToken)}`
    );
    window.dispatchEvent(
      new CustomEvent("mpk-update-access-token", { detail: res.data })
    );
    setTimeout(() => callback(res.data));
  } catch (error) {
    logout();
  }
};

const init = (url, credentials, ignoreAuth, onExchangeToken, callback) => {
  credentials = typeof credentials === "function" ? credentials() : credentials;
  const defaultUri = credentials.redirect_uri;
  const refreshToken = authService.getRefreshToken();
  const accessToken = authService.getAccessToken();

  let currentPath = window.location.href;
  let p = currentPath.match(/^([^\?]+)/g);
  let q = currentPath
    .replace(`${p}?`, "")
    .replace(`${p}`, "")
    .replace(/\?/g, "&");

  let parsedUrl = qs.parseUrl(`${p}?${q}`);
  let newQuery = JSON.parse(JSON.stringify(parsedUrl.query));

  if (parsedUrl.query.code && !accessToken) {
    authService.clearCookie();
    delete newQuery.code;
    delete newQuery.state;

    console.log(parsedUrl.query);
    parsedUrl.query.redirect_uri =
      parsedUrl.query.redirect_uri ||
      window.encodeURIComponent(
        defaultUri ||
          parsedUrl.url +
            (Object.keys(newQuery).length > 0
              ? "?" + qs.stringify(newQuery)
              : "")
      );
    authService.removeIsTFA();
    axios
      .post(url.exchangeToken, parsedUrl.query)
      .then((response) => {
        delete newQuery.redirect_uri;
        let currentUrl = `${window.location.pathname}${
          Object.keys(newQuery).length > 0 ? `?${qs.stringify(newQuery)}` : ""
        }`;
        callback(response);
        navService.redirectTo(p, q);
      })
      .catch((error) => {
        onError(error, () => {
          callback(true);
        });
      });
  } else {
    let _redirectUri = (
      parsedUrl.query.redirect_uri ||
      window.encodeURIComponent(
        defaultUri ||
          parsedUrl.url +
            (Object.keys(newQuery).length > 0
              ? "?" + qs.stringify(newQuery)
              : "")
      )
    ).replace(/(?!.*(:))\/(\/)*\?/g, "?");
    if ([undefined, null].indexOf(accessToken) >= 0) {
      if (refreshToken) {
        getNewAccessToken(url, callback);
      } else {
        if (ignoreAuth) {
          callback();
        } else {
          if (onExchangeToken) {
            onExchangeToken();
          } else {
            login(url, _redirectUri);
          }
        }
      }
    } else {
      callback();
    }
  }
};

const setHttpInterceptors = (url, withCredentials) => {
  const setHeaders = (config) => {
    let accessToken = authService.getAccessToken();
    let accessKey = authService.getAccessKey();
    let userProfile = authService.getUserProfile();

    config.headers = config.headers || {};

    if (accessKey) config.headers["X-Access-Key"] = accessKey;
    else if (accessToken) {
      config.headers.Authorization = "Bearer " + accessToken;
      if (withCredentials) config.withCredentials = true;
    }
    if (userProfile)
      config.headers["X-User-Profile"] = JSON.stringify(userProfile);
  };

  httpService.setInterceptors((config) => {
    return new Promise((resolve, reject) => {
      let accessToken = authService.getAccessToken();
      let refreshToken = authService.getRefreshToken();
      let accessKey = authService.getAccessKey();

      if (!accessKey && !accessToken && refreshToken) {
        if (isRefreshingToken) {
          resolveQueue.push(resolve);
        } else {
          isRefreshingToken = true;
          getNewAccessToken(url, () => {
            setHeaders(config);
            if (resolveQueue.length > 0) {
              for (let i = resolveQueue.length - 1; i >= 0; i--) {
                let q = resolveQueue[i];
                q(config);
                resolveQueue.splice(i, 1);
              }
            }
            isRefreshingToken = false;
            resolve(config);
          });
        }
      } else {
        setHeaders(config);
        resolve(config);
      }
    });
  });
};

const setLogoutAction = (url, host, credentials, onLogout) => {
  let _host = typeof host === "function" ? host() : host;
  let _credentials =
    typeof credentials === "function" ? credentials() : _.clone(credentials);

  authService.setLogoutAction((redirectUri, callback) => {
    let locationHref = window.location.href;
    _credentials.access_token = authService.getAccessToken();
    _credentials.redirect_uri = window.encodeURIComponent(
      redirectUri ||
        _credentials.redirect_uri ||
        (locationHref.match("/error") ? window.location.origin : locationHref)
    );
    if (onLogout) onLogout();
    else {
      callback();
      if (_credentials.access_token)
        window.open(
          `${_host}/auth/oauth/logout?${qs.stringify(_credentials)}`,
          "_self"
        );
      else login(url, _credentials.redirect_uri);
    }
  });
};

const setTokenNames = (callback) => {
  switch (mpkEnv.theme) {
    case "66cebbca847243f422fde45a47cc619b":
      // authService.setTokenNames("SP_ACCESS_TOKEN", "SP_REFRESH_TOKEN");
      break;
    default:
      break;
  }

  callback();
};

export default (
  url = {
    exchangeToken: "/api/iams/exchangeToken",
    refreshToken: "/api/iams/refreshToken/:refreshToken",
    login: "/api/iams/login",
    me: "/api/iams/me",
  },
  host,
  credentials,
  history,
  onComplete,
  redirectUnauthorized = true,
  returnErrorAuthenticate = false,
  withCredentials = true
) => (WrappedComponent) => {
  class SSOLogger extends Component {
    state = {
      onProgress: true,
      newsTickers: [],
      promos: [],
      showTFA: false,
      isTfa: false,
      mandatoryTFA: false,
      showPasswordExpiration: false,
      isExpired: false,
    };

    onUser = (user) => {
      if (onComplete && user) onComplete(user, this.props);
      this.setState({ onProgress: false });
    };

    onUserLoaded = (user) => {
      if (window.location.pathname === "/") {
        let path;
        for (let i = 0; i < user.menus.length; i++) {
          let menu = user.menus[i];
          if (!path && menu.children && menu.children.length > 0) {
            path = menu.children[0].path;
            break;
          }
        }
        if (path) {
          _history.push(path);
        } else {
          if (this.props.global.asInternalService) {
            locationService.errorPage({
              status: 401,
              statusText: "Access Denied. You don't have any menu",
            });
          }
        }
        this.onUser(user);
      } else {
        this.onUser(user);
      }
    };

    getMe = async () => {
      try {
        let res = await httpService.get({ url: url.me });
        let { role } = res.data;
        console.log(
          "🚀 ~ file: ssoConnect.js:316 ~ SSOLogger ~ getMe= ~ role.isAdmin:",
          role.isAdmin
        );
        console.log(
          "🚀 ~ file: ssoConnect.js:317 ~ SSOLogger ~ getMe= ~ role.name.toLowerCase().includes('support'):",
          role.name.toLowerCase().includes("support")
        );
        console.log(
          "🚀 ~ file: ssoConnect.js:318 ~ SSOLogger ~ getMe= ~ this.state.mandatoryTFA:",
          this.state.mandatoryTFA
        );
        if (
          (role.isAdmin || role.name.toLowerCase().includes("support")) &&
          this.state.mandatoryTFA
        ) {
          this.setState({
            showTFA: true,
          });
        } else {
          this.props.authActions.setProperties({
            user: res.data,
            isLoggedIn: true,
            hasPermission: (resourceUris = "") => {
              if (resourceUris === "") return true;
              else {
                const permission = res.data.permission || [];
                const uris = resourceUris.split(",");
                let allowed = false;
                if (uris.length === 1 && resourceUris === "") return true;
                for (let uri of uris) {
                  allowed = permission.indexOf(uri) >= 0;
                  if (allowed) break;
                }
                return allowed;
              }
            },
          });
          setTimeout(() => {
            if (onComplete) this.onUser(res.data);
            else this.onUserLoaded(res.data);
          }, 500);
        }
      } catch (err) {
        this.onUser();
        onError(err, () => this.onUser(null));
      }
    };

    componentWillMount() {
      httpService
        .get({
          url: "/api/portals/host",
        })
        .then(
          (response) => {
            hostMpk.setHost(response.data);
          },
          (error) => {
            locationService.errorPage(error);
          }
        );
      let { ignoreAuth, onExchangeToken, onLogout } = this.props;
      _history = history;
      navService.init(history, this.props.globalActions);

      this.props.authActions.setProperties({
        getNewAccessToken: (callback) => getNewAccessToken(url, callback),
      });

      setTokenNames(() => {
        setHttpInterceptors(url, withCredentials);
        this.setHttpErrorHandler();
        setLogoutAction(url, host, credentials, onLogout);
        init(url, credentials, ignoreAuth, onExchangeToken, (isError) => {
          // if(isError){
          //   this.setState({onProgress:false})
          // } else {
          if (this.props.tmpObject.asWidget || this.props.global.asWidget) {
            if (ignoreAuth) {
              this.props.authActions.setProperties({
                hasPermission: () => true,
              });
              this.onUser(null);
            } else {
              this.getMe();
            }
          } else {
            console.log(
              "🚀 ~ file: ssoConnect.js:392 ~ SSOLogger ~ init ~ TFAAktif:",
              TFAAktif
            );
            if (TFAAktif) {
              const SkipTfa = authService.getSkipTFA();
              const IsTFA = authService.getIsTFA();
              const passwordExpired = authService.getPasswordExpired();
              if (
                (isError &&
                  isError.data &&
                  isError.data.passwordExpired &&
                  isError.data.passwordExpired === "true") ||
                passwordExpired === "true"
              ) {
                authService.setPasswordExpired("true");
                this.setState({ isExpired: true });
              }
              if (isError && isError.data && isError.data.isTfa === "true") {
                console.log("masuk sini bos");
                this.setState({ showTFA: true, isTfa: true });
                console.log(
                  "🚀 ~ file: ssoConnect.js:406 ~ SSOLogger ~ init ~ isError.data.isTfa:",
                  isError.data.isTfa
                );
                authService.setIsTFA("true");
                authService.removeSkipTFA();
              } else {
                if (SkipTfa || IsTFA === "DONE") {
                  if (
                    (isError &&
                      isError.data &&
                      isError.data.passwordExpired &&
                      isError.data.passwordExpired === "true") ||
                    passwordExpired === "true"
                  ) {
                    authService.setPasswordExpired("true");
                    this.setState({ showPasswordExpiration: true });
                  } else {
                    if (ignoreAuth) {
                      this.props.authActions.setProperties({
                        hasPermission: () => true,
                      });
                      this.onUser(null);
                    } else {
                      this.getMe();
                    }
                  }
                } else {
                  console.log("masuk show");
                  this.setState({ mandatoryTFA: true });
                  if (ignoreAuth) {
                    this.props.authActions.setProperties({
                      hasPermission: () => true,
                    });
                    this.onUser(null);
                  } else {
                    this.getMe();
                  }
                }
              }
            } else {
              if (ignoreAuth) {
                this.props.authActions.setProperties({
                  hasPermission: () => true,
                });
                this.onUser(null);
              } else {
                this.getMe();
              }
            }
          }
        });
      });
      this.getResource();
    }

    setHttpErrorHandler = () => {
      let { ignoreAuth, onUnauthorized } = this.props;
      httpService.setErrorHandler((err) => {
        err.statusCode = err.response
          ? err.response.statusCode || err.response.status
          : err.statusCode || err.status;

        if (err.response) {
          err.response.status = err.response.status || err.response.statusCode;
          const responseData = err.response.data;
          err.response.message = responseData
            ? responseData.errorMessage ||
              responseData.error_description ||
              responseData.message ||
              responseData.error ||
              responseData
            : err.message;
          err.message = err.response.message;

          if (Number(err.statusCode) >= 500) {
            if (httpService.valUrlIgnore(err.response.config.url)) return err;
            else locationService.errorPage(err, window.location.href);
            return err;
          } else {
            let isBreak = false;
            if (err.response.headers) {
              let authenticateErr =
                err.response.headers["www-authenticate"] ||
                err.response.headers["WWW-Authenticate"];
              if (authenticateErr && !err.response.data) {
                authenticateErr = authenticateErr
                  .replace(/,/g, "&")
                  .replace(/"/g, "");
                let parsed = qs.parse(authenticateErr);
                err.response.message = parsed.error_description || parsed.error;
                err.message =
                  err.response.data ||
                  err.response.message ||
                  err.response.headers["www-authenticate"];
                err.response.statusText =
                  err.response.status === 401 ? "Unauthorized" : "Bad Request";

                if (
                  err.message &&
                  typeof err.message === "string" &&
                  err.message.match(
                    new RegExp("(session timeout|token expired)", "ig")
                  ) &&
                  (this.props.tmpObject.asWidget || this.props.global.asWidget)
                ) {
                  this.props.dialogActions.showAlert(
                    t.translate("word.message"),
                    t.translate("sentence.confirm.accessTokenExpired")
                  );
                } else {
                  if (onUnauthorized) onUnauthorized(err);
                  else {
                    if (ignoreAuth) {
                      this.props.dialogActions.showAlert(
                        t.translate("word.message"),
                        err.message
                      );
                    } else {
                      if (returnErrorAuthenticate) {
                        if (err.response.status === 401) {
                          if (redirectUnauthorized) logout();
                          else locationService.errorPage(err, window.location);
                        } else return err;
                      } else {
                        locationService.errorPage(err, window.location.href);
                      }
                    }
                  }
                }

                isBreak = true;
              }
            }

            if (!isBreak) {
              if (err.statusCode === 401 && !ignoreAuth) {
                if (onUnauthorized) onUnauthorized(err);
                else {
                  if (redirectUnauthorized) logout();
                  else {
                    if (authService.getRefreshToken()) {
                      locationService.errorPage(err, window.location);
                    } else {
                      authService.clearCookie();
                      locationService.errorPage(err, window.location);
                    }
                  }
                }
              } else {
                err.message = err.response.data
                  ? err.response.data.errorMessage ||
                    err.response.data.error_description ||
                    err.response.data.error ||
                    err.response.data
                  : err.response.body
                  ? err.response.body.errorMessage ||
                    err.response.body.error_description ||
                    err.response.body.error ||
                    err.response.body
                  : err.response.message;
                return err;
              }
            }
          }
        } else {
          locationService.errorPage({
            status: 500,
            message: "Network Error",
          });
        }
      });
    };

    getResource = async () => {
      let { host, baseUrl, clientId } = mpkEnv.portal;
      let config = { headers: { "x-client": clientId } };
      try {
        let resProduct = await fetch(
          `${host}${baseUrl}/api/products/es/all`,
          config
        );
        let products = await resProduct.json();
        let appUrl = products.map((d) => ({
          label: d.name,
          img: d.logo.src,
          url: d.url,
          code: d.description,
        }));

        this.props.globalActions.setProperties({ appUrl });
      } catch (error) {
        this.props.toastActions.izi(
          t.translate("word.failed"),
          typeof error.message === "object"
            ? error.message[this.props.global.localeCode]
            : error.message,
          "warning"
        );
      }
    };

    render() {
      const { isTfa, showTFA, showPasswordExpiration, isExpired } = this.state;
      return (
        <div className="mpk-full width height">
          {this.state.onProgress ? (
            <LoaderInfo statusText="getting user information.." />
          ) : (
            <div className="mpk-layout column mpk-full height">
              <WrappedComponent {...this.props} />
              {/* <Promo /> */}
              {/* <NewsTicker /> */}
              <UserBalance
                visible={this.props.global.userBalanceVisible}
                onCancel={() =>
                  this.props.globalActions.setProperties({
                    userBalanceVisible: false,
                  })
                }
              />
            </div>
          )}
          <Dialog />
          <TFAPage
            visible={showTFA}
            data={[]}
            isTfa={isTfa}
            onFinish={() => {
              if (!isExpired) {
                this.setState({ showTFA: false });
                // window.location = '/';
                window.location.reload();
              } else {
                this.setState({
                  showTFA: false,
                  showPasswordExpiration: true,
                });
              }
            }}
            props={this.props}
          />
          <PasswordExpiration
            visible={showPasswordExpiration}
            data={[]}
            isTfa={isTfa}
            onFinish={() => {
              this.setState({ showPasswordExpiration: false });
              window.location.reload();
            }}
            props={this.props}
          />
        </div>
      );
    }
  }

  return connect(
    (state) => ({
      auth: state.auth,
      global: state.global,
      tmpObject: state.tmpObject,
    }),
    (dispatch) => ({
      authActions: bindActionCreators(authActions, dispatch),
      globalActions: bindActionCreators(globalActions, dispatch),
      toastActions: bindActionCreators(toastActions, dispatch),
      dialogActions: bindActionCreators(dialogActions, dispatch),
    })
  )(SSOLogger);
};
