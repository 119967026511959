import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  Card,
  CardActions,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form/index';
import PembayaranService from './Pembayaran.service';
import utilsService from '../../../services/utils.service';
import counterpart from 'counterpart';

@reduxForm({form: 'PembayaranForm', destroyOnUnmount: true})
@connect((state) => ({
  formData    : getFormValues('PembayaranForm')(state)
}))
export default class PembayaranForm extends FormView {
  service = PembayaranService
  viewType=2

  titleHeader() {
    return `entities.${this.service.name}.formTitle`;
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isRincian: false
    }
  }


  initialData={
  }


  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div></div>
    )
  }

  async initData() {
  }

  formView() {
    return <div>
      <Card>
        <div className='md-grid'>
          <Field
            label         = {counterpart(`entities.${this.service.name}.kodeBilling`)}
            name          = 'kodeBilling'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />

          {this.kodeBillingView()}
        </div>
      </Card>
      <br/>
      {this.bayarView()}
    </div>
  }

  kodeBillingView() {
    if(this.state.isRincian) return <div/>
    return <div className='md-cell md-cell--12'>
    <blockquote>
      <p>Silahkan mengisi Kode Billing Pajak, Bea cukai atau Penerimaan Negara Lainnya</p>
    </blockquote>
    <Button flat primary swapTheming style={{float: 'right'}} onClick={()=> this.setState({isRincian: true})}>Lanjut</Button>
  </div>
  }

  bayarView() {
    if(!this.state.isRincian) return <div/>
    return <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Rincian Data Pembayaran" />
      <Divider/>
      <div className='md-grid'>
        <div className='md-cell md-cell--6'>
        <List className="">
          <ListItem primaryText="Kode Billing" secondaryText="12371983279128" />
          <ListItem primaryText="Jenis Pembayaran" secondaryText="Pajak"/>
          <ListItem primaryText="NPWP" secondaryText="02.0123123-0-00012" />
          <ListItem primaryText="Nama Wajib Pajak" secondaryText="Entah Sufilan" />
          <ListItem primaryText="Alamat" secondaryText="06"/>
          <ListItem primaryText="Nomor Objek Pajak" secondaryText=""/>

        </List>
        </div>

        <div className='md-cell md-cell--6'>
        <List className="">
          <ListItem primaryText="Mata Anggaran" secondaryText="4111121"/>
          <ListItem primaryText="Jenis Setoran" secondaryText="100"/>
          <ListItem primaryText="Masa Pajak" secondaryText="01/09/2019"/>
          <ListItem primaryText="Jumlah Setoran" secondaryText="RP. 1.2000.000,-"/>
          <ListItem primaryText="Terbilang" secondaryText="Satu juta dua ratus rupiah"/>
          <ListItem primaryText="Mata Uang" secondaryText="IDR"/>
        </List>
        </div>

      </div>
    </Card>
  }


}
