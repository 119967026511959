import accounting from 'accounting-js';

export default {
  Debounce(wait = 1000, immediate=false) {
    return function(target, propertyKey, descriptor) {
        var timeout;
        var originalMethod = descriptor.value;
        descriptor.value = function() {
            var context = this
            var args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) originalMethod.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) originalMethod.apply(context, args);
        };
        return descriptor;
    }
  },

  date: {
    MM(M) {
      return ('0' + M).slice(-2)
    },
    YY(YYYY) {
      return YYYY.toString().substr(2,2);
    }
  },
  number: {
    get(d) {
      if(d) {
        return parseFloat(d)
      } else {
        return 0;
      }
    },
    format(v, opt = {}) {
      return accounting.formatNumber(this.get(v), opt);
    },
    format2(v, opt = {}) {
      return accounting.formatNumber(this.get(v), {precision : 0});
    },
    strFormat(value = "", pattern) {
      var i = 0,
          v = value.toString();
      return pattern.replace(/#/g, _ => v[i++]);
    }
  }
}
