import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  Card,
  CardActions,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import DialogView from '../../../components/entity/dialog.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form/index';
import HariLiburService from './HariLiburService';
import utilsService from '../../../services/utils.service';
import counterpart from 'counterpart';

@reduxForm({form: 'HariLiburForm', destroyOnUnmount: true})
@connect((state) => ({
  auth        : state.auth.user,
  data    : getFormValues('HariLiburForm')(state)
}))
export default class HariLiburForm extends DialogView {
  service = HariLiburService
  viewType=2

  titleHeader() {
    return `entities.${this.service.name}.formTitle`;
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isRincian: false,
      permissionMap: {}
    }
  }

  componentDidMount() {
    this.initData(this.props);

    var pList = []
    if(this.props.auth && this.props.auth.servicePermission) pList = this.props.auth.servicePermission
    var pMap = pList.reduce(function(pv, cv) {
      pv[cv.method+"__"+cv.clientPath] = cv
      return pv
    }, {})

    this.setState({permissionMap: pMap})
  }


  initialData={
    status: true
  }



  // async initData() {
  // }

  formView() {
    return <div>
      {/* <Card> */}
        <div className='md-grid'>
          <Field
            label         = "Nama"
            name          = 'name'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate = {validation.required}
          />
          <Field
            label         = {"Tanggal Libur"}
            name          = 'holidayDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />
          <Field
            label         = "Tanggal Hari Kerja Berikutnya"
            name          = 'nextWorkingDayDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}

          />
          <Field
            label         = {"Jenis"}
            name          = 'holidayType'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[
              {id: 'Libur Nasional', name: 'Libur Nasional'},
              {id: 'Cuti Bersama', name: 'Cuti Bersama'},
              {id: 'Lainnya', name: 'Lainnya'}
            ]}
          />
          <Field
            label         = "Keterangan"
            name          = 'Description'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            // validate = {validation.required}
          />
          <Field
            label         = "Status"
            name          = 'status'
            className     = 'md-cell md-cell--6'
            component     = {Switch}

          />
        </div>
      {/* </Card> */}
    </div>
  }



  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

        {this.state.permissionMap['PUT__/transaction_type/{id}'] && <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>}
      </div>
    )
  }

}
