import React from 'react';

const Element = ({className, children, flex=true, overflow=false, style={}}) => {
  style.flex = flex ? 1 : 'none';
  style.overflow = overflow ? 'auto' : 'unset'
  return (
    <div 
      className={`sgn-layout-element ${className ? className : ''}`}
      style={style}
    >
      {children}
    </div>
  )
}

export default Element;