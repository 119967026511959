import React from 'react';
import properties from './properties';
import './Layout.scss';

const Layout = ({
  alignment, direction="row", justify, children, className, 
  fit=true, wrap=false, overflow=false, switchDirection, reverse=false,
  flex=false, style={}
}) => {
  style.display = 'flex';
  style.flexDirection = direction || properties.direction.ROW;
  style.alignItems = alignment || properties.alignment.START;
  style.justifyContent =  justify || properties.justify.START;
  style.flexWrap = wrap ? 'wrap' : 'unset';
  style.overflow = overflow ? 'auto' : 'unset';

  if(flex){
    style.flex=1
  } else {
    if(fit){
      style.width = '100%';
      style.height = '100%';
    }
  }

  return (
    <div 
      className={`
        sgn-layout ${direction} ${className ? className : ''} 
        ${switchDirection ? 'switch-'+switchDirection : ''}
        ${reverse ? 'reverse' : ''}
      `}
      style={style}
    >
      {children}
    </div>

  )
}

export default Layout;