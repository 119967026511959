import ApiService from '../../../services/api.service';
import qs from 'qs';
import _ from 'lodash';
import oboe from 'oboe'
import hosts from '../../../services/hosts';

class RekeningKoranService extends ApiService {
  name= 'rekening-koran';
  path= 'rekening_koran';

  api={
    stream: async (param={}, dispatch=()=>{}, opt={})=> {
      param = {...param};

      delete param.total;

      if(param.page)
        param.page = param.page
      else
        param.page = 1

      if(_.isEmpty(param.column)) {
        delete param.column
      }

      if(param.sort && param.sortBy) {
        if(param.sortBy == "createdDate"){
          param.sortBy = "createdAt"
        }
        var sort  = param.sort;
        if(sort == 'ASC') {
          param.sort = 1
        } else {
          param.sort = -1
        }
      }
      let path = this.path;
      if(opt.path) path = opt.path;

      // console.log("auth", http.defaults.headers.common['Authorization'])

      let res = oboe({
        url: hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl+`${this.apiLocation}/${this.path}/${param.id}/detail?`+qs.stringify(param, {arrayFormat: 'repeat'}),
        headers: {
          Authorization: this.http.defaults.headers.common['Authorization']
        }
      })
      return res
    }
  }
}

export default new RekeningKoranService().init()
