export default {
  alignment:{
    CENTER: 'center',
    START: 'start',
    END: 'end'
  },
  direction:{
    ROW: 'row',
    COLUMN: 'column'
  },
  justify:{
    START: 'flex-start',
    CENTER: 'center',
    END: 'flex-end',
    BETWEEN: 'space-between',
    AROUND: 'space-around'
  },
  media:{
    XS:'xs',
    SM:'sm',
    MD:'md',
    LG:'lg'
  }
}