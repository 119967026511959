import ApiService from '../../../services/api.service';

class TipeTransaksiService extends ApiService {
  name= 'TipeTransaksi';
  path= 'transaction_type';

  api = {
    getPeriode: async (id, bookDate)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/${bookDate}`)

      return res
    }
  }
}

export default new TipeTransaksiService().init()
