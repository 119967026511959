import React, { Component } from "react";
import { Button, DialogContainer, TextField } from "react-md";
import "./bpn.css";
import logo1 from "../../../assets/logo-kemenkeu.png";
import logo2 from "../../../assets/logo-pajakku.png";
import moment from "moment";

import { numberService } from "../../../libs/react-mpk";

export class BpnPrintView extends Component {
  render() {
    return (
      <DialogContainer
        id="simple-action-dialog"
        visible={this.props.show}
        onHide={this.props.onHide}
        actions={[
          { secondary: true, children: "close", onClick: this.props.onHide },
          {
            primary: true,
            children: "Download",
            onClick: () => {
              var element = document.getElementById("bpnprint");
              var opt = {
                margin: 1,
                filename: "myfile.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "in", format: "a4", orientation: "portrait" }
              };

              window
                .html2pdf()
                .set(opt)
                .from(element)
                .save();
            }
          }
        ]}
        dialogStyle={{ width: 800, height: "auto", overflowY: "auto" }}
        title="BPN"
      >
        <div className="bpn-container" id="bpnprint">
          <table className="bpn-header v-gap">
            <thead></thead>
            <tbody>
              <tr>
                <td className="logo">
                  <img src={logo2} />
                </td>
                <td>
                  <div>Bukti Penerimaan Negara</div>
                  <div>labelPenerimaan</div>
                </td>
                <td className="logo">
                  <img src={logo1} />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="v-gap">
            <div className="divider-label">Data Pembayaran</div>
            <table className="bpn-table">
              <thead></thead>
              <tbody>
                <tr>
                  <td>Tanggal Pembayaran</td>
                  <td>:</td>
                  <td>{moment(this.props.data.localDateTime).format("lll")}</td>
                </tr>
                <tr>
                  <td>Tanggal Buku</td>
                  <td>:</td>
                  <td>{this.props.data.settlementDate}</td>
                </tr>
                <tr>
                  <td>Nama Bank/LPL</td>
                  <td>:</td>
                  <td>{this.props.data.branchCode}</td>
                </tr>
                <tr>
                  <td>Kode Cabang Bank</td>
                  <td>:</td>
                  <td>{this.props.data.branchCode}</td>
                </tr>
                <tr>
                  <td>NTB</td>
                  <td>:</td>
                  <td>{this.props.data.ntb}</td>
                </tr>
                <tr>
                  <td>NTPN</td>
                  <td>:</td>
                  <td>{this.props.data.ntpn}</td>
                </tr>
                <tr>
                  <td>STAN</td>
                  <td>:</td>
                  <td>{this.props.data.transactionId}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {this.props.data.billingType == "DJP" && (
            <div className="v-gap" id="setoran-djp">
              <div className="divider-label">Data Setoran</div>
              <table className="bpn-table">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Kode Billing</td>
                    <td>:</td>
                    <td>{this.props.data.paymentCode}</td>
                  </tr>
                  <tr>
                    <td>NPWP</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo1}</td>
                  </tr>
                  <tr>
                    <td>Nama Wajib Pajak</td>
                    <td>:</td>
                    <td>{this.props.data.customerName}</td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo6}</td>
                  </tr>
                  <tr>
                    <td>Nomor Obyek Pajak</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo7}</td>
                  </tr>
                  <tr>
                    <td>Jenis Pajak</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo2}</td>
                  </tr>
                  <tr>
                    <td>Jenis Setoran</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo3}</td>
                  </tr>
                  <tr>
                    <td>Masa dan Tahun Pajak</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo4}</td>
                  </tr>
                  <tr>
                    <td>Nomor Ketetapan</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo5}</td>
                  </tr>
                  <tr>
                    <td>Jumlah Setoran</td>
                    <td>:</td>
                    <td>
                      <b>{this.props.data.amount}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Terbilang</td>
                    <td>:</td>
                    <td>
                      {numberService.terbilang(this.props.data.amount)} Rupiah
                    </td>
                  </tr>
                  {/* <tr>
                <td>Mata Uang</td>
                <td>:</td>
                <td>mataUang</td>
              </tr> */}
                </tbody>
              </table>
            </div>
          )}

          {this.props.data.billingType == "DJBC" && (
            <div className="v-gap" id="setoran-djbc">
              <div className="divider-label">Data Setoran</div>
              <table className="bpn-table">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Kode Billing</td>
                    <td>:</td>
                    <td>{this.props.data.paymentCode}</td>
                  </tr>
                  <tr>
                    <td>ID Wajib Bayar</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo1}</td>
                  </tr>
                  <tr>
                    <td>Nama Wajib Bayar</td>
                    <td>:</td>
                    <td>{this.props.data.customerName}</td>
                  </tr>
                  <tr>
                    <td>Tipe Dokumen</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo2}</td>
                  </tr>
                  <tr>
                    <td>Nomor Dokumen</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo3}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Dokumen</td>
                    <td>:</td>
                    <td>{this.props.data.localDateTime}</td>
                  </tr>
                  <tr>
                    <td>Kode KPBC</td>
                    <td>:</td>
                    <td>{this.props.data.partnerCode}</td>
                  </tr>
                  <tr>
                    <td>Jumlah Setoran</td>
                    <td>:</td>
                    <td>
                      <b>{this.props.data.amount}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Terbilang</td>
                    <td>:</td>
                    <td>
                      {numberService.terbilang(this.props.data.amount)} Rupiah
                    </td>
                  </tr>
                  {/* <tr>
                <td>Mata Uang</td>
                <td>:</td>
                <td>mataUang</td>
              </tr> */}
                </tbody>
              </table>
            </div>
          )}

          {this.props.data.billingType == "DJA" && (
            <div className="v-gap" id="setoran-dja">
              <div className="divider-label">Data Setoran</div>
              <table className="bpn-table">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Kode Billing</td>
                    <td>:</td>
                    <td>{this.props.data.paymentCode}</td>
                  </tr>
                  <tr>
                    <td>Nama Wajib Setor</td>
                    <td>:</td>
                    <td>{this.props.data.customerName}</td>
                  </tr>
                  <tr>
                    <td>Kode Kementerian</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo1}</td>
                  </tr>
                  <tr>
                    <td>Kode Eselon</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo2}</td>
                  </tr>
                  <tr>
                    <td>Kode Satuan Kerja</td>
                    <td>:</td>
                    <td>{this.props.data.billingInfo.billingInfo3}</td>
                  </tr>
                  <tr>
                    <td>Jumlah Setoran</td>
                    <td>:</td>
                    <td>
                      <b>{this.props.data.amount}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Terbilang</td>
                    <td>:</td>
                    <td>
                      {numberService.terbilang(this.props.data.amount)} Rupiah
                    </td>
                  </tr>
                  {/* <tr>
                <td>Mata Uang</td>
                <td>:</td>
                <td>mataUang</td>
              </tr> */}
                </tbody>
              </table>
            </div>
          )}

          <div className="bpn-footer">
            Informasi ini adalah hasil cetakan komputer dan tidak memerlukan
            tanda tangan.
          </div>
        </div>
      </DialogContainer>
    );
  }
}
