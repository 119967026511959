import React from 'react';
import './CornerRibbon.scss';

const CornerRibbon = ({label, style}) => (
    <div className="mpk-corner-ribbon">
        <div className="wrapper">
            <div className="ribbon" style={style ? style : {}}>{label}</div>
        </div>
    </div>
)

export default CornerRibbon;