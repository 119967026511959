import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import PembayaranAllService from './PembayaranAll.service';
import ListView from '../../../components/entity/listView';
import { Divider } from 'react-md';
import hosts from '../../../services/hosts';
import http from '../../../services/http.service';
import { Field, reduxForm, getFormValues, change} from 'redux-form';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox, Fileinput, Progress, Datetimepicker} from '../../../components/form/index';
import download from 'downloadjs'
import iziToast from 'izitoast';
import moment from 'moment';

export class PembayaranAllView extends ListView {
  selectableRows=true
  editDialog  = false;
  addDialog   = false;
  service     = PembayaranAllService
  stream      = true;

  _barActions = () => {
    var arr = [
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.props.history.push('/payment-all/create-payment')

    },
    {
      label:'word.download',
      iconClassName:'mdi mdi-download',
      onClick:() => this._download()
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    }
  ]

  return arr
  }

  beforeFetch(params) {
    if(params.startDate && params.endDate) {
      console.log(params.startDate, 'asdads')
      params.dateField = 'localDateTime'
      params.startDate = new Date(params.startDate).getTime()
      params.endDate = new Date(params.endDate).getTime()
    }

  }

  msgMap = {
    "00": "Transaksi berhasil",
    "01": "Kode billing tidak terdaftar / Tagihan tidak tersedia",
    "02": "Kode billing kadaluarsa/expired",
    "03": "Total tagihan tidak sama / Data pembayaran tidak sesuai",
    "04": "No. Rekening Persepsi tidak terdaftar/ditemukan",
    "05": "Kode partner tidak terdaftar",
    "06": "authCode salah",
    "27": "Tagihan telah terbayar di Bank/Lembaga lai",
    "31": "Kode partner salah",
    "32": "Kode mata uang tidak terdaftar",
    "88": "Tagihan telah terbayar",
    "90": "Time out",
    "92": "Exception error",
    "97": "CA belum terdaftar di zona waktu tertentu",
    "98": "Panjang message (length) tidak sesuai",
    "99": "Failed/Entity data billing salah"
  }

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.kodeBilling`,       value: 'paymentCode'},
    {isSortable: false,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tipeBilling`,       value: "billingType"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.namaPembayaran`,    value: "customerName"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.jmlSetoran`,        value: "amount"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.ntl`,               value: "ntb"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.ntpn`,              value: "ntpn"},
    {isSortable: false,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tglBayar`,          value: (val) => <div className='mpk-font-size-S' style={{whiteSpace: 'nowrap'}}> {moment(val.localDateTime).format("DD MMM YYYY HH:mm:ss")} </div>, type: 'func'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tglBuku`,           value: "settlementDate"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.responseCode`,        value: "responseCode"},
    {isSortable: false, show: true,  isSearchable:false, label: `entities.${this.service.name}.responseMsg`,        value: (d)=> {
      return this.msgMap[d.responseCode]?this.msgMap[d.responseCode]:""
    }, type: 'func'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.status`,            value: "status"},
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Status'
          name='status'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options       = {[
            {id: 'Paid', name: 'Paid'},
            {id: 'Pending Paid', name: 'Pending Paid'},
            {id: 'Failed Paid', name: 'Failed Paid'},
            {id: 'Waiting Refund', name: 'Waiting Refund'}
          ]}
        />
        <this.ig.Field
          label='Start Date'
          name='startDate'
          className="md-cell md-cell--12"
          component={this.ig.Datepicker}
        />
        <this.ig.Field
          label='End Date'
          name='endDate'
          className="md-cell md-cell--12"
          component={this.ig.Datepicker}
        />

        <div style={{height: 110}} />
        <Divider style={{width: '100%'}} />
        {this.defaultSearchForm()}
      </this.SearchContainer>
    )
  }

  async _download(type) {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Download Report ',
      initialValue: {d : 'asd'},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan men-download data terpilih?"
    }))

    if(p) {
      var param = this.props.searchForm?this.props.searchForm:{}
      if(!(param.startDate && param.endDate)) {
        iziToast.warning({title: '', message: 'Tanggal Awal dan Tanggal Akhir harus di isi terlebih dahulu'})
        return false
      }

      console.log(param)

      var res = await this.service.api.report(moment(param.startDate+" 00:00:00").valueOf(), moment(param.endDate+" 23:59:59").valueOf())

      var token = http.defaults.headers.common['Authorization'].replace("Bearer ", "")
      var url = (hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl+`/websocket/report/mpn?access_token=${token}&startDate=1596214800000&endDate=1598893199000`).replace('http', 'ws').replace('https', 'wss')
      var socket = new WebSocket(url)
      var progress = this.context.showDialog((props, res, rej) =>({
        title: 'Mohon ditunggu',
        initialValue: {progress: undefined},
        height: 'auto',
        width: 400,
        okText: 'Submit',
        disableOk: true,
        cancelText: 'Close',
        // contentStyle: {overflow: 'inherit'},
        text: <div className="md-grid">
          <Field
              label         = {"Nama File"}
              name          = 'progress'
              className     = 'md-cell md-cell--12'
              component     = {Progress}
            />
        </div>
      }))

      var progressNum = undefined;
      var interval = setInterval(()=> {
        this.props.dispatch(change('global_dialog', 'progress', undefined))
      }, 1000)

      socket.onopen = async (e) => {
        iziToast.success({title: 'Info', message: 'File Sedang di proses'})

        console.log(`[/websocket/report/mpn][open] Connection established`);
      }

      socket.onmessage = async (event) => {
        console.log(`[/websocket/report/mpn][message] Data received from server: ${event.data}`);
        // this.initData()
        if(event.data.search("{") >= 0) {
          var data = JSON.parse(event.data)
          if(data.count) {
            progressNum = parseInt(data.count)
            this.props.dispatch(change('global_dialog', 'progress', progressNum))
          }

          if(data.file) {
            this.props.dispatch(change('global_dialog', 'progress', 100))
            var dInfo = await this.context.showDialog((props, res, rej) =>({
              title: 'Berhasil',
              height: 'auto',
              width: 400,
              okText: 'Download',
              // disableOk: true,
              cancelText: 'Close',
              // contentStyle: {overflow: 'inherit'},
              text: 'File report telah terbentuk'
            }))

            if(dInfo) {
              let res = await this.service.api.download(data.file);
              let filename = data.file
              download(res.data, filename);
            }
          }
        }
      };

      socket.onclose = async (event)=> {
        if (event.wasClean) {
          console.log(`[/websocket/report/mpn][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
          console.log(`[/websocket/report/mpn][close] Connection died`);
        }

      };

      socket.onerror = (error) => {
        console.log(`[error] ${error.message}`);
      };
    }
  }
}

class PembayaranAllViewDefault extends PembayaranAllView {
  selectableRows=false
}


export default connect((state) => ({
  ...PembayaranAllService.stateConnectSetting()(state),
  searchForm: getFormValues('payment-all_search')(state)
}), PembayaranAllService.actionConnectSetting())(PembayaranAllViewDefault)
