/**
 * Created by dwiargo on 11/29/17.
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Login from './Login';
import ResetPassword from './ResetPassword';

export default actions => (
  <Switch>
    <Route path="/account/login"
           exact render={(props) => (
      <Login router={props} actions={actions} />
    )}/>

    <Route path="/reset/finish"
           exact render={(props) => (
      <ResetPassword router={props} actions={actions} />
    )}/>
  </Switch>
);
