import React from 'react';
import './BgSlice.scss';

const BgSlice = ( props ) => (
  <div className="mpk-bg-slice">
    <div className="parallelogram-1"/>
    {/* <div className="parallelogram-2"/> */}
    <div className="parallelogram-3"/>
    {/* <div className="parallelogram-4"/> */}
    <div className="parallelogram-5"/>
  </div>
)

export default BgSlice;