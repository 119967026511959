/**
 * Created by dwiargo on 11/30/17.
 */

import * as types from '../actions/actionTypes';
import _ from 'lodash';

const initialState = () => ({
  columns:[],
  data:[],
  isLoading:false,
  itemActions:[],
  params:{
    page:1,
    size:10,
    column:'',
    keyword:'',
    sortBy:'createdAt',
    sort:'ASC',
    total:0,
    startDate:'',
    endDate:''
  },
  confirmation:{},
  selected:[],
  selectedItem:null,
  error:{
    isError:false,
    title:'',
    message:''
  }
});

let idx, data, columns, target, item, update, selected;

export default (state = initialState(), action = {}) => {
  switch (action.type){

    case types.IMAGE_SET_PROPERTIES:
      if(action.properties.params) {
        action.properties.params = _.merge(state.params, action.properties.params)
      }

      return Object.assign({}, state, action.properties);

    case types.IMAGE_SET_DATA:
      return Object.assign({}, state, {
        data:action.data
      });

    case types.IMAGE_SET_COLUMNS:
      return Object.assign({}, state, {
        columns:action.columns
      });

    case types.IMAGE_UPDATE_COLUMN:
      columns = state.columns;
      target = _.find(columns, action.column);
      update = _.merge(target, action.update);
      idx = columns.indexOf(target);
      columns.splice(idx, 1, update);

      return Object.assign({}, state, {
        columns:columns
      });

    case types.IMAGE_UPDATE_PARAMS:
      let params = _.merge(state.params, action.params);
      return Object.assign({}, state, {
        params:params
      });

    case types.IMAGE_ADD_DATA_ITEM:
      return Object.assign({}, state, {
        data:state.params.sort === 'ASC' ? [action.item, ...state.data] : [...state.data, action.item],
        total:state.total + 1
      });

    case types.IMAGE_DELETE_DATA_ITEM:
      data = state.data;
      item = _.find(data, action.item);
      idx = data.indexOf(item);
      data.splice(idx, 1);

      selected = state.selected;
      item = _.find(selected, action.item);
      idx = selected.indexOf(item);
      selected.splice(idx,1);

      return Object.assign({}, state, {
        data:data,
        total:state.total - 1,
        selected:selected
      });

    case types.IMAGE_DELETE_DATA_ITEMS:
      return state;

    case types.IMAGE_DELETE_SELECTED_DATA_ITEMS:
      data = state.data.filter(d => {
        item = _.find(state.selected, d);
        return item ? false : true;
      });
      return Object.assign({}, state, {
        data:data,
        selected:[],
        total:state.total - data.length
      });

    case types.IMAGE_UPDATE_DATA_ITEM:
      if(action.index > 0) {
        data = state.data;
        idx = action.index - 1;;
        item = data[idx];
        update = _.merge(item, action.update);
        data.splice(idx, 1, update);
      } else if(action.index === 0){
        data = state.data.map(function(d){
          update = _.merge(d, action.update);
          return update;
        })
      }

      return Object.assign({}, state, {
        data:data
      });

    case types.IMAGE_SELECT_DATA_ITEM:
      if(action.index > 0) {
        item = state.data[action.index - 1];
        selected = state.selected;
        let _item = _.find(selected, item);
        idx = selected.indexOf(_item);
        if (action.isSelect) {
          if(idx < 0) selected.push(item);
        } else {
          if(idx >= 0) selected.splice(idx , 1);
        }
      } else {
        if(action.isSelect) {
          selected = state.data.map(function (d) {
            return d;
          })
        } else {
          selected = [];
        }
      }
      return Object.assign({}, state, {
        selected:selected
      });

    case types.IMAGE_RESET_ALL:
      return Object.assign({}, state, initialState());

    case types.IMAGE_RESET:
      let _initialState = initialState();
      let updatedState = {};
      for(var i = 0 ; i < action.keys.length ; i++){
        let key = action.keys[i];
        updatedState[key] = _initialState[key];
      }
      return Object.assign({}, state, updatedState);

    case types.IMAGE_DESELECT_DATA_ITEM:
      return Object.assign({}, state, {
        selectedItem:null
      });

    case types.IMAGE_RESET_PARAMS:
      let newParams = initialState().params;
      return Object.assign({}, state, {
        params:newParams
      });

    case types.IMAGE_TOGGLE_SELECT_DATA_ITEM:
      item = _.find(state.data, action.dataItem);
      selected = state.selected;
      idx = selected.indexOf(item);
      if(idx >= 0) selected.splice(idx, 1);
      else selected.push(item);
      return Object.assign({}, state, {
        selected:selected
      });

    default:
      return state;
  }
}