import React from 'react';
import {LinearProgress} from "react-md";

export default class Progress extends React.Component {
  render() {
    let { input, meta: { touched, error }, ...others } = this.props;

    return <LinearProgress {...others} value={input.value} />
  }
}
