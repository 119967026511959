import ApiService from '../../../services/api.service';

class PelaporanService extends ApiService {
  name= 'pelaporan';
  path= 'lhp';

  api = {
    getPelimpahan: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/pelimpahan`)
      res.data = JSON.parse("["+res.request.responseText.split("}{").join("},{")+"]")

      return res
    },

    putPelimpahan: async(data) => {
      var res = await this.http.put(`${this.apiLocation}/lhp/${data.id}/add_pelimpahan`, data)

      return res
    },

    deletePelimpahan: async(data) => {
      var res = await this.http.put(`${this.apiLocation}/lhp/${data.id}/remove_pelimpahan`, data)

      return res
    },

    getStatus: async(id) => {
      var res = await this.http.get(`${this.apiLocation}/pelaporan/get_status_pelaporan/${id}`)
      return res
    },

    createDnp: async (id) => {
      var res = await this.http.post(`${this.apiLocation}/dnp`, {lhpId: parseInt(id)})
      return res
    },

    deleteDnp: async (id) => {
      var res = await this.http.delete(`${this.apiLocation}/dnp/${id}`)
      return res
    },

    getDnp: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/dnp/${id}`, {params: {disableToast: true}})

      return res
    },

    dnpAddItem: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/dnp/${data.id}/add_mpn_transaction`, data)

      return res
    },
    dnpDeleteItem: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/dnp/${data.id}/remove_mpn_transaction`, data)

      return res
    },

    createRekeningKoran: async (id) => {
      var res = await this.http.post(`${this.apiLocation}/rekening_koran`, {lhpId: parseInt(id)})
      return res
    },

    getRekeningKoran: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/rekening_koran/${id}`, {params: {disableToast: true}})

      return res
    },

    deleteRekeningKoran: async (id) => {
      var res = await this.http.delete(`${this.apiLocation}/rekening_koran/${id}`)

      return res
    },

    getRekonTrx: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/payment_recon/${id}`, {params: {disableToast: true}})

      return res
    },

    deleteRekonTrx: async (id) => {
      var res = await this.http.delete(`${this.apiLocation}/payment_recon/${id}`, {params: {disableToast: true}})

      return res
    },

    updateRekonTrx: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/payment_recon/${id}/apply`, {params: {disableToast: true}})

      return res
    },

    uploadPaymentRecon: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/payment_recon`, data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      return res
    },

    verify: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/pelaporan/verify_execute/${id}`)
      return res
    },

    generateFile: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/pelaporan/generate_file_execute/${id}`)
      return res
    },

    downloadLpl: async (filename) => {
      var res = await this.http.get(`${this.apiLocation}/pelaporan/download_lhp/${filename}`, {
        responseType: 'blob'
      })

      return res
    },
    downloadDnp: async (filename) => {
      var res = await this.http.get(`${this.apiLocation}/pelaporan/download_dnp/${filename}`, {
        responseType: 'blob'
      })

      return res
    },
    downloadRekeningKoran: async (filename) => {
      var res = await this.http.get(`${this.apiLocation}/pelaporan/download_rk/${filename}`, {
        responseType: 'blob'
      })

      return res
    },
    getCashRekon: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/cash_recon/${id}`, {params: {disableToast: true}})

      return res
    },
    postCashRekon: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/cash_recon`, data)

      return res
    },
    deleteCashRekon: async (id) => {
      var res = await this.http.delete(`${this.apiLocation}/cash_recon/${id}`)

      return res
    },

    submitFileLapor: async (id, data) => {
      var res = await this.http.put(`${this.apiLocation}/pelaporan/submit/${id}`, data)

      return res
    }
  }

  // apiMockup= {
  //   find: [{
  //     kodeBilling: '123124124124',
  //     tipeBilling: 'DJP',
  //     namaPelaporan: 'Entah Sifulan',
  //     jmlSetoran: '220000',
  //     ntl: "123213123",
  //     ntpn: "123123123",
  //     tglBayar: '01/01/2019',
  //     tglBuku: '01/01/2019',
  //     status: 'PAID'
  //   }]
  // }
}

export default new PelaporanService().init()
