import ApiService from '../../../services/api.service';

class PeriodePelimpahanService extends ApiService {
  name= 'periode-pelimpahan';
  path= 'periode_pelimpahan';

  api = {
    getPeriode: async (id, bookDate)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/${bookDate}`)

      return res
    }
  }

  // apiMockup= {
  //   find: [{
  //     kodeBilling: '123124124124',
  //     tipeBilling: 'DJP',
  //     namaPeriodePelimpahan: 'Entah Sifulan',
  //     jmlSetoran: '220000',
  //     ntl: "123213123",
  //     ntpn: "123123123",
  //     tglBayar: '01/01/2019',
  //     tglBuku: '01/01/2019',
  //     status: 'PAID'
  //   }]
  // }
}

export default new PeriodePelimpahanService().init()
