/**
 * Created by dwiargo on 2/22/18.
 */

import React, { Component } from "react";
import {
  httpService,
  LoaderInfo,
  locationService,
  rdxConnect
} from "../src/libs/react-mpk";
import hosts from "./services/hosts";
import t from "counterpart";

class AppInfo extends Component {
  constructor() {
    super();
    this.state = {
      onProgress: true
    };
  }
  componentWillMount() {
    t.setLocale("id");
    httpService
      .get({
        url: "/api/portals/host"
      })
      .then(
        response => {
          const {
            appInfo,
            appConsole,
            articleOptions,
            constant,
            apiGateway,
            payment
          } = response.data;
          hosts.setHost(response.data);
          this.props.globalActions.setProperties({
            readyHosts: true,
            timestamp: new Date(response.data.timestamp),
            appInfo,
            appConsole,
            articleOptions,
            constant,
            apiGateway,
            payment
          });
          setTimeout(() => this.setState({ onProgress: false }), 1000);
        },
        error => {
          locationService.errorPage(error);
        }
      );
  }

  render() {
    return (
      <div className="mpk-full viewport-width viewport-height">
        {this.state.onProgress ? (
          <LoaderInfo statusText="loading global information.." />
        ) : (
          this.props.children
          // <div></div>
        )}
      </div>
    );
  }
}

export default rdxConnect(AppInfo);
