/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from "react";
import Route from "react-router-dom/Route";
import { Paper } from "react-md";
import { LocaleSwitcher } from "../../libs/react-mpk";
import Translate from "react-translate-component";

import routes from "./routes";

import "./Account.scss";

const year = new Date().getFullYear();
const links = [
  {
    label: "sentence.generalInfo.links.privacyPolicy"
  },
  {
    label: "sentence.generalInfo.links.termsOfService"
  },
  {
    label: "sentence.generalInfo.links.disclaimer"
  },
  {
    label: "sentence.generalInfo.links.help"
  }
];

class Account extends Component {
  render() {
    return (
      <div className="mpk-full width height account">
        <div className="bg">
          <div className="parallelogram-1" />
          <div className="parallelogram-2" />
          <div className="parallelogram-3" />
          <div className="parallelogram-4" />
          <div className="parallelogram-5" />
        </div>
        <div
          className="account-main mpk-content mpk-position relative"
          style={{ top: 0 }}
        >
          <div className="account-header mpk-layout flex-none mpk-position absolute">
            <div className="flex" />
            <LocaleSwitcher />
          </div>
          <Route>{routes()}</Route>
        </div>
      </div>
    );
  }
}

export default Account;
