import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import CaOnlyService from './CaOnly.service';
import ListView from '../../../components/entity/listView';
import SaOnlyService from './SaOnly.service';
import FailedService from './Failed.service';

export class CaOnlyView extends ListView {
  viewType    = 2;
  editDialog  = false;
  addDialog   = false;
  service     = CaOnlyService
  stream      = true;

  componentDidMount() {
    this.firstCall = false;
    this.props.setClick(this.fetchData);
  }

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.caId`,             value: 'caId'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.caName`,           value: 'caName'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.settlementDate`,   value: 'settlementDate'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.curr`,             value: 'currency'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.billingCode`,      value: 'paymentCode'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.ntb`,              value: 'ntb'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.ntpn`,             value: 'ntpn'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.noSakti`,          value: 'noSakti'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.amount`,           value: 'amount'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.status`,           value: 'status'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.description`,      value: 'description'}
  ]

  translate=false


  titleHeader() {
    return ""
  }


  beforeFetch(params) {
    params.id = this.props.id
  }

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}

@connect(SaOnlyService.stateConnectSetting(), SaOnlyService.actionConnectSetting())
export class SaOnlyView extends CaOnlyView {
  service = SaOnlyService
}

@connect(FailedService.stateConnectSetting(), FailedService.actionConnectSetting())
export class FailedView extends CaOnlyView {
  service = FailedService
}

export default connect(CaOnlyService.stateConnectSetting(), CaOnlyService.actionConnectSetting())(CaOnlyView)
