import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import RekeningKoranLaporanService from './RekeningKoranLaporanService';
import ListView from '../../../components/entity/listView';

export class RekeningKoranLaporanView extends ListView {
  viewType    = 2;
  editDialog  = false;
  addDialog   = false;
  service     = RekeningKoranLaporanService
  stream      = true;
  selectableRows=false

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tanggal`,           value: 'transactionDateTime', type: 'date'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.debit`,             value: "isDebit", type: 'boolean'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.kredit`,            value: "isCredit", type: 'boolean'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.nominal`,             value: "amount", type: 'number'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.saldo`,             value: "balance", type: 'number'}
  ]

  translate=false


  titleHeader() {
    return ""
  }


  beforeFetch(params) {
    params.id = this.props.parentId
  }

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [

  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }

  componentDidMount() {
    this.firstCall = false;
    this.props.setParent('rekeningKoranFetch', this.fetchData)
  }
}


export default connect(RekeningKoranLaporanService.stateConnectSetting(), RekeningKoranLaporanService.actionConnectSetting())(RekeningKoranLaporanView)
