import {
  auth,
  table,
  global,
  toast,
  dialog,
  tmpObject
} from "../../libs/react-mpk/redux/reducers";
import { reducer as formReducer } from "redux-form";
import image from "./imageReducer";

import { combineReducers } from "redux";

const entityReducer = combineReducers({
  pembayaran: require("../../modules/Main/Pembayaran/Pembayaran.service")
    .default.reducer,
  "payment-all": require("../../modules/Main/PembayaranAll/PembayaranAll.service")
    .default.reducer,
  "notif-bank": require("../../modules/Main/NotifBank/NotifBank.service")
    .default.reducer,
  pelimpahan: require("../../modules/Main/pelimpahan/Pelimpahan.service")
    .default.reducer,
  "periode-pelimpahan": require("../../modules/Main/PeriodePelimpahan/PeriodePelimpahan.service")
    .default.reducer,
  pelaporan: require("../../modules/Main/pelaporan/Pelaporan.service").default
    .reducer,
  "payment-pelimpahan": require("../../modules/Main/pelimpahan/PelimpahanPayment.service")
    .default.reducer,
  "lhp-pelimpahan": require("../../modules/Main/Pelaporan/LhpPelimpahan.service")
    .default.reducer,
  "dnp-pelimpahan": require("../../modules/Main/Pelaporan/DnpPelimpahan.service")
    .default.reducer,
  "rekening-koran": require("../../modules/Main/Pelaporan/RekeningKoran.service")
    .default.reducer,
  "ca-only": require("../../modules/Main/Pelaporan/CaOnly.service").default
    .reducer,
  "sa-only": require("../../modules/Main/Pelaporan/SaOnly.service").default
    .reducer,
  failed: require("../../modules/Main/Pelaporan/Failed.service").default
    .reducer,
  refund: require("../../modules/Main/PembayaranAll/PembayaranRefund.service")
    .default.reducer,
  mutasi: require("../../modules/Main/Mutasi/Mutasi.service").default.reducer,
  TipeTransaksi: require("../../modules/Main/TipeTransaksi/TipeTransaksi.service")
    .default.reducer,
  LaporanBulanan: require("../../modules/Main/LaporanBulanan/LaporanBulananService")
    .default.reducer,
  "pernyataan-saldo": require("../../modules/Main/LaporanBulanan/PernyataanSaldoService")
    .default.reducer,
  "rekening-koran-laporan": require("../../modules/Main/LaporanBulanan/RekeningKoranLaporanService")
    .default.reducer,
  "hari-libur": require("../../modules/Main/HariLibur/HariLiburService").default
    .reducer
  // END OF PAYROLL ----------------------------------------------------------
});

const rootReducer = combineReducers({
  auth,
  table,
  global,
  toast,
  image,
  dialog,
  tmpObject,
  global,
  form: formReducer,
  auth: auth,
  authHalona: require("../../services/auth.service").default.reducer,
  entity: entityReducer
});

export default rootReducer;
