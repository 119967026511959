export const IMAGE_SET_DATA = "IMAGE_SET_DATA";
export const IMAGE_SET_COLUMNS = "IMAGE_SET_COLUMNS";
export const IMAGE_SET_PROPERTIES = "IMAGE_SET_PROPERTIES";
export const IMAGE_UPDATE_COLUMN = "IMAGE_UPDATE_COLUMN";
export const IMAGE_UPDATE_PARAMS = "IMAGE_UPDATE_PARAMS";
export const IMAGE_ADD_DATA_ITEM = "IMAGE_ADD_DATA_ITEM";
export const IMAGE_DELETE_DATA_ITEM = "IMAGE_DELETE_DATA_ITEM";
export const IMAGE_DELETE_DATA_ITEMS = "IMAGE_DELETE_DATA_ITEMS";
export const IMAGE_UPDATE_DATA_ITEM = "IMAGE_UPDATE_DATA_ITEM";
export const IMAGE_DELETE_SELECTED_DATA_ITEMS = "IMAGE_DELETE_SELECTED_DATA_ITEMS";
export const IMAGE_SELECT_DATA_ITEM = "IMAGE_SELECT_DATA_ITEM";
export const IMAGE_DESELECT_DATA_ITEM = "IMAGE_DESELECT_DATA_ITEM";
export const IMAGE_RESET_ALL = "IMAGE_RESET_ALL";
export const IMAGE_RESET = "IMAGE_RESET";
export const IMAGE_RESET_PARAMS = "IMAGE_RESET_PARAMS";
export const IMAGE_TOGGLE_SELECT_DATA_ITEM = "IMAGE_TOGGLE_SELECT_DATA_ITEM";