import ApiService from '../../../services/api.service';

class LaporanBulananService extends ApiService {
  name= 'LaporanBulanan';
  path= 'pelaporan_bulanan';

  api = {
    getPeriode: async (id, bookDate)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/${bookDate}`)

      return res
    },
    generatePs: async (id)=> {
      var res = await this.http.get(`${this.apiLocation}/pelaporan_bulanan/${id}/request_generate_pernyataan_saldo`)

      return res
    },
    reqPs: async (id)=> {
      var res = await this.http.get(`${this.apiLocation}/report/${id}/pernyataan_saldo`)

      return res
    },
    generateRk: async (id)=> {
      var res = await this.http.get(`${this.apiLocation}/pelaporan_bulanan/${id}/request_generate_rekening_koran`)

      return res
    },
    reqRk: async (id)=> {
      var res = await this.http.get(`${this.apiLocation}/report/${id}/rekening_koran`)

      return res
    },
    download: async (filename)=> {
      var res = await this.http.get(`${this.apiLocation}/report/${filename}/download`, {responseType: 'blob'})

      return res
    }
  }
}

export default new LaporanBulananService().init()
