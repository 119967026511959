/**
 * Created by dwiargo on 12/13/17.
 */

import CardArticleThumbnail from './CardArticleThumbnail';
import CardArticleHeadline from './CardArticleHeadline';

export {
  CardArticleThumbnail,
  CardArticleHeadline
};
