import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import PembayaranRefundService from './PembayaranRefund.service';
import ListView from '../../../components/entity/listView';

@connect(PembayaranRefundService.stateConnectSetting(), PembayaranRefundService.actionConnectSetting())
export default class PembayaranRefundView extends ListView {
  editDialog  = false;
  addDialog   = false;
  service     = PembayaranRefundService
  stream      = true;
  viewType    = 2

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.paymentCode`,       value: "paymentCode"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.bankCode`,          value: "bankCode"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.bankName`,          value: "bankName"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.accountNumber`,     value: "accountNumber"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.accountHolderName`, value: "accountHolderName"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.amount`,            value: "amount", type: 'number'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.description`,       value: "description"}
  ]

  fetchOption() {
    return {
      path: `lhp/${this.props.lphId}/refund`
    }
  }

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    // {
    //   label:"More Info",
    //   iconClassName:"mdi mdi-bell",
    //   onClick: (item) => this.editItem(item)
    // }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }


}
