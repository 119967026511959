import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  Card,
  CardActions,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form/index';
import NotifBankService from './NotifBank.service';
import utilsService from '../../../services/utils.service';
import counterpart from 'counterpart';

@reduxForm({form: 'NotifBankForm', destroyOnUnmount: true})
@connect((state) => ({
  formData    : getFormValues('NotifBankForm')(state)
}))
export default class NotifBankForm extends FormView {
  service = NotifBankService
  viewType=2

  titleHeader() {
    return `entities.${this.service.name}.formTitle`;
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isRincian: false
    }
  }


  initialData={
  }


  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div></div>
    )
  }

  async initData() {
  }

  formView() {
    return <div>
      <Card>
        <div className='md-grid'>
          <div className="md-cell md-cell--6">
            <Field
              label         = {counterpart(`entities.${this.service.name}.transactionId`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.transactionCode`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.transactionPosition`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.accountNo`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
          </div>
          <div className="md-cell md-cell--6">
            <Field
              label         = {counterpart(`entities.${this.service.name}.status`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.notifDateTime`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.transactionDate`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.accountCurrency`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
          </div>
          <Field
            className="md-cell md-cell--12"
            label         = {counterpart(`entities.${this.service.name}.amount`)}
            name          = 'kodeBilling'
            component     = {TextfieldMask}
            money = {","}
          />
          <Field
            className="md-cell md-cell--12"
            label         = {counterpart(`entities.${this.service.name}.trxDescription`)}
            name          = 'kodeBilling'
            component     = {Textfield}
          />

          <div className="md-cell md-cell--6">
            <Field
              label         = {counterpart(`entities.${this.service.name}.fromAccount`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.fromAccountNo`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.fromBank`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.externalReference`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.openingBalance`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.tellerId`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
          </div>
          <div className="md-cell md-cell--6">
            <Field
              label         = {counterpart(`entities.${this.service.name}.toAccount`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.toAccountNo`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.toBank`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.seq`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart(`entities.${this.service.name}.closingBalance`)}
              name          = 'kodeBilling'
              component     = {Textfield}
            />

          </div>
          <Field
            label         = {counterpart(`entities.${this.service.name}.errorCode`)}
            className     ="md-cell md-cell--12"
            name          = 'kodeBilling'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart(`entities.${this.service.name}.errorDescription`)}
            className     ="md-cell md-cell--12"
            name          = 'kodeBilling'
            component     = {Textfield}
          />

        </div>
      </Card>
      <br/>
      {this.mpnPaymentView()}
    </div>
  }

  mpnPaymentView() {
    return <div>
    <Card>
      {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Data Refund" /> */}
      {/* <Divider/> */}
      <DataTable plain>
        <TableHeader>
          <TableRow>
            <TableColumn className=' md-text-left'>Tgl Jam Tagihan</TableColumn>
            <TableColumn className=' md-text-right'>Kode Billing</TableColumn>
            <TableColumn className=' md-text-left'>Tipe Billing</TableColumn>
            <TableColumn className=' md-text-left'>Nama Rekening</TableColumn>
            <TableColumn className=' md-text-left'>No Rekening Rekening</TableColumn>
            <TableColumn className=' md-text-left'>Jml Setor</TableColumn>
            <TableColumn className=' md-text-left'>Tgl Buku</TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableColumn className=' md-text-left'>27/11/1990</TableColumn>
            <TableColumn className=' md-text-right'>123</TableColumn>
            <TableColumn className=' md-text-left'>DJP</TableColumn>
            <TableColumn className=' md-text-left'>Entah </TableColumn>
            <TableColumn className=' md-text-left'>123019328</TableColumn>
            <TableColumn className=' md-text-left'>RP. 1.000.000,-</TableColumn>
            <TableColumn className=' md-text-left'>27/9</TableColumn>
            <TableColumn className='md-text-center'>
                <Button style={{margin: 0}} secondary flat onClick={()=> {}}>Hapus</Button>
              </TableColumn>
          </TableRow>
          <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
            <TableColumn className=' md-text-left'></TableColumn>
            <TableColumn className=' md-text-right'></TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
            <TableColumn className='md-text-center'>
              <Button primary flat onClick={this._tambahRefund}>Match Tagihan</Button>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable>
    </Card>
    </div>
  }



}
