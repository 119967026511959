import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import NotifBankService from './NotifBank.service';
import ListView from '../../../components/entity/listView';

@connect(NotifBankService.stateConnectSetting(), NotifBankService.actionConnectSetting())
export default class NotifBankView extends ListView {
  editDialog  = false;
  addDialog   = false;
  service     = NotifBankService

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tanggal`,       value: "tanggal"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.kode`,       value: "kode"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.dk`,       value: "dk"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.jumlah`,       value: "jumlah"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.deskripsi`,       value: "deskripsi"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.bankAsal`,       value: "bankAsal"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.rekAsal`,       value: "rekAsal"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.status`,       value: "status"},
  ]
}
