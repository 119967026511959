import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import PembayaranService from './Pembayaran.service';
import ListView from '../../../components/entity/listView';

@connect(PembayaranService.stateConnectSetting(), PembayaranService.actionConnectSetting())
export default class PembayaranView extends ListView {
  service     = PembayaranService

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.kodeBilling`,       value: (d)=> {
      return d.billingInfo.billingInfo1+d.billingInfo.billingInfo2+d.billingInfo.billingInfo3
    }, type: 'func'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tipeBilling`,       value: "tipeBilling"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.namaPembayaran`,    value: "namaPembayaran"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.jmlSetoran`,        value: "jmlSetoran"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.ntl`,               value: "ntl"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.ntpn`,              value: "ntpn"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tglBayar`,          value: "tglBayar"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tglBuku`,           value: "tglBuku"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.status`,            value: "status"},
  ]


}
