import ApiService from '../../../services/api.service';

class PembayaranRefundService extends ApiService {
  name= 'refund';
  path= 'refund';

  // apiMockup= {
  //   find: [{
  //     kodeBilling: '123124124124',
  //     tipeBilling: 'DJP',
  //     namaPembayaranRefund: 'Entah Sifulan',
  //     jmlSetoran: '220000',
  //     ntl: "123213123",
  //     ntpn: "123123123",
  //     tglBayar: '01/01/2019',
  //     tglBuku: '01/01/2019',
  //     status: 'PAID'
  //   }]
  // }
}

export default new PembayaranRefundService().init()
