import ApiService from '../../../services/api.service';

class PelimpahanService extends ApiService {
  name= 'pelimpahan';
  path= 'pelimpahan';

  api={
    updateSubmission: async (id, data)=> {
      var res = this.http.put(`${this.apiLocation}/${this.path}/${id}/nomor_sakti`, data)

      return res;
    },

    getAllPayment: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/mpn_transaction`)
      res.data = JSON.parse("["+res.request.responseText.split("}{").join("},{")+"]")

      return res
    },

    addAllPayment: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}/${data.id}/add_mpn_transaction`, data)

      return res
    },

    deleteAllPayment: async (data) => {
      var res = await this.http.put(`${this.apiLocation}/${this.path}/${data.id}/remove_mpn_transaction`, data)

      return res
    },

    createInsidental: async (data)=> {
      var res = this.http.post(`${this.apiLocation}/${this.path}/incidental`, data)
      return res;
    },
    updateInsidental: async (data)=> {
      var res = this.http.put(`${this.apiLocation}/${this.path}/${data.id}/incidental`, data)
      return res;
    }
  }
}

export default new PelimpahanService().init()
