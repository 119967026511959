/**
 * @author Ismail Sunny
 * @email ismiletea@gmail.com
 * @create date 2018-01-17 12:16:58
 * @modify date 2018-01-17 12:16:58
 * @desc [description]
*/
import Service from './service';
import env from 'env';
import qs from 'qs';
import * as constant from '../config/constant';

class AuthService extends Service {
  reducer;
  api = {
    login: async (username, password, dispatch=this.emptyFunc) => {
      let res = await this.http.post(this.apiLocation+'/sign-in', {
        rememberMe: true,
        login: username,
        password: password
      }, {
        headers: {
          // Authorization: env.clientAuth,
          'content-type': 'application/json',
          'X-Auth-Token': null
        }
      })

      // set header
      this.http.defaults.headers.common['X-Auth-Token'] = `${res.data.token}`
      this.http.defaults.withCredentials = true;
      localStorage.setItem('token', JSON.stringify(res.data));

      dispatch({
        type: this.type.AUTH_LOGIN,
        data: res.data
      })
    },

    forgotPasswordInit: async (data) => {
      let res = await this.http.post('/api/account/reset_password/init', data);
      return res
    },

    forgotPasswordFinish: async (data) => {
      let res = await this.http.post('/api/account/reset_password/finish', data);
      return res
    },

    logout: async (dispatch) => {
      dispatch({
        type: this.type.AUTH_LOGOUT
      })
      await this.http.get(this.apiLocation+'/sign-out', {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
      });
      localStorage.removeItem('token');
      this.http.defaults.headers.common['X-Auth-Token'] = ''
    },

    getCurrentUser: async(dispatch) => {
      let res = await this.http.get(this.apiLocation+'/user/me');

      res.data.permissionMap = {};

      if(res.data.role && res.data.role.permissions) {
        res.data.permissionMap = res.data.role.permissions.reduce((vp, cv) => {
          vp[cv.permission.name] = cv.allow
          return vp
        }, {})

        res.data.user.permissionMap = res.data.permissionMap
      }

      if(res.data.servicePermission) {
        res.data.permissionMap = pList.reduce(function(pv, cv) {
          pv[cv.method+"__"+cv.clientPath] = cv
          return pv
        }, {})

        res.data.user.permissionMap = res.data.permissionMap
      }

      return dispatch({
        type: this.type.AUTH_SET_USER,
        data: res.data.user
      })
    },

    changeOrganization: async (data, dispatch=()=>{}) => {
      let res = await this.http.get('/api/account/organization/'+data.id);
      let access = {};

      res.data.forEach((d)=> {
        access[d.name] = d
      })

      dispatch({
        type: this.type.AUTH_CHANGE_ORGANIZATION,
        data: {
          organization: data,
          access
        }
      })
    },

    getApprovalSetting: async(dispatch) => {
      let res = await this.http.get(this.apiLocation+'/master/approval-setting', {
        params:{
          page: 1,
          sortBy: 'name',
          size: 100,
          sort: 1
        },
        paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }});

      var data = {}
      console.log(res, 'approval....')
      if(res.data) {
        data = res.data.data.reduce((pv, cv) => {
          pv[cv.name] = cv.enable;

          return pv
        }, {})


      }

      return dispatch({
        type: this.type.GET_APPROVAL_SETTING,
        data
      })
    },

    changeSPT: async (data, dispatch=()=>{}) => {
      dispatch({
        type: this.type.AUTH_CHANGE_SPT,
        data: data
      })
    },

    changeModule: async (data, dispatch=()=>{}) => {
      dispatch({
        type: this.type.AUTH_CHANGE_MODULE,
        data: data
      })
    },

    changeCompany: async (data, dispatch=()=>{}) => {
      dispatch({
        type: this.type.AUTH_CHANGE_COMPANY,
        data: data
      })
    },
    changeMenuType: async (data, dispatch=()=>{}) => {
      dispatch({
        type: this.type.AUTH_CHANGE_MENUTYPE,
        data: data
      })
    },
    getAuth: (user)=> {
      var userAuth = constant.ROLE_EPPT_USER;
      if(user.authorities) {
        user.authorities.forEach((d, i) => {
          if(d.name === constant.ROLE_EPPT_ADMIN) {
            userAuth = constant.ROLE_EPPT_ADMIN;
          }

          if(userAuth !== constant.ROLE_EPPT_ADMIN) {
            if(d.name === constant.ROLE_EPPT_OWNER) {
              userAuth = constant.ROLE_EPPT_OWNER;
            }
          }
        })
      }
      return userAuth;
    },

    getOrgAccess: async (dispatch=()=>{}) => {
      var res = await this.http.get('/api/organization/user/access/all');
      var data = {}
      if(res.data) {
        res.data.forEach((d, i)=> {
          if(!data[d.organizationId+"."+d.access.name]) data[d.organizationId+"."+d.access.name] = {};

          d.userAccessPermission.forEach((e, h) => {
            data[d.organizationId+"."+d.access.name][e.permission.name] = e.permission;
          })
        })
      }

      dispatch({
        type: this.type.AUTH_CHANGE_ORG_ACCESS,
        data
      })
    },

    changeHalonaOrganization: async (data, dispatch=()=>{}) => {
      dispatch({
        type: this.type.CHANGE_HALONA_ORGANIZATION,
        data
      })
    }
  }

  type = {
    AUTH_LOGIN: 'AUTH_LOGIN',
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    AUTH_SET_USER: 'AUTH_SET_USER',
    AUTH_CHANGE_ORGANIZATION: 'AUTH_CHANGE_ORGANIZATION',
    AUTH_CHANGE_SPT: 'AUTH_CHANGE_SPT',
    AUTH_CHANGE_MODULE: 'AUTH_CHANGE_MODULE',
    AUTH_CHANGE_SETTING: 'AUTH_CHANGE_SETTING',
    AUTH_CHANGE_COMPANY: 'AUTH_CHANGE_COMPANY',
    AUTH_CHANGE_MENUTYPE: 'AUTH_CHANGE_MENUTYPE',
    AUTH_CHANGE_ORG_ACCESS: 'AUTH_CHANGE_ORG_ACCESS',
    TOGGLE_GLOBAL_PROGRESS: 'TOGGLE_GLOBAL_PROGRESS',
    GET_APPROVAL_SETTING: 'GET_APPROVAL_SETTING',
    SET_COLLAPSIBLE: 'SET_COLLAPSIBLE',
    SET_SETTING: 'SET_SETTING',
    CHANGE_HALONA_ORGANIZATION: 'CHANGE_HALONA_ORGANIZATION'
  }

  initialState= {
    halonaOrganization: [],
    approvalSetting: {

    },
    showGlobalProgress: false,
    isLoggedIn:false,
    token: {},
    user:{permissionMap: {}},
    currentOrganization: {},
    access: {},
    orgAccess: {},
    currentSPT: {},
    currentModule: 'none',
    currentCompany: {},
    menuType: 'main',
    setting: {
      zoom: '90%',
      menuType: 'Default',
      collapsible: false
    }
  }

  authReducer() {
    var obj = this;
    var settings = {
      size: '90%',
      menuType: 'Default',
      collapsible: false
    }

    if(localStorage.getItem('settings')) {
      var lset = JSON.parse(localStorage.getItem('settings'));

      settings = {
        ...settings,
        ...lset
      }
    }

    obj.initialState.setting = settings;
    return function(state=obj.initialState, action) {
			switch (action.type) {
        case obj.type.SET_COLLAPSIBLE:
        return {
          ...state,
          setting: {
            ...state.setting,
            collapsible: action.data
          }
        }
        case obj.type.AUTH_LOGIN:
        return {
          ...state,
          token: action.data,
          isLoggedIn: true
        }
        case obj.type.AUTH_LOGOUT:
        return {
          ...state,
          token: {},
          isLoggedIn: false,
          user: {}
        }
        case obj.type.AUTH_SET_USER:
        return {
          ...state,
          user: action.data,
          isLoggedIn: true
        }
        case obj.type.AUTH_CHANGE_ORGANIZATION:
        return {
          ...state,
          currentOrganization: action.data.organization,
          access: action.data.access
        }
        case obj.type.AUTH_CHANGE_SPT:
        return {
          ...state,
          currentSPT: action.data
        }
        case obj.type.AUTH_CHANGE_MODULE:
        return {
          ...state,
          currentModule: action.data
        }
        case obj.type.AUTH_CHANGE_COMPANY:
        return {
          ...state,
          currentCompany: action.data
        }
        case obj.type.AUTH_CHANGE_MENUTYPE:
        return {
          ...state,
          menuType: action.data
        }
        case obj.type.AUTH_CHANGE_ORG_ACCESS:
        return {
          ...state,
          orgAccess: action.data
        }
        case obj.type.TOGGLE_GLOBAL_PROGRESS:
        return {
          ...state,
          showGlobalProgress: action.data
        }
        case obj.type.GET_APPROVAL_SETTING:
        return {
          ...state,
          approvalSetting: action.data
        }
        case obj.type.CHANGE_HALONA_ORGANIZATION:
          return {
            ...state,
            halonaOrganization: action.data
          }
        default:
        return state;
      }
    }
  }

  // method
  initReducers() {
    this.reducer = this.authReducer()
  }

	constructor() {
		super();
		this.initReducers()
	}
}


export default new AuthService();
