import React from 'react';
import env from '../../config/env';
import doodle from '../../assets/doodle-website.png';

const AppContainer = ({children}) => (
  <div 
    className="mpk-full viewport-width viewport-height"
    style={{
      background: env.theme === env.themes.SOBATPAJAK ? `url(${doodle})` : 'transparent'
    }}
  >
    {children}
  </div>
)

export default AppContainer;