import ApiService from '../../../services/api.service';

class MutasiService extends ApiService {
  name= 'mutasi';
  path= 'balance';

  api = {
    getSummary: async (id, bookDate)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/${bookDate}`)

      return res
    },
    report: async (startDate, endDate)=> {
      var res = await this.http.get(`${this.apiLocation}/report/balance?startDate=${startDate}&endDate=${endDate}`)

      return res
    },
    download: async (filename)=> {
      var res = await this.http.get(`${this.apiLocation}/report/${filename}/download`, {responseType: 'blob'})

      return res
    }
  }
}

export default new MutasiService().init()
