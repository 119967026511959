/**
 * Created by dwiargo on 11/29/17.
 */

import env from 'env';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const basePath = function() {
  if(env.devMode) {
    return ''
  } else {
    // return '/company/:companyId/:subscriptionId/init'
    return ''
  }
}()

export default (actions, global) => (
  <Switch>
    <Route path={basePath+"/notif-bank"} exact component={require('./NotifBank/NotifBank.view').default}/>
    <Route path={basePath+"/notif-bank/:id"} component={require('./NotifBank/NotifBank.form').default}/>

    <Route path={basePath+"/payment-all"} exact component={require('./PembayaranAll/PembayaranAll.view').default}/>
    <Route path={basePath+"/payment-all/create-payment"} exact component={require('./PembayaranAll/PembayaranAll.create').default}/>
    <Route path={basePath+"/payment-all/:id"} component={require('./PembayaranAll/PembayaranAll.form').default}/>

    <Route path={basePath+"/payment"} exact component={require('./Pembayaran/Pembayaran.view').default}/>
    <Route path={basePath+"/payment/:id"} component={require('./Pembayaran/Pembayaran.form').default}/>

    <Route path={basePath+"/pelimpahan"} exact component={require('./Pelimpahan/Pelimpahan.view').default}/>
    <Route path={basePath+"/pelimpahan/:id"} component={require('./Pelimpahan/Pelimpahan.form').default}/>

    <Route path={basePath+"/pelaporan"} exact component={require('./pelaporan/Pelaporan.view').default}/>
    <Route path={basePath+"/pelaporan/:id"} component={require('./Pelaporan/Pelaporan.form').default}/>

    <Route path={basePath+"/periode-pelimpahan"} exact component={require('./PeriodePelimpahan/PeriodePelimpahan.view').default}/>
    <Route path={basePath+"/periode-pelimpahan/:id"} component={require('./PeriodePelimpahan/PeriodePelimpahan.form').default}/>

    <Route path={basePath+"/mutasi"} exact component={require('./Mutasi/Mutasi.view').default}/>
    <Route path={basePath+"/mutasi/:id"} exact component={require('./Mutasi/Mutasi.form').default}/>

    <Route path={basePath+"/TipeTransaksi"} exact component={require('./TipeTransaksi/TipeTransaksi.view').default}/>
    <Route path={basePath+"/TipeTransaksi/:id"} exact component={require('./TipeTransaksi/TipeTransaksi.form').default}/>

    <Route path={basePath+"/laporan-bulanan"} exact component={require('./LaporanBulanan/LaporanBulananView').default}/>
    <Route path={basePath+"/laporan-bulanan/:id"} component={require('./LaporanBulanan/LaporanBulananForm').default}/>

    <Route path={basePath+"/hari-libur"} exact component={require('./HariLibur/HariLiburView').default}/>
    <Route path={basePath+"/hari-libur/:id"} exact component={require('./HariLibur/HariLiburForm').default}/>

    {/* Admin */}
    <Route path={basePath+"/"} component={require('./Dashboard/Dashboard.view').default}/>
  </Switch>
)





