/**
 * Created by dwiargo on 11/29/17.
 */

import { Connect, connect } from "react-redux";
import React, { Component } from "react";
import ContainerPaper from "../components/ContainerPaper";
import counterpart from "counterpart";
import { TextField, Button, FontIcon } from "react-md";
import Empty from "../../../libs/react-mpk/components/Empty";
import "./Login.scss";
import AuthService from "../../../services/auth.service";
import { Avatar, Tooltipped } from "react-md";
import izitoast from "izitoast";

@connect()
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordView: false,
      signature: {},
      formData: {},
      validation: {},
      error: {},
      status: "wait",
      version: "",
      serverStatus: {
        status: "UP",
        class: "md-avatar--green"
      }
    };
  }

  async componentDidMount() {}

  async checkStatus() {
    try {
      var status = await AuthService.http.get("/management/health");
      var className = "md-avatar--red";
      if (status.data.status == "UP") className = "md-avatar--green";
      this.setState({
        serverStatus: {
          status: status.data.status,
          class: className
        }
      });
    } catch (ex) {
      this.setState({
        serverStatus: {
          status: "Down",
          class: "md-avatar--red"
        }
      });
    }
  }

  async login() {
    var username = this.refs.username.value;
    var password = this.refs.password.value;
    console.log(this.props);
    let res = await AuthService.api.login(
      username,
      password,
      this.props.dispatch,
      this.props.dispatch
    );
    this.props.router.history.push("/");
  }

  async forgotPassword() {
    var email = this.refs.email.value;
    let res = await AuthService.api.forgotPasswordInit({ email });

    izitoast.info({
      title: "Forgot Password",
      message: "Please check your email."
    });
  }

  enter(e) {
    if (e.key === "Enter") {
      this.login();
    }
  }

  toggleShowPassword() {
    this.setState(p => {
      return {
        showForgotPasswordView: !p.showForgotPasswordView
      };
    });
  }

  render() {
    let { validation, error, status, formData } = this.state;

    if (this.state.showForgotPasswordView) {
      return this.forgotPasswordView();
    }

    return (
      <ContainerPaper
        status={status}
        validation={validation}
        headerText={<div>{this.state.version}</div>}
        deck=""
        footer={
          <div className="mpk-margin-N top mpk-layout justify-between mpk-font-weight-M">
            <span
              className="mpk-link mpk-font-weight-M"
              onClick={() => this.toggleShowPassword()}
            >
              {counterpart.translate("word.forgotPassword")}
            </span>
            {/* <span
                className="mpk-link mpk-font-weight-M"
                onClick={() => navService.redirectTo('/account/register')}
                >
                {counterpart.translate('word.createAccount')}
                </span> */}
          </div>
        }
      >
        <div
          className="body"
          onSubmit={e => {
            e.preventDefault();
            this.login();
          }}
        >
          <Tooltipped label="Empty element with tooltip">
            <div
              className={
                "md-inline-block md-avatar " + this.state.serverStatus.class
              }
              style={{
                position: "fixed",
                top: 14.5,
                right: 40,
                height: 18,
                width: 18
              }}
            >
              <div className="md-avatar-content"></div>
            </div>
          </Tooltipped>

          <div className="mpk-flex">
            <TextField
              ref="username"
              id="user-username"
              label="Nama User"
              placeholder="Type your username"
              lineDirection="center"
              type="text"
              required={true}
              onKeyPress={e => this.enter(e)}
            />
            <TextField
              ref="password"
              id="user-password"
              label="password"
              placeholder="Enter your password"
              lineDirection="center"
              type="password"
              onKeyPress={e => this.enter(e)}
              passwordIcon={
                <FontIcon
                  iconClassName="mdi mdi-eye"
                  style={{ fontSize: 16 }}
                />
              }
              required={true}
            />
          </div>
        </div>
        <div onClick={() => this.login()} className="action mpk-flex-none">
          <Button raised secondary type="submit">
            Sign In
          </Button>
        </div>
      </ContainerPaper>
    );
  }

  loginView() {
    if (this.state.showForgotPasswordView) {
      return this.forgotPasswordView();
    }

    return (
      <form>
        <div className="title">Login</div>
        <TextField
          ref="username"
          id="user-username"
          label="username"
          placeholder="Type your username"
          lineDirection="center"
          type="text"
          required={true}
          onKeyPress={e => this.enter(e)}
        />
        <TextField
          ref="password"
          id="user-password"
          label="password"
          placeholder="Enter your password"
          lineDirection="center"
          type="password"
          onKeyPress={e => this.enter(e)}
          passwordIcon={
            <FontIcon iconClassName="mdi mdi-eye" style={{ fontSize: 16 }} />
          }
          required={true}
        />
        <Empty height={16} />
        <Button
          onClick={() => this.login()}
          style={{ width: "100%" }}
          flat
          primary
          swapTheming
        >
          Submit
        </Button>
        <div className="opt">
          <span className="mpk-link" onClick={() => this.toggleShowPassword()}>
            Lupa Password?
          </span>
        </div>
      </form>
    );
  }

  forgotPasswordView() {
    /*return (
      <form>
        <div className="title">Forgot Password</div>
        <TextField
          ref="email"
          id="user-email"
          label="email"
          placeholder="Type your email"
          lineDirection="center"
          type="text"
          required={true}
          onKeyPress={(e)=> this.enter(e)}
          />
        <Button
          onClick={()=> {}}
          style={{width:"100%"}}
          flat primary swapTheming
          >
          Submit
        </Button>
        <div className="opt">
          <span className="mpk-link" onClick={()=> this.toggleShowPassword()}>Login?</span>
        </div>
      </form>
    )*/

    let { validation, error, status, formData } = this.state;

    return (
      <ContainerPaper
        status={status}
        validation={validation}
        headerText={<div>{this.state.version}</div>}
        deck="Pengolahan Pajak Terpadu"
        footer={
          <div className="mpk-margin-N top mpk-layout justify-between mpk-font-weight-M">
            <span
              className="mpk-link mpk-font-weight-M"
              onClick={() => this.toggleShowPassword()}
            >
              {counterpart.translate("word.login")}
            </span>
            {/* <span
              className="mpk-link mpk-font-weight-M"
              onClick={() => navService.redirectTo('/account/register')}
            >
                {counterpart.translate('word.createAccount')}
                </span> */}
          </div>
        }
      >
        <div
          className="body"
          onSubmit={e => {
            e.preventDefault();
            this.forgotPassword();
          }}
        >
          <Tooltipped label="Empty element with tooltip">
            <div
              className={
                "md-inline-block md-avatar " + this.state.serverStatus.class
              }
              style={{
                position: "fixed",
                top: 14.5,
                right: 40,
                height: 18,
                width: 18
              }}
            >
              <div className="md-avatar-content"></div>
            </div>
          </Tooltipped>

          <div className="mpk-flex">
            {/* <TextField
              ref="email"
              id="user-email"
              label="email"
              placeholder="Type your email"
              lineDirection="center"
              type="text"
              required={true}
              onKeyPress={(e)=> this.enter(e)}
            /> */}
            <p>Mohon untuk hubungi admin setempat!</p>
          </div>
        </div>
        {/* <div onClick={()=> this.forgotPassword()} className="action mpk-flex-none">
          <Button raised secondary type="submit">Send</Button>
        </div> */}
      </ContainerPaper>
    );
  }
}

export default Login;
