
import React, { Component } from 'react';
import {SelectField, DropdownMenu, TextField, Button, FontIcon, Avatar, Chip, SVGIcon} from 'react-md';

export default class MultiSelectCustom extends Component {
  // options = [{id: , name: }]
  static defaultProps = {
    valueField: 'id',
    viewField: 'name',
    itemTemplate: function(d) {
      return {
        primaryText: d[this.props.viewField],
        onClick: () => this.handleItemClick(d)
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      viewValue: '',
      menuItems: [],
      chips    : [],
    }
  }

  componentDidMount() {
    var value = this.props.input.value
    if(value.length != ""){
      var options = this.props.options
      var option = options.filter((option)=>{
        return option.id == value
      })
      var viewValue = option[0]
      var valueText = ""
      if(viewValue){
        valueText = viewValue.name
      }
      this.setState({ chips : value })
    } else {}
    // Testing Select Value Option console.log(value, options, option, viewValue, valueText)
    this.buildMenuItems()
  }

  handleItemClick(d) {
    var val = d[this.props.valueField];
    if(this.props.valueField == 'parent') val = d;
    this.props.input.value = val;
    this.setState({viewValue: ''})
    // Chips
    var array = this.state.chips
    var _this = this
    var exists = array.filter(function (exist){
        return exist == _this.props.input.value
    })
    if(exists.length == 0){
        array.push(this.props.input.value)
    }
    this.setState({ chips: array })
    this.props.input.onChange(array)
  }

  handleViewChange(val) {
    this.setState({viewValue: val});
  }

  handleChipClick(index) {
    var array = this.state.chips
    array.pop(index)
    this.setState({ chips: array })
    this.props.input.onChange(array)
  }

  async buildMenuItems() {
    var menuItems = []
    if(this.props.service) {
      let res = await this.props.service.api.find();
      menuItems = res.data.map(this.props.itemTemplate.bind(this))
    } else if(this.props.options) {
      menuItems = this.props.options.map(this.props.itemTemplate.bind(this))
    }

    this.setState({menuItems})
    return menuItems;
  }

  render() {
    let input = this.props.input;
    let meta = this.props.meta;
    if(!meta) meta = {}
    let optInput = {...input}
    let idProps = Math.random().toString(36).substring(7)+this.props.name;
    var _this = this
    return (
        <div className={this.props.className}>
            <DropdownMenu
                id={this.props.id+"_menu"}
                menuItems={this.state.menuItems}
                toggleQuery=".md-text-field-container"
                sameWidth
                className={this.props.className}
                style={{
                    display: 'block',
                    width: '100%',
                    margin: '0px',
                    ...this.props.style
                }}
                anchor={{
                    x: DropdownMenu.HorizontalAnchors.INNER_LEFT,
                    y: DropdownMenu.VerticalAnchors.BOTTOM,
                }}
                position={DropdownMenu.Positions.BELOW}
                {...this.props.dropdownProps}
                >
                <TextField
                    id={idProps}
                    onChange={(val) => this.handleViewChange(val)}
                    value={this.state.viewValue}
                    label={this.props.label}
                    inlineIndicator={<Button icon className="text-fields__inline-btn">keyboard_arrow_down</Button>}
                    placeholder={this.props.placeholder}
                    error={meta.touched && !!meta.error} errorText={meta.error}
                />
            </DropdownMenu>
            <div className={this.props.className + " chips__list"}>
                {this.state.chips.map(function (i, ii){
                    if(_this.props.valueField == "parent"){ i = i[_this.props.viewField] }
                    return <Chip key={ii} label={i} removable style={{cursor: 'pointer', marginRight: 10, marginTop: 10}} onClick={_this.handleChipClick.bind(_this, ii)} />
                })}
            </div>
        </div>
    )
  }
}

