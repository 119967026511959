/**
 * Created by dwiargo on 11/30/17.
 */

import * as types from './actionTypes';

export const setData = (data:Array) => {
  return {
    type:types.IMAGE_SET_DATA,
    data
  }
};

export const setColumns = (columns:Array) => {
  return {
    type:types.IMAGE_SET_COLUMNS,
    columns
  }
};

export const setProperties = (properties:object) => {
  return {
    type:types.IMAGE_SET_PROPERTIES,
    properties
  }
};

export const updateParams = (params:object) => {
  return {
    type:types.IMAGE_UPDATE_PARAMS,
    params
  }
};

export const updateColumn = (column:object, update:object) => {
  return {
    type:types.IMAGE_UPDATE_COLUMN,
    column, update
  }
};


export const addDataItem = (item:Object) => {
  return {
    type:types.IMAGE_ADD_DATA_ITEM,
    item
  }
};

export const deleteDataItem = (item:Object) => {
  return {
    type:types.IMAGE_DELETE_DATA_ITEM,
    item
  }
};

export const deleteDataItems = () => {
  return {
    type:types.IMAGE_DELETE_DATA_ITEMS
  }
};

export const deleteSelectedDataItems = () => {
  return {
    type:types.IMAGE_DELETE_SELECTED_DATA_ITEMS
  }
};

export const updateDataItem = (index:Number, update:Object) => {
  return {
    type:types.IMAGE_UPDATE_DATA_ITEM,
    index, update
  }
};

export const selectDataItem = (index:Number, isSelect:Boolean) => {
  return {
    type:types.IMAGE_SELECT_DATA_ITEM,
    index, isSelect
  }
};

export const deselectDataItem = () => {
  return {
    type:types.IMAGE_DESELECT_DATA_ITEM
  }
}

export const resetAll = () => {
  return {
    type:types.IMAGE_RESET_ALL
  }
};

export const reset = (keys:Array) => {
  return {
    type:types.IMAGE_RESET,
    keys
  }
};

export const resetParams = () => {
  return {
    type:types.IMAGE_RESET_PARAMS
  }
};
export const toggleSelectDataItem = (dataItem) => {
  return {
    type:types.IMAGE_TOGGLE_SELECT_DATA_ITEM,
    dataItem
  }
};