import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  Card,
  CardActions,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox, Radio, Datetimepicker} from '../../../components/form/index';
import PelimpahanService from './Pelimpahan.service';
import utilsService from '../../../services/utils.service';
import counterpart from 'counterpart';
import PeriodePelimpahanService from '../PeriodePelimpahan/PeriodePelimpahan.service';
import { PembayaranAllView } from '../PembayaranAll/PembayaranAll.view';
import PembayaranAllService from '../PembayaranAll/PembayaranAll.service';
import moment from 'moment';
import PelimpahanPaymentService from './PelimpahanPayment.service';

@connect(PembayaranAllService.stateConnectSetting(), PembayaranAllService.actionConnectSetting())
class PembayaranPicker extends PembayaranAllView {
  viewType=2;

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.kodeBilling`,       value: 'paymentCode'},
    {isSortable: false,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tipeBilling`,       value: "billingType"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.namaPembayaran`,    value: "customerName"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.jmlSetoran`,        value: "amount"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.ntl`,               value: "ntb"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.ntpn`,              value: "ntpn"},
    {isSortable: false,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tglBayar`,          value: "localDateTime", type: 'date'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tglBuku`,           value: "settlementDate"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.status`,            value: "status"},
  ]

  fetchOption() {
    return {
      path: `pelimpahan/${this.props.pelimpahanId}/un_list_mpn_transaction`
    }
  }

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}

@connect((state) => ({
  ...PelimpahanPaymentService.stateConnectSetting()(state),
  auth        : state.auth.user
}), PelimpahanPaymentService.actionConnectSetting())
class PelimpahanPaymentView extends PembayaranAllView {
  viewType=2;
  service=PelimpahanPaymentService

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showDialogConfirmDeleteFinishFailedSelected:false,
      showForm:false,
      formData: {},
      data: [],
      permissionMap: {}
    }
  }

  componentDidMount() {
    this.firstCall = false;

    var pList = []
    if(this.props.auth && this.props.auth.servicePermission) pList = this.props.auth.servicePermission
    var pMap = pList.reduce(function(pv, cv) {
      pv[cv.method+"__"+cv.clientPath] = cv
      return pv
    }, {})

    this.setState({permissionMap: pMap})
  }


  beforeFetch(params) {
    params.id = this.props.pelimpahanId
  }

  _barActions = () => {

    var arr = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        // window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    }
    ]

    if(this.state.permissionMap['PUT__/pelimpahan/{id}/add_mpn_transaction']) {
      arr.push({
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      })
    }

    if(this.state.permissionMap['PUT__/pelimpahan/{id}/remove_mpn_transaction']) {
      arr.push({
        label:'word.delete',
        iconClassName:'mdi mdi-delete',
        onClick:() => {

          // window.location.hash = window.location.hash.split('?')[0]
          this.deletePembayaran()
        },
        disabledFunc:() => this.props.table.selected.length === 0
      })
    }

    return arr
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }

  deletePembayaran = async ()=> {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Hapus Pembayaran',
      initialValue: {},

      text:  'Apakah anda yakin akan menghapus pelimpahan terpilih?'
    }))

    if(p) {
      await PelimpahanService.api.deleteAllPayment({
        id           : this.props.pelimpahanId,
        mpnPaymentIds: this.props.table.selected.map(x => x.id)
      })

      this.fetchData()
    }
  }
}

@reduxForm({form: 'PelimpahanForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  ...PembayaranAllService.stateConnectSetting()(state),
  auth        : state.auth.user,
  formData    : getFormValues('PelimpahanForm')(state)
}))
export default class PelimpahanForm extends FormView {
  service = PelimpahanService
  viewType=2

  titleHeader() {
    return `entities.${this.service.name}.formTitle`;
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isRincian: false,
      pembayaranList: [],
      permissionMap: {}
    }
  }


  initialData={
    status: 'PREPARED',
    isPeriodic: true,
    mpnTransactionIds: [],
    currency: 'IDR'
    // submission: {}
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

        {this.state.permissionMap['PUT__/pelimpahan/{id}'] && <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>}
      </div>
    )
  }

  async beforeSave(val) {
    // val.bookDate = moment(val.bookDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    val.mpnTransactionIds = this.state.pembayaranList.map(x => x.id)
    if(val.dilimpahkan) {
      val.submissionDateTime = val.submissionDateTime.replace(" ", "T")
    }

    if(val.endTransactionDate) val.endTransactionDate = val.endTransactionDate.replace(" ", "T")
    if(val.startTransactionDate) val.startTransactionDate = val.startTransactionDate.replace(" ", "T")
    return val
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      if(res.data.isPeriodic) {
        res.data.periodePelimpahanId = res.data.periodePelimpahan.id
        let pembayaranRes = await this.service.api.getAllPayment(this.props.match.params.id)
        // console.log(pembayaranRes, 'asdas')
        this.setState({pembayaranList: pembayaranRes.data})
      }

      if(res.data.startTransactionDate) res.data.startTransactionDate = res.data.startTransactionDate.replace("T", " ")
      if(res.data.endTransactionDate) res.data.endTransactionDate = res.data.endTransactionDate.replace("T", " ")
      if(res.data.submissionDateTime) {
        res.data.submissionDateTime = res.data.submissionDateTime.replace("T", " ")
        res.data.dilimpahkan = true
      }

      this.props.initialize(res.data);
    }

    var pList = []
    if(this.props.auth && this.props.auth.servicePermission) pList = this.props.auth.servicePermission
    var pMap = pList.reduce(function(pv, cv) {
      pv[cv.method+"__"+cv.clientPath] = cv
      return pv
    }, {})

    this.setState({permissionMap: pMap})
  }

  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        if(value.id) {
          if(value.isPeriodic) {
            if(value.dilimpahkan) {
              res = await this.service.api.updateSubmission(value.id, value)
            } else {
              res = await this.service.api.update(value)
            }
          } else {
            if(value.dilimpahkan) {
              res = await this.service.api.updateSubmission(value.id, value)
            } else {
              res = await this.service.api.updateInsidental(value)
            }

          }
        } else {
          if(value.isPeriodic) {
            res = await this.service.api.save(value)
          } else {
            res = await this.service.api.createInsidental(value)
          }

        }
        this.setState({onProgress: false})
        this.afterSave(res, value)

        var pathArray = this.props.location.pathname.split('/');
        var path = '';
        pathArray.forEach((d, i)=> {
          if(pathArray.length-1 != i) {
            path = path+d;
            if(i != pathArray.length-2) path = path+'/'
          }
        })

        this.props.history.push(path)
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }

  formView() {
    return <div>
      <Card>
        <div className='md-grid'>
          <Field
            label         = {'Status Pelimpahan'}
            name          = 'status'
            className     = "md-cell md-cell--12"
            component     = {Textfield}
            disabled
          />
        </div>
      </Card>
      <br/>
      <Card>
        <div className='md-grid'>
          <Field
            label         = {counterpart(`entities.${this.service.name}.tglBuku`)}
            name          = 'bookDate'
            className     = "md-cell md-cell--12"
            // format = "YYYY-MM-DD"
            component     = {Datepicker}
          />

            <Field
              label         = {"Jenis Pelimpahan"}
              name          = 'isPeriodic'
              className     = "md-cell md-cell--12"
              component     = {Searchfield}
              options       = {[{id: true, name: "Rutin/Periodik"}, {id: false, name: "Insidental"}]}
            />

          {/* <div className='md-grid md-cell md-cell--12' >
            <div className="md-cell md-cell--12" style={{marginLeft: 0}}><p style={{color: "rgba(0, 0, 0, 0.54)"}}>Pelimpahan Rutin</p></div>

            <Field
              label         = {"Rutin/Periodik"}
              name          = 'kodeBilling'
              className     ="md-cell md-cell--6"
              component     = {Checkbox}
            />
            <Field
              label         = {"Insidental"}
              name          = 'kodeBilling'
              className     ="md-cell md-cell--6"
              component     = {Checkbox}
            />
          </div> */}
          {this.props.formData.isPeriodic && <Field
            label         = {counterpart(`entities.${this.service.name}.periodePelimpahan`)}
            name          = 'periodePelimpahanId'
            className     = "md-cell md-cell--12"
            component     = {Searchfield}
            service       = {PeriodePelimpahanService}
            onChange      = {async (e, d) => {
              var res = await PeriodePelimpahanService.api.getPeriode(d, moment(this.props.formData.bookDate, "YYYY-MM-DD").toDate().getTime())
              if(res.data) {
                // console.log('tests')
                this.props.change('startTransactionDate',res.data.transactionTime.startDateTime.replace("T", " "))
                this.props.change('endTransactionDate', res.data.transactionTime.endDateTime.replace("T", " "))
              }
              console.log(res)
            }}
          />}
          {this.props.formData.isPeriodic && <Field
            label         = "Waktu Awal"
            name          = 'startTransactionDate'
            className      ="md-cell md-cell--6"
            component     = {Datetimepicker}
            disabled
          />}
          {this.props.formData.isPeriodic && <Field
            label         = "Waktu Akhir"
            name          = 'endTransactionDate'
            className      ="md-cell md-cell--6"
            component     = {Datetimepicker}
            disabled
          />}

          {/* <Field
            label         = {"Data Transaksi Penerimaan"}
            name          = 'kodeBilling'
            className     = "md-cell md-cell--12"
            component     = {Searchfield}
            options       = {[{id: "1", name: "Periode 15-8"}]}
          /> */}

          {this.props.formData.isPeriodic && <Field
            label         = "Pilih Otomatis"
            name          = 'isPeriodic'
            className      ="md-cell md-cell--12"
            component     = {Checkbox}
          />}
          {/* <div className="md-cell md-cell--12">
          <Button primary raised disabled={!this.props.formData.isPeriodic} onClick={this._tambahRefund}>Pilih Otomatis</Button>
          </div> */}
        </div>
      </Card>
      <br/>
      {this.mpnPaymentView()}
    </div>
  }

  mpnPaymentView() {
    return <div>

      {/* <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Data Refund" /> */}
      {/* <Divider/> */}

      {(this.props.match.params.id != 'new' && this.props.formData.isPeriodic) &&
        <Card>
        <PelimpahanPaymentView pelimpahanId={this.props.match.params.id} addItem={(fetchData)=> this._tambahPayment(fetchData)} />
          <div className='md-grid'>
            <Field
              label         = {'Total Nilai Pelimpahan'}
              name          = 'amount'
              className     = "md-cell md-cell--8"
              component     = {TextfieldMask}
              money         = ","
              disabled      = {this.props.formData.isPeriodic}
            />
            <Field
              label         = {'Mata Uang'}
              name          = 'currency'
              className     = "md-cell md-cell--4"
              component     = {Textfield}
              disabled
            />
          </div>
        </Card>
      }

      {(!this.props.formData.isPeriodic) &&
      <Card>
      <div className='md-grid'>
        <Field
          label         = {'Total Nilai Pelimpahan'}
          name          = 'amount'
          className     = "md-cell md-cell--8"
          component     = {TextfieldMask}
          money = ','
        />
        <Field
          label         = {'Mata Uang'}
          name          = 'currency'
          className     = "md-cell md-cell--4"
          component     = {Textfield}
          disabled
        />
      </div>
    </Card>
    }
    <br/>

    {(this.props.formData.id) && <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Hasil Pelimpahan" />
      <Divider/>
      <div className='md-grid'>
        <Field
          label         = {'Telah dilimpahkan'}
          name          = 'dilimpahkan'
          className     = "md-cell md-cell--12"
          component     = {Checkbox}
        />
        <Field
          label         = {'Tanggal & Jam Pelimpahan'}
          name          = 'submissionDateTime'
          className     = "md-cell md-cell--12"
          component     = {Datetimepicker}
          disabled ={!this.props.formData.dilimpahkan}
        />
        <Field
          label         = {'TRN'}
          name          = 'trn'
          className     = "md-cell md-cell--12"
          component     = {Textfield}
          disabled ={!this.props.formData.dilimpahkan}
        />
        <Field
          label         = {'Nomor Sakti'}
          name          = 'nomorSakti'
          className     = "md-cell md-cell--12"
          component     = {Textfield}
          disabled ={!this.props.formData.dilimpahkan}
        />
        <Field
          label         = {'Description'}
          name          = 'description'
          className     = "md-cell md-cell--12"
          component     = {Textfield}
          disabled ={!this.props.formData.dilimpahkan}
        />
        {/* <Button raised primary onClick={this._tambahRefund}>Import Pelimpahan</Button> */}
      </div>
    </Card>}

    </div>
  }

  _tambahPayment = async (fetchData) => {
    var p = await this.context.showDialog((props, res, rej) =>({
      // title: 'Tambah Pembayaran',
      initialValue: {},
      height: 'auto',
      width: 800,
      contentStyle: {overflow: 'inherit', padding: 0},
      okText: 'Tambah',
      text: <PembayaranPicker pelimpahanId={this.props.formData.id} />

    }))

    if(p) {
      // this.setState({pembayaranList: this.props.table.selected})
      await this.service.api.addAllPayment({
        id: this.props.formData.id,
        mpnPaymentIds: this.props.table.selected.map(x => x.id)
      })

      fetchData()
    }


  }

}
