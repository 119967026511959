import React from 'react';
import LoaderInfo from '../components/LoaderInfo';
import { connect } from 'react-redux';

export default (WrappedComponent) => {
    return connect( state => ({
      auth:state.auth
    }))((props) => {
      return props.auth.isLoggedIn ? <WrappedComponent user={props.auth.user} {...props} /> : (
        <LoaderInfo text="waiting user information..."/>
      );
    })
}