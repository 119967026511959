import ApiService from '../../../services/api.service';

class PembayaranService extends ApiService {
  name= 'pembayaran';
  path= 'pembayaran';

  apiMockup= {
    find: [{
      kodeBilling: '123124124124',
      tipeBilling: 'DJP',
      namaPembayaran: 'Entah Sifulan',
      jmlSetoran: '220000',
      ntl: "123213123",
      ntpn: "123123123",
      tglBayar: '01/01/2019',
      tglBuku: '01/01/2019',
      status: 'PAID'
    }]
  }
}

export default new PembayaranService().init()
