import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import PeriodePelimpahanService from './PeriodePelimpahan.service';
import ListView from '../../../components/entity/listView';

@connect((state) => ({
  ...PeriodePelimpahanService.stateConnectSetting()(state),
  auth        : state.auth.user
}), PeriodePelimpahanService.actionConnectSetting())
export default class PeriodePelimpahanView extends ListView {
  editDialog  = false;
  addDialog   = false;
  service     = PeriodePelimpahanService
  stream      = true;

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.kode`,              value: "code"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.nama`,              value: "name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.hariAwal`,          value: "startDay"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.jamAwal`,           value: "startTime"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.hariAkhir`,         value: "endDay"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.jamAkhir`,          value: "endTime"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.batasPelimpahan`,   value: "dueTime"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.deskripsi`,         value: "description"}
  ]

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showDialogConfirmDeleteFinishFailedSelected:false,
      showForm:false,
      formData: {},
      data: [],
      permissionMap: {}
    }
  }

  componentDidMount() {
    this.firstCall = false;

    var pList = []
    if(this.props.auth && this.props.auth.servicePermission) pList = this.props.auth.servicePermission
    var pMap = pList.reduce(function(pv, cv) {
      pv[cv.method+"__"+cv.clientPath] = cv
      return pv
    }, {})

    this.setState({permissionMap: pMap})
  }

  _barActions = () => {
    var arr = [

      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => {

          window.location.hash = window.location.hash.split('?')[0]
          this.fetchData()
        }
      }
    ]

    if(this.state.permissionMap['POST__/periode_pelimpahan']) {
      arr.push({
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      })
    }

    return arr
  }


}
