import React from 'react';
import './NumberInWords.scss';
import numberService from '../../services/numberService';

const NumberInWords = ({number}) => {
  return(
    <div className="mpk-number-in-words">
      {numberService.terbilang(number)}
    </div>
  )
}

export default NumberInWords;