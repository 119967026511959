import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import HariLiburService from './HariLiburService';
import ListView from '../../../components/entity/listView';
import HariLiburForm from './HariLiburForm';

@connect((state) => ({
  ...HariLiburService.stateConnectSetting()(state),
  auth        : state.auth.user
}), HariLiburService.actionConnectSetting())
export default class HariLiburView extends ListView {
  editDialog  = true;
  addDialog   = true;
  service     = HariLiburService
  stream      = true;
  FormDialog  = HariLiburForm
  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.name`,           value: "name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.holidayDate`,       value: "holidayDate", style: {width:400} },
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.nextWorkingDayDate`,          value: "nextWorkingDayDate",},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.holidayType`,          value: "holidayType"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.description`,          value: "description"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.status`,          value: "status", type: 'boolean'},
    // {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.keterangan`,           value: "deskripsi"}
  ]

  _barActions = () => {
      var arr = [
      {
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      },
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => {

          window.location.hash = window.location.hash.split('?')[0]
          this.fetchData()
        }
      }
    ]
    return arr
  }

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showDialogConfirmDeleteFinishFailedSelected:false,
      showForm:false,
      formData: {},
      data: [],
      permissionMap: {}
    }
  }

  componentDidMount() {
    this.firstCall = false;

    var pList = []
    if(this.props.auth && this.props.auth.servicePermission) pList = this.props.auth.servicePermission
    var pMap = pList.reduce(function(pv, cv) {
      pv[cv.method+"__"+cv.clientPath] = cv
      return pv
    }, {})

    this.setState({permissionMap: pMap})
  }

  _barActions = () => {
    var arr = [

      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => {

          window.location.hash = window.location.hash.split('?')[0]
          this.fetchData()
        }
      }
    ]

    if(this.state.permissionMap['POST__/mpn_holiday']) {
      arr.push({
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      })
    }

    return arr
  }
}
