import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import {
  Button,
  Card,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from "react-md";
import FormView from "../../../components/entity/form.view";
import {
  Textfield,
  Searchfield,
  TextfieldMask,
  Switch,
  Datepicker,
  Checkbox,
  Radio,
  Datetimepicker,
  DatetimepickerEdit
} from "../../../components/form/index";
import counterpart from "counterpart";
import PembayaranAllService from "./PembayaranAll.service";
import moment from "moment";
import iziToast from "izitoast";
import { LoaderInfo } from "../../../../src/libs/react-mpk";

@reduxForm({ form: "CreatePayment", destroyOnUnmount: true, initialValues: {} })
@connect(state => ({
  ...PembayaranAllService.stateConnectSetting()(state),
  auth: state.auth.user,
  formData: getFormValues("CreatePayment")(state)
}))
export default class PembayaranAllCreate extends FormView {
  viewType = 2;
  titleHeader() {
    return "entities.payment-all.formTitleCreate";
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isRincian: false,
      pembayaranList: [],
      permissionMap: {},
      searchType: "code",
      paymentData: null,
      fetchLoading: false,
      totalAmount: 0
    };
  }

  _barItem() {
    return (
      <div>
        <Button
          secondary
          flat
          onClick={() => {
            this.props.history.goBack();
          }}
          style={{ marginRight: 16 }}
        >
          {counterpart.translate("word.back")}
        </Button>
      </div>
    );
  }

  searchPayment = async data => {
    try {
      this.setState({ fetchLoading: true, paymentData: null });
      const res =
        this.state.searchType === "code"
          ? await PembayaranAllService.api.getByCode(data)
          : await PembayaranAllService.api.getByOrderNo(data);

      const sum = res.data.reduce((accumulator, object) => {
        return accumulator + object.amount;
      }, 0);

      this.setState({
        fetchLoading: false,
        paymentData: res.data,
        totalAmount: parseFloat(sum)
      });
    } catch (error) {
      this.setState({
        fetchLoading: false
      });
    }
  };

  rupiahFormat = number => {
    const parseNum = parseFloat(number);
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR"
    }).format(parseNum);
  };

  submitPayment = async (orderNo, bookDate, transactionDate) => {
    try {
      await PembayaranAllService.api.submitManualPayment(
        orderNo,
        bookDate,
        transactionDate
      );

      iziToast.success({
        title: "Berhasil",
        message: "Berhasil Melakukan Pembayaran Manual"
      });

      this.props.history.push("/payment-all");
    } catch (error) {
      iziToast.error({ title: "Gagal", message: error });
    }
  };

  sumAmount = data => {
    if (this.state.paymentData)
      data.reduce((accumulator, object) => {
        return accumulator + object.amount;
      }, 0);
  };

  formView() {
    return (
      <div>
        <Card className="md-grid">
          <div className="md-cell md-cell--12 md-grid">
            <Button
              primary
              flat
              raised={this.state.searchType === "code" ? true : false}
              onClick={() => this.setState({ searchType: "code" })}
              className="md-cell md-cell--3 md-grid"
            >
              Kode Billing
            </Button>
            <Button
              primary
              flat
              raised={this.state.searchType === "orderNo" ? true : false}
              onClick={() => this.setState({ searchType: "orderNo" })}
              className="md-cell md-cell--3 md-grid"
            >
              No. Pembayaran
            </Button>
          </div>
        </Card>
        <Card>
          <div className="md-grid">
            <Field
              label={
                this.state.searchType === "code"
                  ? "Kode Billing"
                  : "No. Pembayaran"
              }
              name="parameter"
              className="md-cell md-cell--12"
              component={Textfield}
            />
            <Button
              primary
              flat
              swapTheming
              className="md-cell md-cell--2"
              onClick={() => this.searchPayment(this.props.formData.parameter)}
            >
              Cari
            </Button>
          </div>
        </Card>
        <br />
        {this.state.fetchLoading && <LoaderInfo statusText="Loading..." />}
        {this.state.paymentData && (
          <Card>
            <DataTable plain>
              <TableHeader>
                <TableRow>
                  <TableColumn className=" md-text-left">
                    Kode Billing
                  </TableColumn>
                  <TableColumn className=" md-text-left">
                    Nama Pelanggan
                  </TableColumn>
                  <TableColumn className=" md-text-left">Pesan</TableColumn>
                  <TableColumn className=" md-text-left">Status</TableColumn>
                  <TableColumn className=" md-text-left">
                    JML Setoran
                  </TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody style={{ borderBottom: "2px solid black" }}>
                {this.state.paymentData.map(item => (
                  <TableRow key={item.paymentCode}>
                    <TableColumn>{item.paymentCode}</TableColumn>
                    <TableColumn>{item.customerName}</TableColumn>
                    <TableColumn>{item.response.messageId}</TableColumn>
                    <TableColumn>{item.status}</TableColumn>
                    <TableColumn>
                      {`Rp ${parseFloat(item.amount).toLocaleString()}`}
                    </TableColumn>
                  </TableRow>
                ))}
              </TableBody>
            </DataTable>
            <div
              style={{
                width: "100%",
                alignSelf: "flex-end",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                fontSize: "16px",
                paddingRight: "1rem",
                marginTop: "0.5rem"
              }}
            >
              {`Total : Rp ${this.state.totalAmount.toLocaleString()}`}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
              className="md-grid"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%"
                }}
                className="md-grid"
              >
                <Field
                  label={counterpart(`entities.payment-all.tglBuku`)}
                  name="bookDate"
                  className="md-cell md-cell--6"
                  component={Datepicker}
                />
                <Field
                  label={counterpart(`entities.payment-all.tglBayar`)}
                  name="transactionDate"
                  className="md-cell md-cell--6"
                  component={DatetimepickerEdit}
                />
              </div>
              <Button
                style={{ alignSelf: "center" }}
                secondary
                flat
                swapTheming
                className="md-cell md-cell--6"
                disabled={!this.state.paymentData}
                onClick={() => {
                  if (this.state.paymentData)
                    if (
                      !this.props.formData.bookDate ||
                      !this.props.formData.transactionDate
                    ) {
                      iziToast.info({
                        message: "Harap Lengkapi Data!"
                      });
                    } else {
                      this.submitPayment(
                        this.state.paymentData[0].orderNo,
                        this.props.formData.bookDate,
                        this.props.formData.transactionDate
                      );
                    }
                }}
              >
                Submit
              </Button>
            </div>
          </Card>
        )}
        <br />
      </div>
    );
  }
}
