import React, { Component } from "react";
import { connect } from "react-redux";

// import FormDialog from './ItemType.dialog.view';
import MutasiService from "./Mutasi.service";
import MutasiForm from "./Mutasi.form";
import ListView from "../../../components/entity/listView";
import { Divider, Card, TextField } from "react-md";
import {
  Commandbar,
  Table,
  TableSetting,
  Empty,
  DialogConfirm,
  httpService
} from "../../../libs/react-mpk";
import iziToast from "izitoast";
import moment from "moment";
import utilsService from "../../../services/utils.service";
import qs from "qs";
import hosts from "../../../services/hosts";
import http from "../../../services/http.service";
import { Field, reduxForm, getFormValues, change } from "redux-form";
import {
  validation,
  Textfield,
  Searchfield,
  TextfieldMask,
  Switch,
  Datepicker,
  Checkbox,
  Fileinput,
  Progress,
  Datetimepicker
} from "../../../components/form/index";
import download from "downloadjs";

@connect(
  state => ({
    ...MutasiService.stateConnectSetting()(state),
    searchForm: getFormValues("mutasi_search")(state)
  }),
  MutasiService.actionConnectSetting()
)
export default class MutasiView extends ListView {
  selectableRows = false;
  editDialog = true;
  addDialog = true;
  service = MutasiService;
  stream = true;

  FormDialog = MutasiForm;

  constructor(props) {
    super(props);
    this.state = {
      showTableSetting: true,
      showDialogConfirmDeleteSelected: false,
      showDialogConfirmDeleteFinishFailedSelected: false,
      showForm: false,
      formData: {},
      data: [],
      showSummary: false,
      summary: {}
    };
  }

  columns = [
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: `entities.${this.service.name}.tanggal`,
      value: "transactionDate",
      type: "date"
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: `entities.${this.service.name}.kodeBilling`,
      value: "MPNPayment.paymentCode"
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: `entities.${this.service.name}.nomorSakti`,
      value: "pelimpahan.nomorSakti"
    },
    {
      isSortable: false,
      show: true,
      isSearchable: true,
      label: `entities.${this.service.name}.debet`,
      value: d => {
        if (d.MPNTransactionType.debitCredit == "D") {
          return (
            <div style={{ textAlign: "right" }}>
              {utilsService.number.format2(d.amount)}
            </div>
          );
        } else {
          return <div style={{ textAlign: "right" }}>-</div>;
        }
      },
      type: "func"
    },
    {
      isSortable: false,
      show: true,
      isSearchable: true,
      label: `entities.${this.service.name}.kredit`,
      value: d => {
        if (d.MPNTransactionType.debitCredit == "C") {
          return (
            <div style={{ textAlign: "right" }}>
              {utilsService.number.format2(d.amount)}
            </div>
          );
        } else {
          return <div style={{ textAlign: "right" }}>-</div>;
        }
      },
      type: "func"
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: `entities.${this.service.name}.saldo`,
      value: "balance",
      type: "number"
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: `entities.${this.service.name}.tipe`,
      value: "MPNTransactionType.name"
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: `entities.${this.service.name}.keterangan`,
      value: "description"
    }
  ];

  _barActions = () => {
    var arr = [
      {
        label: "word.download",
        iconClassName: "mdi mdi-download",
        onClick: () => this._download()
      },
      {
        label: "word.refresh",
        iconClassName: "mdi mdi-refresh",
        onClick: () => {
          window.location.hash = window.location.hash.split("?")[0];
          this.fetchData();
        }
      }
    ];
    return arr;
  };

  footer() {
    if (!this.state.showSummary)
      return <div class="asd" style={{ height: 91 }} />;
    return (
      <div className="md-grid" style={{ width: "100%" }}>
        {/* <div className="md-cell md-cell--6"></div> */}
        <TextField
          // id="floating-center-title"
          label="Saldo Awal"
          lineDirection="center"
          placeholder="Saldo Awal"
          className="md-cell md-cell--3 md-text-right"
          inputClassName="md-text-right"
          value={utilsService.number.format2(this.state.summary.openingBalance)}
        />
        {/* <div className="md-cell md-cell--6"></div> */}
        <TextField
          // id="floating-center-title"
          label="Mutasi Debet"
          lineDirection="center"
          placeholder="Mutasi Debet"
          className="md-cell md-cell--3 md-text-right"
          inputClassName="md-text-right"
          value={utilsService.number.format2(this.state.summary.debit)}
        />
        {/* <div className="md-cell md-cell--6"></div> */}
        <TextField
          // id="floating-center-title"
          label="Mutasi Kredit"
          lineDirection="center"
          placeholder="Mutasi Kredit"
          className="md-cell md-cell--3 md-text-right"
          inputClassName="md-text-right"
          value={utilsService.number.format2(this.state.summary.credit)}
        />
        {/* <div className="md-cell md-cell--6"></div> */}
        <TextField
          // id="floating-center-title"
          label="Saldo Akhir"
          lineDirection="center"
          placeholder="Saldo Akhir"
          className="md-cell md-cell--3 md-text-right"
          inputClassName="md-text-right"
          value={utilsService.number.format2(this.state.summary.closeBalance)}
        />
      </div>
    );
  }

  searchForm() {
    return (
      <this.SearchContainer>
        {/* <this.ig.Field
          label='Status'
          name='status'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options       = {[
            {id: 'Paid', name: 'Paid'},
            {id: 'Pending Paid', name: 'Pending Paid'},
            {id: 'Failed Paid', name: 'Failed Paid'},
            {id: 'Waiting Refund', name: 'Waiting Refund'}
          ]}
        /> */}
        <this.ig.Field
          label="Tanggal Awal"
          name="startDate"
          className="md-cell md-cell--12"
          component={this.ig.Datepicker}
        />
        <this.ig.Field
          label="Tanggal Akhir"
          name="endDate"
          className="md-cell md-cell--12"
          component={this.ig.Datepicker}
        />

        <div style={{ height: 110 }} />
        <Divider style={{ width: "100%" }} />
        {this.defaultSearchForm()}
      </this.SearchContainer>
    );
  }

  fetchData = async (params = this.props.table.params, onSuccess, onError) => {
    params = _.cloneDeep(params);

    console.log(this.props, "asdasd");

    this.props.history.push({ path: "/mutasi", search: qs.stringify() });

    var search = this.getJsonFromUrl();
    var filterObj = this.props.filterObj;
    if (this.firstCall) filterObj = {};
    delete filterObj.size;
    delete filterObj.page;
    delete filterObj.sort;
    delete filterObj.sortBy;

    params = {
      ...params,
      ...search,
      ...filterObj
    };

    params.startDate = moment(params.startDate + " 00:00:00").valueOf();
    params.endDate = moment(params.endDate + " 23:59:59").valueOf();

    if (!params.startDate || !params.endDate) {
      this.setState({ data: [] });
      var result = {
        isLoading: true,
        data: [],
        params: {
          total: 0
        }
      };

      result.isLoading = false;
      this.props.tableActions.setProperties(result);

      iziToast.warning({
        title: "Warning",
        message: "Mohon isi Tanggal Awal dan Tanggal Akhir"
      });

      return {
        data: [],
        total: 0
      };
    }

    if (!onSuccess) {
      this.props.tableActions.setProperties({
        isLoading: true
      });
    }

    try {
      await this.beforeFetch(params);

      if (!this.stream) {
        var res = await this.service.api[this.apiPath](
          params,
          this.props.dispatch,
          this.fetchOption()
        );
        var data = res.data.data;
        var total = parseInt(res.data.total);
        if (onSuccess) onSuccess(data, total);

        if (!onSuccess) {
          this.props.tableActions.setProperties({
            isLoading: false,
            data,
            params: {
              total
            }
          });
        }
      } else {
        var res = await this.service.api.stream(
          params,
          this.props.dispatch,
          this.fetchOption()
        );

        console.log(res.header(), "asdasd");
        var result = {
          isLoading: true,
          data: [],
          params: {
            total: 0
          }
        };
        res
          .start((s, h) => {
            result.isLoading = false;
            result.params.total = parseInt(h["x-pagination-count"]);

            var summary = {
              openingBalance: h["x-mutation-opening"]
                ? parseInt(h["x-mutation-opening"])
                : null,
              debit: h["x-mutation-debit"]
                ? parseInt(h["x-mutation-debit"])
                : null,
              credit: h["x-mutation-credit"]
                ? parseInt(h["x-mutation-credit"])
                : null,
              closeBalance: h["x-mutation-close"]
                ? parseInt(h["x-mutation-close"])
                : null
            };

            // console.log(summary, 'asdasd', h)

            if (summary.openingBalance || summary.openingBalance == 0) {
              this.setState({ showSummary: true, summary });
            }
            this.props.tableActions.setProperties(result);
          })
          .node("!", (d, e) => {
            console.info("node", d);
            if (d.id) result.data.push(d);
            this.props.tableActions.setProperties(result);
          })
          .done(d => {
            if (result.data.length > 0) this.setState({ showSummary: true });
            // console.log('done')
          })
          .fail(async error => {
            if (error.statusCode == 401) {
              await httpService.get({ url: "/api/iams/me" }).then(res => {});

              // reload
              res = await this.service.api.stream(
                params,
                this.props.dispatch,
                this.fetchOption()
              );
              result = {
                isLoading: true,
                data: [],
                params: {
                  total: 0
                }
              };
              res
                .start((s, h) => {
                  result.isLoading = false;
                  result.params.total = parseInt(h["x-pagination-count"]);
                  this.props.tableActions.setProperties(result);
                })
                .node("!", (d, e) => {
                  // console.info('node', d)
                  if (d.id) result.data.push(d);
                  this.props.tableActions.setProperties(result);
                })
                .done(d => {
                  // console.log('done')
                });
            }
          });
      }

      if (this.service && this.service.tableService) {
        this.props.dispatch({
          type: this.service.tableService.tableType.TABLE_SELECT_DATA_ITEM,
          index: 0,
          isSelect: false
        });
      }

      this.setState({ data });

      return {
        data,
        total
      };
    } catch (e) {
      console.log(e);
      if (onError) onError(e);
      if (!onError) {
        this.props.tableActions.setProperties({
          isLoading: false,
          error: {
            isError: true,
            title: e.statusText,
            message: e.message
          }
        });
      }
      throw e;
    }
  };

  async _download(type) {
    var p = await this.context.showDialog((props, res, rej) => ({
      title: "Download Report",
      initialValue: { d: "asd" },
      height: "auto",
      width: "auto",
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan men-download data terpilih?"
    }));

    if (p) {
      var param = this.props.searchForm ? this.props.searchForm : {};
      if (!(param.startDate && param.endDate)) {
        iziToast.warning({
          title: "",
          message: "Tanggal Awal dan Tanggal Akhir harus di isi terlebih dahulu"
        });
        return false;
      }

      console.log(param);

      var res = await this.service.api.report(
        moment(param.startDate + " 00:00:00").valueOf(),
        moment(param.endDate + " 23:59:59").valueOf()
      );

      var token = http.defaults.headers.common["Authorization"].replace(
        "Bearer ",
        ""
      );
      var url = (
        hosts.getAll().apiGateway.host +
        hosts.getAll().apiGateway.baseUrl +
        `/websocket/report/mutation?access_token=${token}&startDate=1596214800000&endDate=1598893199000`
      )
        .replace("http", "ws")
        .replace("https", "wss");
      var socket = new WebSocket(url);
      var progress = this.context.showDialog((props, res, rej) => ({
        title: "Mohon ditunggu",
        initialValue: { progress: undefined },
        height: "auto",
        width: 400,
        okText: "Submit",
        disableOk: true,
        cancelText: "Close",
        // contentStyle: {overflow: 'inherit'},
        text: (
          <div className="md-grid">
            <Field
              label={"Nama File"}
              name="progress"
              className="md-cell md-cell--12"
              component={Progress}
            />
          </div>
        )
      }));

      var progressNum = undefined;
      var interval = setInterval(() => {
        this.props.dispatch(change("global_dialog", "progress", undefined));
      }, 1000);

      socket.onopen = async e => {
        iziToast.success({ title: "Info", message: "File Sedang di proses" });

        console.log(
          `[/websocket/report/mutation][open] Connection established`
        );
      };

      socket.onmessage = async event => {
        console.log(
          `[/websocket/report/mutation][message] Data received from server: ${event.data}`
        );
        // this.initData()
        if (event.data.search("{") >= 0) {
          var data = JSON.parse(event.data);
          if (data.count) {
            progressNum = parseInt(data.count);
            this.props.dispatch(
              change("global_dialog", "progress", progressNum)
            );
          }

          if (data.file) {
            this.props.dispatch(change("global_dialog", "progress", 100));
            var dInfo = await this.context.showDialog((props, res, rej) => ({
              title: "Berhasil",
              height: "auto",
              width: 400,
              okText: "Download",
              // disableOk: true,
              cancelText: "Close",
              // contentStyle: {overflow: 'inherit'},
              text: "File report telah terbentuk"
            }));

            if (dInfo) {
              let res = await this.service.api.download(data.file);
              let filename = data.file;
              download(res.data, filename);
            }
          }
        }
      };

      socket.onclose = async event => {
        if (event.wasClean) {
          console.log(
            `[/websocket/report/mutation][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
          );
        } else {
          console.log(`[/websocket/report/mutation][close] Connection died`);
        }
      };

      socket.onerror = error => {
        console.log(`[error] ${error.message}`);
      };
    }
  }
}
