import ApiService from '../../../services/api.service';

class HariLiburService extends ApiService {
  name= 'hari-libur';
  path= 'mpn_holiday';

  api = {
    getPeriode: async (id, bookDate)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/${bookDate}`)

      return res
    }
  }
}

export default new HariLiburService().init()
