import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import env from 'env';
//style
import '../node_modules/storm-react-diagrams/dist/style.min.css';
import '../node_modules/izitoast/dist/css/iziToast.css';
import './index.scss';

// configure
import configureStore from './redux/store/configure-store';
import {configureHttp} from './services/http.service';
import AppInfo from './AppInfo'

configureHttp();
const store = configureStore();

var el = function(){
  if(env.devMode) {
    return <Provider store={store}>
      <App />
  </Provider>
  } else {
    return <Provider store={store}>
    <AppInfo>
      <App />
    </AppInfo>
  </Provider>
  }
}()

ReactDOM.render(el, document.getElementById('root'));
