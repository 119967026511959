import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  Card,
  CardActions,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer,
  TextField
} from 'react-md';
import FormView from '../../../components/entity/dialog.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form/index';
import MutasiService from './Mutasi.service';
import utilsService from '../../../services/utils.service';
import counterpart from 'counterpart';

@reduxForm({form: 'MutasiForm', destroyOnUnmount: true})
@connect((state) => ({
  data    : getFormValues('MutasiForm')(state)
}))
export default class MutasiForm extends FormView {
  service = MutasiService
  // viewType=2

  cancelLabel='Tutup'
  submitLabel='Lihat Data Transaksi'
  // showSubmit=

  titleHeader() {
    return `entities.${this.service.name}.formTitle`;
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isRincian: false
    }
  }


  initialData={
  }



  // async initData() {
  // }

  formView() {
    var posisi = "Debet"
    if(this.props.formData.MPNTransactionType && this.props.formData.MPNTransactionType.debitCredit == "C") posisi = "Kredit"
    return <div>
      {/* <Card> */}
        <div className='md-grid'>
          <TextField
            // id="floating-center-title"
            label={counterpart(`entities.${this.service.name}.tanggal`)}
            lineDirection="center"
            placeholder={counterpart(`entities.${this.service.name}.tanggal`)}
            className="md-cell md-cell--12 md-text-right"
            // inputClassName="md-text-right"
            value={this.props.formData.transactionDate}
          />
          <TextField
            // id="floating-center-title"
            label="Kode Billing (Payment/Refund)"
            lineDirection="center"
            placeholder="Kode Billing (Payment/Refund)"
            className="md-cell md-cell--12"
            // inputClassName="md-text-right"
            value={this.props.formData.MPNPayment?this.props.formData.MPNPayment.paymentCode:""}

          />
           <TextField
            // id="floating-center-title"
            label="Nomor Sakti"
            lineDirection="center"
            placeholder="Nomor Sakti"
            className="md-cell md-cell--12"
            // inputClassName="md-text-right"
            value={this.props.formData.pelimpahan?this.props.formData.pelimpahan.nomorSakti:""}

          />
          <TextField
            // id="floating-center-title"
            label="Posisi"
            lineDirection="center"
            placeholder="Posisi"
            className="md-cell md-cell--12"
            // inputClassName="md-text-right"
            value={posisi}

          />
          <TextField
            // id="floating-center-title"
            label="Nilai Transaksi"
            lineDirection="center"
            placeholder="Nilai Transaksi"
            className="md-cell md-cell--12"
            // inputClassName="md-text-right"
            value={utilsService.number.format2(this.props.formData.amount)}

          />
           <TextField
            // id="floating-center-title"
            label="Saldo"
            lineDirection="center"
            placeholder="Saldo"
            className="md-cell md-cell--12"
            // inputClassName="md-text-right"
            value={utilsService.number.format2(this.props.formData.balance)}

          />
          <TextField
            // id="floating-center-title"
            label="Tipe Transaksi"
            lineDirection="center"
            placeholder="Tipe Transaksi"
            className="md-cell md-cell--12"
            // inputClassName="md-text-right"
            value={this.props.formData.MPNTransactionType?this.props.formData.MPNTransactionType.name:""}

          />
          <TextField
            // id="floating-center-title"
            label="Keterangan"
            lineDirection="center"
            placeholder="Keterangan"
            className="md-cell md-cell--12"
            // inputClassName="md-text-right"
            value={this.props.formData.description}

          />
        </div>
      {/* </Card> */}
    </div>
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

        <Button raised primary onClick={()=> {}}>Lihat Data Transaksi</Button>
      </div>
    )
  }

  _handleSubmit(callback) {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    if(this.props.formData.MPNPayment && this.props.formData.MPNPayment.id) this.props.history.push("/payment-all/"+this.props.formData.MPNPayment.id)
    if(this.props.formData.pelimpahan && this.props.formData.pelimpahan.id) this.props.history.push("/pelimpahan/"+this.props.formData.pelimpahan.id)
    if(this.props.formData.refund && this.props.formData.refund.id) this.props.history.push("/payment-all/"+this.props.formData.refund.paymentId)
  }

}
