import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues, change} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  Card,
  CardActions,
  LinearProgress,
  FontIcon,
  DialogContainer,
  TabsContainer,
  Tabs,
  Tab,
  List,
  ListItem
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox, Fileinput, Progress, Datetimepicker} from '../../../components/form/index';
import PelaporanService from './Pelaporan.service';
import utilsService from '../../../services/utils.service';
import counterpart from 'counterpart';
import { PelimpahanView } from '../Pelimpahan/Pelimpahan.view';
import LhpPelimpahanService from './LhpPelimpahan.service';
import DnpPelimpahanService from './DnpPelimpahan.service';
import PelimpahanService from '../Pelimpahan/Pelimpahan.service';
import { PembayaranAllView } from '../PembayaranAll/PembayaranAll.view';
import RekeningKoranView from './RekeningKoran.view';
import PelaporanWsService from './Pelaporan.ws.service';
import hosts from '../../../services/hosts';
import http from '../../../services/http.service';
import iziToast from 'izitoast';
import download from 'downloadjs'
import CaOnlyView, { SaOnlyView, FailedView } from './CaOnly.view';
import UtilService from '../../../services/utils.service';
import PembayaranAllService from '../PembayaranAll/PembayaranAll.service';
import PembayaranRefundView from '../PembayaranAll/PembayaranRefund.view'

@connect(PelimpahanService.stateConnectSetting(), PelimpahanService.actionConnectSetting())
class PickerPelimpahanView extends PelimpahanView {
  viewType=2;
  service=PelimpahanService
  translate=false

  fetchOption() {
    if(this.props.lhpId) {
      return {
        path: `lhp/${this.props.lhpId}/un_list_pelimpahan`
      }
    }

    if(this.props.dnpId) {
      return {
        path: `/dnp/${this.props.dnpId}/un_list_mpn_transaction`
      }
    }

    return {}

  }


  titleHeader() {
    return ""
  }


  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}

@connect(PembayaranAllService.stateConnectSetting(), PembayaranAllService.actionConnectSetting())
class PickerPembayaranView extends PembayaranAllView {
  viewType=2;
  service=PembayaranAllService
  translate=false

  fetchOption() {

    if(this.props.dnpId) {
      return {
        path: `dnp/${this.props.dnpId}/un_list_mpn_transaction`
      }
    }

    return {}

  }


  titleHeader() {
    return ""
  }


  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}

@connect(LhpPelimpahanService.stateConnectSetting(), LhpPelimpahanService.actionConnectSetting())
class LhpPelimpahanView extends PelimpahanView {
  viewType=2;
  service=LhpPelimpahanService
  translate=false

  componentDidMount() {
    this.firstCall = false;
    this.props.setParent('lhpPelimpahanFetch', this.fetchData)
  }


  titleHeader() {
    return ""
  }


  beforeFetch(params) {
    this.props.lhpPelimpahanFetch = this.fetchData
    params.id = this.props.lphId
  }

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => {

        this.props.addPelimpahan(this.fetchData)
      }
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {

        this.deletePelimpahan()
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }

  deletePelimpahan = async ()=> {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Hapus Pelimpahan',
      initialValue: {},

      text:  'Apakah anda yakin akan menghapus pelimpahan terpilih?'
    }))

    if(p) {
      await PelaporanService.api.deletePelimpahan({
        id           : this.props.lphId,
        pelimpahanIds: this.props.table.selected.map(x => x.id)
      })

      this.fetchData()
    }
  }
}

@connect(DnpPelimpahanService.stateConnectSetting(), DnpPelimpahanService.actionConnectSetting())
class DnpPelimpahanView extends PembayaranAllView {
  viewType=2;
  service=DnpPelimpahanService
  translate=false


  titleHeader() {
    return ""
  }


  beforeFetch(params) {
    params.id = this.props.dnpId
  }

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => {

        this.props.addPelimpahan(this.fetchData)
      }
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {

        this.deleteItem()
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }

  deleteItem = async ()=> {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Hapus Transaksi NPM',
      initialValue: {},

      text:  'Apakah anda yakin akan menghapus pelimpahan terpilih?'
    }))

    if(p) {
      await PelaporanService.api.dnpDeleteItem({
        id           : this.props.lhpId,
        mpnPaymentIds: this.props.table.selected.map(x => x.id)
      })

      this.fetchData()
    }
  }
}

@reduxForm({form: 'PelaporanForm', destroyOnUnmount: true, initialValues: {files: [], cashRecon: {}, dnp:{fileName: '', files: []}, rekeningKoran: {fileName: '', files: []}}, recon: {saOnly: {}, caOnly: {}, failed: {}}})
@connect((state) => ({
  ...PelimpahanService.stateConnectSetting()(state),
  auth        : state.auth.user,
  formData    : getFormValues('PelaporanForm')(state),
  tablePembayaranPicker: state.entity['payment-all'].table
}))
export default class PelaporanForm extends FormView {
  service = PelaporanService
  viewType=2

  caInit      = ()=> {}
  saInit      = ()=> {}
  failedInit  = ()=> {}

  lhpPelimpahanFetch = ()=> {}

  _setParent(d, func) {
    // console.log('asdasd')
    this[d] = func
  }

  titleHeader() {
    if(this.props.match.params.id == 'new') {
      return `entities.${this.service.name}.addTitle`;
    } else {
      return `entities.${this.service.name}.editTitle`;
    }
  }

  initialData={files: [], dnp: {fileName: '', files: []}, rekeningKoran: {fileName: '', files: []},
    recon: {saOnly: {}, caOnly: {}, failed: {}},
    cashRecon: {}
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isRincian: false,
      activeTabIndex: 0,
      permissionMap: {}
    }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.dnp = {}
      res.data.rekeningKoran = {}
      res.data.files = []
      try {
        let dnpQ = await this.service.api.getDnp(this.props.match.params.id)
        if(dnpQ.data) {
          res.data.dnp = dnpQ.data
          res.data.dnp.files = []

        }
      } catch(e) {}

      try {
        let dnpQ = await this.service.api.getRekeningKoran(this.props.match.params.id)
        if(dnpQ.data) {
          res.data.rekeningKoran = dnpQ.data
          res.data.rekeningKoran.files = []
        }
      } catch(e) {}

      res.data.recon = this.initialData.recon
      try {
        let dnpQ = await this.service.api.getRekonTrx(this.props.match.params.id)
        let rec = dnpQ.data.reduce((pv, cv) => {
          pv[cv.reconType] = cv
          return pv
        }, {})
        res.data.recon = {
          caOnly: rec['CA Only']?rec['CA Only']:{},
          saOnly: rec['SA Only']?rec['SA Only']:{},
          failed: rec['Failed']?rec['Failed']:{},
        }
        // console.log(dnpQ, 'dnpQ')
      } catch(e) {}

      res.data.cashRecon = {}
      try {
        let cashReconQ = await this.service.api.getCashRekon(this.props.match.params.id)
        if(cashReconQ.data) {
          res.data.cashRecon = cashReconQ.data
          res.data.cashRecon.id = "edit"
        }
      } catch(e) {}

      // handle fileName
      if(res.data.fileName) res.data.files = res.data.fileName.split(",")
      if(res.data.dnp.fileName) res.data.dnp.files = res.data.dnp.fileName.split(",")
      if(res.data.rekeningKoran.fileName) res.data.rekeningKoran.files = res.data.rekeningKoran.fileName.split(",")

      // console.log(res.data, 'asd')


      res.data.statusAll = 'Data Incomplete'
      try {
        let statusQ = await this.service.api.getStatus(this.props.match.params.id);
        var statusAll = statusQ.data.lhp+statusQ.data.dnp+statusQ.data.rekeningKoran

        console.log(statusAll, 'asd')

        if(statusAll.search("Data Verified") >= 0) res.data.statusAll = 'Data Verified'
        if(statusAll.search("Data Complete") >= 0) res.data.statusAll = 'Data Complete'
        if(statusAll.search("File created") >= 0) res.data.statusAll = 'File created'
        if(statusAll.search("File submitted") >= 0) res.data.statusAll = 'File submitted'
      } catch(e) {

      }



      this.props.initialize(res.data);
    }

    var pList = []
    if(this.props.auth && this.props.auth.servicePermission) pList = this.props.auth.servicePermission
    var pMap = pList.reduce(function(pv, cv) {
      pv[cv.method+"__"+cv.clientPath] = cv
      return pv
    }, {})

    this.setState({permissionMap: pMap})
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

        {this.state.permissionMap['POST__/lhp'] && <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>}
      </div>
    )
  }

  formView() {
    return <div>
      <Card>
        <div className='md-grid'>
          <Field
            label         = "Tanggal Buku"
            name          = 'bookDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />
          <Field
            label         = "Status"
            name          = 'statusAll'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
            disabled
          />
        </div>
        </Card>
        <br/>
          {(this.props.match.params.id != 'new') &&
            <Card className="md-grid"     >
              <div className= 'md-cell md-cell--12 md-grid'>
                <Button disabled={!(this.props.formData.statusAll == 'Data Complete' || this.props.formData.statusAll == 'Data Verified' || this.props.formData.statusAll == 'File created'|| this.props.formData.statusAll == 'File submitted')} primary raised onClick={() => this._verifikasiData()} className     = 'md-cell md-cell--4 md-grid'>Verifikasi Data</Button>
                <Button disabled={!(this.props.formData.statusAll == 'Data Verified' || this.props.formData.statusAll == 'File created'|| this.props.formData.statusAll == 'File submitted')} primary raised onClick={() => this._generateFileLapor()} className     = 'md-cell md-cell--4 md-grid'>Generate File Lapor</Button>
                <Button disabled={!(this.props.formData.statusAll == 'File created'|| this.props.formData.statusAll == 'File submitted')} primary raised onClick={this._kirimLaporan} className     = 'md-cell md-cell--4 md-grid'>Submit File Lapor</Button>
              </div>
            </Card>
          }


      <br/>
      {this.editView()}
    </div>
  }

  editView() {
    if(this.props.match.params.id != 'new') {
    return <div>
      <Card>
        <TabsContainer panelClassName="md-grid" colored activeTabIndex={this.state.activeTabIndex} onTabChange={(i) => this.setState({activeTabIndex: i})}>
          <Tabs tabId="simple-tab" mobile={true}>
            <Tab label="LHP"></Tab>
            <Tab label="DNP"></Tab>
            <Tab label="Rek Koran"></Tab>
            <Tab label="Rekon Transaksi"></Tab>
            <Tab label="Rekon Kas"></Tab>
          </Tabs>
        </TabsContainer>
      </Card>
      {this.switchView()}
      </div>
    }
  }

  switchView() {
    if(this.state.activeTabIndex == 0) {
      return this.lhpView()
    } else if(this.state.activeTabIndex == 1) {
      return this.dnpView()
    } else if(this.state.activeTabIndex == 2) {
      return this.rekKoranView()
    } else if(this.state.activeTabIndex == 3) {
      return this.rekonTrxView()
    } else {
      return this.rekonKasView()
    }
  }

  lhpView() {
    return <div style={{marginTop: -14}}>
      <Card>
        <div className='md-grid'>
          <Field
            label         = {"Status Lhp"}
            name          = 'status'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {"Kode Bank"}
            name          = 'bankCode'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {"Nama Bank"}
            name          = 'bankName'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {"Nomor Rekening"}
            name          = 'accountNumber'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {"Mata Uang"}
            name          = 'currency'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {"Jumlah Transaksi"}
            name          = 'transactionTotal'
            className     = 'md-cell md-cell--12'
            component     = {TextfieldMask}
            money = {","}
          />
          <Field
            label         = {"Jumlah Penerimaan"}
            name          = 'transactionAmount'
            className     = 'md-cell md-cell--12'
            component     = {TextfieldMask}
            money = {","}
          />
          {/* <Field
            label         = {"Nama File"}
            name          = 'fileName'
            className     = 'md-cell md-cell--8'
            component     = {Textfield}
          />
          <div className="md-cell md-cell--4" style={{paddingTop: 20}}>
          <Button primary raised onClick={this._lihatLhp}>View</Button>
          </div> */}
          <Field
            label         = {"Tanggal dan Jam Lapor"}
            name          = 'submissionDate'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            disabled
          />
        </div>
      </Card>

      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Daftar File" />
        <Divider/>
        <List>
          {this.props.formData.files.map((d, i) => <ListItem key={i} onClick={()=> {this._downloadLpl(d)}} primaryText={d} />)}
        </List>
      </Card>

      <br/>
      <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Data Transaksi" />
      <Divider/>
      {/* <DataTable plain>
        <TableBody>
          <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
            <TableColumn className=' md-text-right'>
              <Button primary flat onClick={this._pilihDataPelimpahan}>Pilih Data Pelimpahan</Button>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable> */}
      <LhpPelimpahanView lphId={this.props.match.params.id} addPelimpahan={(fetchData)=> this._tambahPelimpahanLhp(fetchData)} setParent={this._setParent} />
    </Card>

    <br/>
      <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Data Refund" />
      <Divider/>
      {/* <DataTable plain>
        <TableBody>
          <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
            <TableColumn className=' md-text-right'>
              <Button primary flat onClick={this._pilihDataPelimpahan}>Pilih Data Pelimpahan</Button>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable> */}
      <PembayaranRefundView lphId={this.props.match.params.id} addPelimpahan={(fetchData)=> this._tambahPelimpahanLhp(fetchData)} setParent={this._setParent} />
    </Card>

    {/* <br/>
      <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Data Pengembalian/Refund" />
      <Divider/>
      <DataTable plain>
        <TableBody>
          <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
            <TableColumn className=' md-text-right'>
              <Button primary flat onClick={this._pilihDataRefund}>Pilih Data Refund</Button>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable>
      <DataTable plain>
        <TableHeader>
          <TableRow>
            <TableColumn className=' md-text-left'>Kode Billing</TableColumn>
            <TableColumn className=' md-text-right'>Tipe Billing</TableColumn>
            <TableColumn className=' md-text-left'>Nama Pembayaran</TableColumn>
            <TableColumn className=' md-text-left'>Jml Setor</TableColumn>
            <TableColumn className=' md-text-left'>Tgl Bayar</TableColumn>
            <TableColumn className=' md-text-left'>Tgl Refund</TableColumn>
            <TableColumn className=' md-text-left'>Bank</TableColumn>
            <TableColumn className=' md-text-left'>Norek</TableColumn>
            <TableColumn className=' md-text-left'>Status</TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableColumn className=' md-text-left'>27/11/1990</TableColumn>
            <TableColumn className=' md-text-right'>21/11/2020</TableColumn>
            <TableColumn className=' md-text-left'>Rp. 1.000.000,-</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>BRI</TableColumn>
            <TableColumn className=' md-text-left'>123123123</TableColumn>
            <TableColumn className=' md-text-left'>Refunded</TableColumn>
            <TableColumn className=' md-text-left'>-</TableColumn>
            <TableColumn className='md-text-center'>
              <Button icon primary style={{float: 'left'}}>visibility</Button>
              <Button icon secondary style={{float: 'left'}}>delete</Button>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable>
    </Card> */}
    </div>
  }

  dnpView() {
    return <div style={{marginTop: -14}}>
      {!this.props.formData.dnp.id && <Card>
        <div className='md-grid md-text-center' style={{textAlign: 'center'}}>
          <div className='md-cell md-cell--4' /><Button className='md-cell md-cell--4' primary raised onClick={() => this._buatDnp()}>Buat DNP</Button>
        </div>
      </Card>}

      {this.props.formData.dnp.id &&
      <div>
      <Card>
      <div className='md-grid' >
        <Field
          label         = {"Status DNP"}
          name          = 'dnp.status'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
        />
        {/* <div className= 'md-cell md-cell--6 md-grid' style={{padding:0}}>
          <Field
            label         = {"Nama File"}
            name          = 'dnp.fileName'
            className     = 'md-cell md-cell--8'
            component     = {Textfield}
          />
          <div style = {{paddingTop: 20}}><Button primary raised onClick={this._lihatLhp}>View</Button></div>
        </div> */}

        <Field
          label         = {"Tanggal dan Jam Lapor"}
          name          = 'dnp.submissionDate'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          disabled
        />

          <div className='md-text-right md-cell md-cell--12'>
            <Button style={{ marginLeft: 10}} secondary raised onClick={() => this._deleteDnp("caOnly")}>Hapus DNP</Button>
          </div>
        </div>
      </Card>
      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Daftar File" />
        <Divider/>
        <List>
          {this.props.formData.dnp.files.map((d, i) => <ListItem key={i} onClick={()=> {this._downloadDnp(d)}} primaryText={d} />)}
        </List>
      </Card>

      <br/>
      <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Data Transaksi" />
      <Divider/>
      {/* <DataTable plain>
        <TableBody>
          <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
            <TableColumn className=' md-text-right'>
              <Button primary flat onClick={this._pilihDataTransaksi}>Pilih Data Transaksi</Button>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable> */}
      <DnpPelimpahanView dnpId={this.props.formData.dnp.id} lhpId={this.props.formData.id} addPelimpahan={(fetchData)=> this._tambahDnpItem(fetchData)} />
    </Card>
    </div>
    }
    </div>
  }

  rekKoranView() {
    return <div style={{marginTop: -14}}>
      {!this.props.formData.rekeningKoran.id && <Card>
        <div className='md-grid' >
          <div className     = 'md-cell md-cell--12 md-text-center'>
           <Button raised primary onClick={() => this._buatRekeningKoran()}>Generate Rekening Koran</Button>
          </div>
        </div>
      </Card>}
      {/* <br/> */}
      {this.props.formData.rekeningKoran.id && <div><Card>
        <div className='md-grid' >

        <Field
          label         = {"Status Rekening Koran"}
          name          = 'rekeningKoran.status'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
        />
          <div className     = 'md-cell md-cell--6'>
            <Field
              label         = {"Tanggal dan Jam Pembuatan"}
              name          = 'rekeningKoran.createdAt'
              component     = {Textfield}
            />
            <Field
              label         = {"Tanggal Buku"}
              name          = 'rekeningKoran.bookDate'
              component     = {Textfield}
            />
            <Field
              label         = {"Kode Bank"}
              name          = 'rekeningKoran.bankName'
              component     = {Textfield}
            />
            <Field
              label         = {"Nomor Rekening Penerimaan"}
              name          = 'rekeningKoran.accountNumber'
              component     = {Textfield}
            />
            <Field
              label         = {"Mata Uang"}
              name          = 'rekeningKoran.currency'
              component     = {Textfield}
            />
          </div>
          <div className     = 'md-cell md-cell--6'>
            <Field
              label         = {"Saldo Awal"}
              name          = 'rekeningKoran.openBalance'
              component     = {Textfield}
            />
            <Field
              label         = {"Saldo Akhir"}
              name          = 'rekeningKoran.closeBalance'
              component     = {Textfield}
            />
            <Field
              label         = {"Mutasi Debit"}
              name          = 'rekeningKoran.debitMutation'
              component     = {Textfield}
            />
            <Field
              label         = {"Mutasi Kredit"}
              name          = 'rekeningKoran.creditMutation'
              component     = {Textfield}
            />
            <Field
              label         = {"Total Transaksi"}
              name          = 'rekeningKoran.transactionTotal'
              component     = {Textfield}
            />
          </div>

          {/* <Field
            label         = {"Nama File"}
            name          = 'rekeningKoran.fileName'
            className     = 'md-cell md-cell--8'
            component     = {Textfield}
          />
          <div className="md-cell md-cell--4" style={{paddingTop: 20}}>
          <Button primary raised onClick={this._lihatLhp}>View</Button>
          </div> */}
          <Field
            label         = {"Tanggal dan Jam Lapor"}
            name          = 'rekeningKoran.submissionDate'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            disabled
          />

          <div className='md-text-right md-cell md-cell--12'>
            <Button style={{ marginLeft: 10}} secondary raised onClick={() => this._deleteRekeningKoran()}>Hapus Rekening Koran</Button>
          </div>

        </div>
      </Card>
      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Daftar File" />
        <Divider/>
        <List>
          {this.props.formData.rekeningKoran.files.map((d, i) => <ListItem key={i} onClick={()=> {this._downloadRekeningKoran(d)}} primaryText={d} />)}
        </List>
      </Card>

      <br/>
      <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Data Transaksi" />
      <Divider/>
      <RekeningKoranView lphId={this.props.formData.rekeningKoran.id} />
      {/* <DataTable plain>
        <TableHeader>
          <TableRow>
            <TableColumn className=' md-text-right'>Tanggal & Jam TRX</TableColumn>
            <TableColumn className=' md-text-left'>Kode Billing</TableColumn>
            <TableColumn className=' md-text-left'>Nomor Sakti</TableColumn>
            <TableColumn className=' md-text-left'>Debit</TableColumn>
            <TableColumn className=' md-text-left'>Kredit</TableColumn>
            <TableColumn className=' md-text-left'>Saldo</TableColumn>
            <TableColumn className=' md-text-left'>Keterangan</TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableColumn className=' md-text-left'>27/11/1990</TableColumn>
            <TableColumn className=' md-text-right'>21/11/2020</TableColumn>
            <TableColumn className=' md-text-left'>Rp. 1.000.000,-</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>BRI</TableColumn>
            <TableColumn className=' md-text-left'>123123123</TableColumn>
            <TableColumn className=' md-text-left'>-</TableColumn>
            <TableColumn className='md-text-center'>
              <Button icon primary style={{float: 'left'}}>visibility</Button>
              <Button icon secondary style={{float: 'left'}}>delete</Button>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable> */}
    </Card>
    </div>
    }
    </div>
  }

  rekonTrxView() {
    return <div style={{marginTop: -14}}>
      <Card>

      <CardTitle title='' style={{padding: '10px 16px'}} subtitle="CA Only" />
      <Divider/>
      <div className="md-grid">
        <div className     = 'md-cell md-cell--12 md-text-center'>
          {!this.props.formData.recon.caOnly.id && <Button primary raised onClick={() => this._import("CA Only")}>Import CA Only Data</Button>}

          {this.props.formData.recon.caOnly.id && <Button primary raised onClick={() => this._updateRecon("caOnly")}>Update Data Transaksi</Button>}
          {this.props.formData.recon.caOnly.id && <Button style={{ marginLeft: 10}} secondary raised onClick={() => this._deleteRecon("caOnly")}>Hapus</Button>}
        </div>
      </div>

      {this.props.formData.recon.caOnly.id &&
        <div>
          <div className="md-grid">
          <Field
            label         = {"Nama File"}
            name          = 'recon.caOnly.fileName'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {"Tipe File Rekon"}
            name          = 'recon.caOnly.reconType'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {"Status"}
            name          = 'recon.caOnly.status'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          </div>
          <CaOnlyView setClick={met => this.caInit = met} id={this.props.formData.recon.caOnly.id} />
        </div>
      }
      </Card>
      <br/>
      <Card>

        <CardTitle title='' style={{padding: '10px 16px'}} subtitle="SA/Settlement Only" />
        <Divider/>
        <div className="md-grid">
          <div className     = 'md-cell md-cell--12 md-text-center'>
          {!this.props.formData.recon.saOnly.id &&<Button primary raised onClick={() => this._import("SA Only")}>Import SA Only Data</Button>}
          {this.props.formData.recon.saOnly.id && <Button primary raised onClick={() => this._updateRecon("saOnly")}>Update Data Transaksi</Button>}
            {this.props.formData.recon.saOnly.id && <Button style={{ marginLeft: 10}} secondary raised onClick={() => this._deleteRecon("saOnly")}>Hapus</Button>}

          </div>
        </div>

        {this.props.formData.recon.saOnly.id &&
          <div>
            <div className="md-grid">
            <Field
              label         = {"Nama File"}
              name          = 'recon.saOnly.fileName'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
            />
            <Field
              label         = {"Tipe File Rekon"}
              name          = 'recon.saOnly.reconType'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {"Status"}
              name          = 'recon.saOnly.status'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            </div>
            <SaOnlyView setClick={met => this.saInit = met} id={this.props.formData.recon.saOnly.id} />

            {/* <Button primary onClick={()=>  this.saInit()}>asdasd</Button> */}
          </div>
        }
      </Card>
      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle="Failed" />
        <Divider/>
        <div className="md-grid">
          <div className     = 'md-cell md-cell--12 md-text-center'>
            {!this.props.formData.recon.failed.id &&<Button primary raised onClick={() => this._import("Failed")}>Import Failed Data</Button>}
            {this.props.formData.recon.failed.id && <Button  primary raised onClick={() => this._updateRecon("failed")}>Update Data Transaksi</Button>}
            {this.props.formData.recon.failed.id && <Button style={{ marginLeft: 10}} secondary raised onClick={() => this._deleteRecon("failed")}>Hapus</Button>}

          </div>
        </div>


        {this.props.formData.recon.failed.id &&
          <div>
            <div className="md-grid">
            <Field
              label         = {"Nama File"}
              name          = 'recon.failed.fileName'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
            />
            <Field
              label         = {"Tipe File Rekon"}
              name          = 'recon.failed.reconType'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {"Status"}
              name          = 'recon.failed.status'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            </div>
            <FailedView setClick={met => this.failedInit = met} id={this.props.formData.recon.failed.id} />
          </div>
        }
      </Card>
    </div>
  }

  rekonKasView() {
    return <div style={{marginTop: -14}}>
      <Card>
        <div className="md-grid">
          <div className     = 'md-cell md-cell--12 md-text-center'>
            {!this.props.formData.cashRecon.id && <Button primary raised onClick={() => this._addCashRecon("CA Only")}>Input data kekurangan/kelebihan pelimpahan</Button>}
          </div>
        </div>
        {this.props.formData.cashRecon.id &&
        <div className="md-grid">
           <Field
              label         = {"Hasil Rekon"}
              name          = 'cashRecon.reconType'
              className     ="md-cell md-cell--12"
              component     = {Searchfield}
              options       = {[{id: 'Kelebihan Pelimpahan', name: 'Kelebihan Pelimpahan'}, {id: 'Kekurangan Pelimpahan', name: 'Kekurangan Pelimpahan'}]}
            />
          <Field
              label         = {"Nilai"}
              name          = 'cashRecon.amount'
              className     ="md-cell md-cell--12"
              component     = {TextfieldMask}
              money = {","}
            />
          <Field
              label         = {"Data Pelimpahan"}
              name          = 'cashRecon.pelimpahanId'
              className     ="md-cell md-cell--12"
              component     = {Textfield}
              rightIcon={<Button primary raised onClick={this._dataPelimpahanPicker}>Pilih Data Pelimpahan</Button>}
            />
          <Field
              label         = {"Status"}
              name          = 'cashRecon.status'
              className     ="md-cell md-cell--12"
              component     = {Textfield}
              disabled
            />

            <div className="md-cell md-cell--12  md-text-right">

              <Button primary flat onClick={() => this._hapusCashRecon("CA Only")}>Hapus</Button>
              <Button primary raised onClick={() => this._postCashRecon("CA Only")}>Simpan</Button>
            </div>
        </div>
        }
      </Card>
    </div>
  }

  _pilihDataRekKoran = async()=> {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Pilih Data Rekening Koran',
      initialValue: {},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: <div>
      <DataTable>
        <TableHeader>
          <TableRow>
          <TableColumn className=' md-text-left'>Tgl & Jam Pembuatan</TableColumn>
            <TableColumn className=' md-text-left'>Tgl Buku</TableColumn>
            <TableColumn className=' md-text-right'>Kode Bank</TableColumn>
            <TableColumn className=' md-text-left'>NO Rek</TableColumn>
            <TableColumn className=' md-text-left'>Mata Uang</TableColumn>
            <TableColumn className=' md-text-left'>Saldo Awal</TableColumn>
            <TableColumn className=' md-text-left'>Saldo Akhir</TableColumn>
            <TableColumn className=' md-text-left'>Mutasi Debit</TableColumn>
            <TableColumn className=' md-text-left'>Mutasi Kredit</TableColumn>
            <TableColumn className=' md-text-left'>Total Transaksi</TableColumn>
            <TableColumn className=' md-text-left'>Status</TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableColumn className=' md-text-left'>27/11/1990</TableColumn>
            <TableColumn className=' md-text-right'>21/11/2020</TableColumn>
            <TableColumn className=' md-text-left'>Rp. 1.000.000,-</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>BRI</TableColumn>
            <TableColumn className=' md-text-left'>123123123</TableColumn>
            <TableColumn className=' md-text-left'>Refunded</TableColumn>
            <TableColumn className=' md-text-left'>-</TableColumn>
            <TableColumn className='md-text-center'>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable>
      </div>
    }))
  }

  _pilihDataTransaksi = async()=> {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Pilih Data Transaksi',
      initialValue: {},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: <div>
      <DataTable>
        <TableHeader>
          <TableRow>
            <TableColumn className=' md-text-left'>Kode Billing</TableColumn>
            <TableColumn className=' md-text-right'>Tipe Billing</TableColumn>
            <TableColumn className=' md-text-left'>Nilai</TableColumn>
            <TableColumn className=' md-text-left'>NTL</TableColumn>
            <TableColumn className=' md-text-left'>NTPN</TableColumn>
            <TableColumn className=' md-text-left'>Tgl TRX</TableColumn>
            <TableColumn className=' md-text-left'>Tgl Buku</TableColumn>
            <TableColumn className=' md-text-left'>Status</TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableColumn className=' md-text-left'>27/11/1990</TableColumn>
            <TableColumn className=' md-text-right'>21/11/2020</TableColumn>
            <TableColumn className=' md-text-left'>Rp. 1.000.000,-</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>BRI</TableColumn>
            <TableColumn className=' md-text-left'>123123123</TableColumn>
            <TableColumn className=' md-text-left'>Refunded</TableColumn>
            <TableColumn className=' md-text-left'>-</TableColumn>
            <TableColumn className='md-text-center'>
              <Button icon primary style={{float: 'left'}}>visibility</Button>
              <Button icon secondary style={{float: 'left'}}>delete</Button>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable>
      </div>
    }))
  }

   _pilihDataRefund = async()=> {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Pilih Data Pelimpahan',
      initialValue: {},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: <div>
      <DataTable >
        <TableHeader>
          <TableRow>
          <TableColumn className=' md-text-left'>Kode Billing</TableColumn>
            <TableColumn className=' md-text-right'>Tipe Billing</TableColumn>
            <TableColumn className=' md-text-left'>Nama Pembayaran</TableColumn>
            <TableColumn className=' md-text-left'>Jml Setor</TableColumn>
            <TableColumn className=' md-text-left'>Tgl Bayar</TableColumn>
            <TableColumn className=' md-text-left'>Tgl Refund</TableColumn>
            <TableColumn className=' md-text-left'>Bank</TableColumn>
            <TableColumn className=' md-text-left'>Norek</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
          <TableColumn className=' md-text-left'>27/11/1990</TableColumn>
            <TableColumn className=' md-text-right'>21/11/2020</TableColumn>
            <TableColumn className=' md-text-left'>Rp. 1.000.000,-</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>BRI</TableColumn>
            <TableColumn className=' md-text-left'>123123123</TableColumn>
          </TableRow>
        </TableBody>
      </DataTable>
      </div>
    }))
  }

  _pilihDataPelimpahan = async()=> {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Pilih Data Pelimpahan',
      initialValue: {},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: <div>
      <DataTable >
        <TableHeader>
          <TableRow>
            <TableColumn className=' md-text-left'>Tgl Pelimpahan</TableColumn>
            <TableColumn className=' md-text-right'>Tgl Buku</TableColumn>
            <TableColumn className=' md-text-left'>Nilai</TableColumn>
            <TableColumn className=' md-text-left'>TRN</TableColumn>
            <TableColumn className=' md-text-left'></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableColumn className=' md-text-left'>27/11/1990</TableColumn>
            <TableColumn className=' md-text-right'>21/11/2020</TableColumn>
            <TableColumn className=' md-text-left'>Rp. 1.000.000,-</TableColumn>
            <TableColumn className=' md-text-left'>123123</TableColumn>
            <TableColumn className=' md-text-left'>-</TableColumn>
            <TableColumn className='md-text-center'>
            </TableColumn>
          </TableRow>
        </TableBody>
      </DataTable>
      </div>
    }))
  }

  _lihatLhp = async () => {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Pilih Data Pelimpahan',
      initialValue: {},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: <div className='md-grid'>
        <Field
            label         = {"Nama File"}
            name          = 'kodeBilling'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            disabled
          />

        <Field
            label         = {"Isi File"}
            name          = 'kodeBilling'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            rows       = {4}
            disabled
          />
      </div>
    }))
  }

  _verifikasiData = async()=> {
    var res = await this.service.api.verify(this.props.match.params.id)

    var token = http.defaults.headers.common['Authorization'].replace("Bearer ", "")
    var url = (hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl+`/websocket/verify/${this.props.match.params.id}?access_token=${token}`).replace('http', 'ws').replace('https', 'wss')
    var socket = new WebSocket(url)

    socket.onopen = async (e) => {
      iziToast.success({title: 'Info', message: 'Data Sedang diverifikasi'})
      console.log(`[/websocket/verify/${this.props.match.params.id}][open] Connection established`);

      var p = await this.context.showDialog((props, res, rej) =>({
        title: 'Verifikasi Data',
        initialValue: {},
        height: 'auto',
        width: 'auto',
        cancelText: 'close',
        disableOk: true,
        // contentStyle: {overflow: 'inherit'},
        text: <div className="md-grid">
          <div className="md-cell md-cell--12">
            <p>Data Sedang diverifikasi mohon ditunggu</p>
          </div>
          <Field
              label         = {"Verifikasi Tanggal Buku"}
              name          = 'verifyBookDate'
              className     = 'md-cell md-cell--12'
              component     = {Checkbox}
              disabled
            />
          <Field
              label         = {"Verifikasi Jumlah Pelimpahan"}
              name          = 'verifyPelimpahanCount'
              className     = 'md-cell md-cell--12'
              component     = {Checkbox}
              disabled
            />
          <Field
            label         = {"Verifikasi Total Jumlah Transaksi"}
            name          = 'verifyTransactionTotal'
            className     = 'md-cell md-cell--12'
            component     = {Checkbox}
            disabled
          />
          <Field
            label         = {"Verifikasi Jumlah Penerima"}
            name          = 'verifyTransactionAmount'
            className     = 'md-cell md-cell--12'
            component     = {Checkbox}
            disabled
          />
          <Field
            label         = {"Verifikasi Selesai"}
            name          = 'verifyPelaporanIsDone'
            className     = 'md-cell md-cell--12'
            component     = {Checkbox}
            disabled
          />
        </div>
      }))
    };

    socket.onmessage = (event) => {
      console.log(`[/websocket/verify/${this.props.match.params.id}][message] Data received from server: ${event.data}`);
      if(event.data.search("{") >= 0) {
        var data = JSON.parse(event.data)

        this.props.dispatch(change('global_dialog', 'verifyBookDate', data.verifyBookDate))
        this.props.dispatch(change('global_dialog', 'verifyTransactionTotal', data.verifyTransactionTotal))
        this.props.dispatch(change('global_dialog', 'verifyTransactionAmount', data.verifyTransactionAmount))
        this.props.dispatch(change('global_dialog', 'verifyPelaporanIsDone', data.verifyPelaporanIsDone))
        this.props.dispatch(change('global_dialog', 'verifyPelimpahanCount', data.verifyPelimpahanCount))

        if(data.verifyPelaporanIsDone) {
          this.initData()
        }
      }
    };

    socket.onclose = (event)=> {
      if (event.wasClean) {
        console.log(`[/websocket/verify/${this.props.match.params.id}][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.log(`[/websocket/verify/${this.props.match.params.id}][close] Connection died`);
      }
    };

    socket.onerror = (error) => {
      console.log(`[error] ${error.message}`);
    };
  }

  _generateFileLapor = async()=> {
    var res = await this.service.api.generateFile(this.props.match.params.id)

    var token = http.defaults.headers.common['Authorization'].replace("Bearer ", "")
    var url = (hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl+`/websocket/generate_file/${this.props.match.params.id}?access_token=${token}`).replace('http', 'ws').replace('https', 'wss')
    var socket = new WebSocket(url)

    socket.onopen = async (e) => {
      iziToast.success({title: 'Info', message: 'File Sedang digenerate'})
      console.log(`[/websocket/generate_file/${this.props.match.params.id}][open] Connection established`);
      var p = await this.context.showDialog((props, res, rej) =>({
        title: 'Generate File Lapor',
        initialValue: {},
        height: 'auto',
        width: 'auto',
        cancelText: 'close',
        disableOk: true,
        // contentStyle: {overflow: 'inherit'},
        text: <div className="md-grid">
          <Field
              label         = {"File DNP berhasil di buat"}
              name          = 'generatePelaporanDNP'
              className     = 'md-cell md-cell--12'
              component     = {Checkbox}
              disabled
            />
          <Field
              label         = {"File Rekening Koran Berhasil di buat"}
              name          = 'generatePelaporanRekeningKoran'
              className     = 'md-cell md-cell--12'
              component     = {Checkbox}
              disabled
            />
          <Field
            label         = {"File LHP Berhasil di buat"}
            name          = 'generatePelaporanLHP'
            className     = 'md-cell md-cell--12'
            component     = {Checkbox}
            disabled
          />
          <Field
            label         = {"Pembuatan File Selesai"}
            name          = 'generatePelaporanIsDone'
            className     = 'md-cell md-cell--12'
            component     = {Checkbox}
            disabled
          />
        </div>
      }))
    };

    socket.onmessage = (event) => {
      console.log(`[/websocket/generate_file/${this.props.match.params.id}][message] Data received from server: ${event.data}`);
      if(event.data.search("{") >= 0) {
        var data = JSON.parse(event.data)
        this.props.dispatch(change('global_dialog', 'generatePelaporanLHP', data.generatePelaporanLHP))
        this.props.dispatch(change('global_dialog', 'generatePelaporanDNP', data.generatePelaporanDNP))
        this.props.dispatch(change('global_dialog', 'generatePelaporanRekeningKoran', data.generatePelaporanRekeningKoran))
        this.props.dispatch(change('global_dialog', 'generatePelaporanIsDone', data.generatePelaporanIsDone))

        if(data.generatePelaporanIsDone) {
          this.initData()
        }
      }
    };

    socket.onclose = (event)=> {
      if (event.wasClean) {
        console.log(`[/websocket/generate_file/${this.props.match.params.id}][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.log(`[/websocket/generate_file/${this.props.match.params.id}][close] Connection died`);
      }
    };

    socket.onerror = (error) => {
      console.log(`[error] ${error.message}`);
    };
  }

  _kirimLaporan = async()=> {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Submit File Lapor',
      initialValue: {},
      height: 'auto',
      width: 400,
      contentStyle: {overflow: 'inherit'},
      text: <div className="md-grid">
        <Field
            label         = {"Tanggal Lapor"}
            name          = 'localDateTime'
            className     = 'md-cell md-cell--12'
            component     = {Datetimepicker}
          />
      </div>
    }))

    console.log(p, 'asd')

    if(p) {
      p.localDateTime = p.localDateTime.replace(" ", "T")
      await this.service.api.submitFileLapor(this.props.formData.id, p)
      this.initData()
    }
  }

  async _buatDnp() {
    var res = await this.service.api.createDnp(this.props.match.params.id)
    if(res.data) {
      this.initData()
    }
  }

  async _deleteDnp() {

    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Hapus data DNP ',
      initialValue: {d : 'asd'},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan menghapus data ini?"
    }))

    if(p) {
      await this.service.api.deleteDnp(this.props.formData.dnp.id)
      this.initData()
    }
  }

  async _buatRekeningKoran() {
    var res = await this.service.api.createRekeningKoran(this.props.match.params.id)
    if(res.data) {
      this.initData()
    }
  }

  async _deleteRekeningKoran() {

    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Hapus data Rekening Koran ',
      initialValue: {d : 'asd'},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan menghapus data ini?"
    }))

    if(p) {
      await this.service.api.deleteRekeningKoran(this.props.formData.rekeningKoran.id)
      this.initData()
    }
  }

  async _reloadStatus() {

  }

  async _downloadLpl(item) {
    // await this.service.api.downloadError(item, true);
    let res = await this.service.api.downloadLpl(item);
    let filename = item
    download(res.data, filename);
  }

  async _downloadDnp(item) {
    // await this.service.api.downloadError(item, true);
    let res = await this.service.api.downloadDnp(item);
    let filename = item
    download(res.data, filename);
  }

  async _downloadRekeningKoran(item) {
    // await this.service.api.downloadError(item, true);
    let res = await this.service.api.downloadRekeningKoran(item);
    let filename = item
    download(res.data, filename);
  }

  async _import(type) {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Import ' +type,
      initialValue: {d : 'asd'},
      height: 'auto',
      width: 'auto',
      okText: 'Submit',
      // contentStyle: {overflow: 'inherit'},
      text: <div className="md-grid">
        <Field
            label         = {"File"}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
          />
      </div>
    }))

    if(p) {

      let data = new FormData();
      data.append("file", p.file)
      data.append("lhpId", this.props.match.params.id)
      data.append("reconType", type)
      data.append("fileName", p.file.name)

      var post = await this.service.api.uploadPaymentRecon(data)

      var token = http.defaults.headers.common['Authorization'].replace("Bearer ", "")
      var url = (hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl+`/websocket/payment_recon/${post.data.id}?access_token=${token}`).replace('http', 'ws').replace('https', 'wss')
      var socket = new WebSocket(url)



      var progress = this.context.showDialog((props, res, rej) =>({
        title: 'Mohon ditunggu',
        initialValue: {progress: 0},
        height: 'auto',
        width: 400,
        okText: 'Submit',
        disableOk: true,
        cancelText: 'Close',
        // contentStyle: {overflow: 'inherit'},
        text: <div className="md-grid">
          <Field
              label         = {"Nama File"}
              name          = 'progress'
              className     = 'md-cell md-cell--12'
              component     = {Progress}
            />
        </div>
      }))

      socket.onopen = async (e) => {
        iziToast.success({title: 'Info', message: 'File sedang diunggah/diupload'})
        console.log(`[/websocket/payment_recon/${post.data.id}][open] Connection established`);
      }

      socket.onmessage = (event) => {
        console.log(`[/websocket/payment_recon/${post.data.id}][message] Data received from server: ${event.data}`);
        // this.initData()
        if(event.data.search("{") >= 0) {
          var data = JSON.parse(event.data)
          this.props.dispatch(change('global_dialog', 'progress', (data.imported/data.size)*100))

          if(data.importDone) {
            this.initData()
          }
        }
      };

      socket.onclose = (event)=> {
        if (event.wasClean) {
          console.log(`[/websocket/payment_recon/${post.data.id}][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
          console.log(`[/websocket/payment_recon/${post.data.id}][close] Connection died`);
        }
      };

      socket.onerror = (error) => {
        console.log(`[error] ${error.message}`);
      };
    }
  }

  async _deleteRecon(type) {

    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Hapus data recon ',
      initialValue: {d : 'asd'},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan menghapus data ini?"
    }))

    if(p) {
      await this.service.api.deleteRekonTrx(this.props.formData.recon[type].id)
      this.initData()
    }
  }

  async _updateRecon(type) {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Update Data ',
      initialValue: {d : 'asd'},
      height: 'auto',
      width: 'auto',
      // contentStyle: {overflow: 'inherit'},
      text: "Apakah anda yakin akan meng-update data ini?"
    }))

    if(p) {
      var res = await this.service.api.updateRekonTrx(this.props.formData.recon[type].id)

      var token = http.defaults.headers.common['Authorization'].replace("Bearer ", "")
      var url = (hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl+`/websocket/payment_recon_apply/${this.props.formData.recon[type].id}?access_token=${token}`).replace('http', 'ws').replace('https', 'wss')
      var socket = new WebSocket(url)
      var progress = this.context.showDialog((props, res, rej) =>({
        title: 'Mohon ditunggu',
        initialValue: {progress: 0},
        height: 'auto',
        width: 400,
        okText: 'Submit',
        disableOk: true,
        cancelText: 'Close',
        // contentStyle: {overflow: 'inherit'},
        text: <div className="md-grid">
          <Field
              label         = {"Nama File"}
              name          = 'progress'
              className     = 'md-cell md-cell--12'
              component     = {Progress}
            />
        </div>
      }))

      var progressNum = 0;
      var interval = setInterval(()=> {
        this.props.dispatch(change('global_dialog', 'progress', progressNum))
      }, 1000)

      socket.onopen = async (e) => {
        iziToast.success({title: 'Info', message: 'File Sedang diupdate'})

        console.log(`[/websocket/payment_recon_apply/${this.props.formData.recon[type].id}][open] Connection established`);
      }

      socket.onmessage = async (event) => {
        console.log(`[/websocket/payment_recon_apply/${this.props.formData.recon[type].id}][message] Data received from server: ${event.data}`);
        // this.initData()
        if(event.data.search("{") >= 0) {
          var data = JSON.parse(event.data)
          progressNum = (data.processed/data.count)*100

          if(data.paymentReconApplyDone) {
            clearTimeout(interval)
            this.props.dispatch(change('global_dialog', 'progress', 100))
            // TODO
            if(type == 'saOnly') {
              this.saInit()
            } else if(type == 'caOnly') {
              this.caInit()
            } else {
              this.failedInit()
            }

          }
        }
      };

      socket.onclose = (event)=> {
        if (event.wasClean) {
          console.log(`[/websocket/payment_recon_apply/${this.props.formData.recon[type].id}][close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
          console.log(`[/websocket/payment_recon_apply/${this.props.formData.recon[type].id}][close] Connection died`);
        }
      };

      socket.onerror = (error) => {
        console.log(`[error] ${error.message}`);
      };
    }
  }

  _addCashRecon = ()=> {
    this.props.change('cashRecon.id', "add")
  }

  _dataPelimpahanPicker = async ()=> {
    var picker = await this.context.showDialog((props, res, rej) =>({
      // title: 'Pilih Pelimpahan',
      initialValue: {progress: 0},
      height: 'auto',
      width: 800,
      okText: 'Pilih',
      cancelText: 'Close',
      contentStyle: {overflow: 'inherit', padding: 0},
      text: <div>
        <PickerPelimpahanView  />
        <br/>
      </div>
    }))

    if(picker) {
      if( this.props.table.selected.length > 0) {
        this.props.change('cashRecon.pelimpahanId', this.props.table.selected[0].id)
        this.props.change('cashRecon.pelimpahanName', this.props.table.selected[0].tglBuku+" (Sakti: "+this.props.table.selected[0].nomorSakti+")")
      }
    }
  }

  _postCashRecon = async ()=> {
    var picker = await this.context.showDialog((props, res, rej) =>({
      title: 'Simpan Rekon Kas?',
      initialValue: {progress: 0},
      height: 'auto',
      text: "apakah anda yakin akan menyimpan rekon kas ini?"
    }))

    if(picker) {
      var data = {...this.props.formData.cashRecon}
      if(data.id == "add") delete data.id
      data.lhpId = parseInt(this.props.match.params.id)

      await this.service.api.postCashRekon(data)
      iziToast.success({title: 'Info', message: 'Rekon Kas telah di simpan'})
      this.initData()
    }

  }

  _hapusCashRecon = async ()=> {
    var picker = await this.context.showDialog((props, res, rej) =>({
      title: 'Hapus Rekon Kas?',
      initialValue: {progress: 0},
      height: 'auto',
      text: "apakah anda yakin akan menghapus rekon kas ini?"
    }))

    if(picker) {
      await this.service.api.deleteCashRekon(this.props.match.params.id)
      iziToast.success({title: 'Info', message: 'Rekon Kas telah di hapus'})
      this.initData()
    }
  }

  _tambahPelimpahanLhp = async (fetchData) => {
    var p = await this.context.showDialog((props, res, rej) =>({
      // title: 'Tambah Pembayaran',
      initialValue: {},
      height: 'auto',
      width: 800,
      contentStyle: {overflow: 'inherit', padding: 0},
      okText: 'Tambah',
      text: <PickerPelimpahanView lhpId={this.props.formData.id} />

    }))

    if(p) {
      if( this.props.table.selected.length > 0) {
        await this.service.api.putPelimpahan({
          id: this.props.formData.id,
          pelimpahanIds: this.props.table.selected.map(x => x.id)
        })

        fetchData()
      }
    }

  }

  _tambahDnpItem = async (fetchData) => {
    var p = await this.context.showDialog((props, res, rej) =>({
      // title: 'Tambah Pembayaran',
      initialValue: {},
      height: 'auto',
      width: 800,
      contentStyle: {overflow: 'inherit', padding: 0},
      okText: 'Tambah',
      text: <PickerPembayaranView dnpId={this.props.formData.dnp.id} />

    }))

    if(p) {
      if( this.props.tablePembayaranPicker.selected.length > 0) {
        await this.service.api.dnpAddItem({
          id: this.props.formData.id,
          mpnPaymentIds: this.props.tablePembayaranPicker.selected.map(x => x.id)
        })

        fetchData()
      }
    }

  }


}
