
import * as validationM from './validation';
import TextfieldM from './Textfield';
import TextfieldMaskM from './TextfieldMask';
import SearchfieldM from './Searchfield';
import SwitchM from './Switch';
import CheckboxM from './Checkbox';
import RadioM from './Radio';
import DatepickerM from './Datepicker';
import MultiselectM from './Multiselect'
import convertM from './convert';
import FileinputM from './Fileinput';
import ProgressM from './Progress';
import DatetimepickerM from './Datetimepicker'
import DatetimepickerEditM from './DatetimepickerEdit'

export const validation = validationM;
export const Textfield = TextfieldM;
export const TextfieldMask = TextfieldMaskM;
export const Searchfield = SearchfieldM;
export const Switch = SwitchM;
export const Checkbox = CheckboxM;
export const Radio = RadioM;
export const Datepicker = DatepickerM;
export const Multiselect = MultiselectM;
export const convert = convertM;
export const Fileinput = FileinputM;
export const Progress = ProgressM
export const Datetimepicker = DatetimepickerM
export const DatetimepickerEdit = DatetimepickerEditM
