/**
 * Created by dwiargo on 2/21/18.
 */

import React, { Component } from "react";
import { ErrorPage } from "../../libs/react-mpk";
import queryString from "query-string";

class Error extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    console.log("adsasdasdas", queryString.parseUrl(window.location.href));
    let parsedUrl = queryString.parseUrl(window.location.href);
    this.setState(parsedUrl.query);
  }

  render() {
    let { status, statusText, errorMessage, redirectUri } = this.state;
    return (
      <ErrorPage
        status={status}
        statusText={statusText}
        errorMessage={errorMessage}
        redirectUri={redirectUri ? redirectUri : null}
      />
    );
  }
}

export default Error;
