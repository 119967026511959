import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import LaporanBulananService from './LaporanBulananService';
import ListView from '../../../components/entity/listView';

@connect((state) => ({
  ...LaporanBulananService.stateConnectSetting()(state),
  auth        : state.auth.user
}), LaporanBulananService.actionConnectSetting())
export default class LaporanBulananView extends ListView {
  editDialog  = false;
  addDialog   = false;
  service     = LaporanBulananService
  stream      = true;

  columns=[
    {isSortable: false,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.month`,           value: (d)=> d.month, type: "func", searchField: 'month'},
    {isSortable: false,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.year`,       value: (d)=> d.year, type: "func", searchField: 'year'},
    {isSortable: false,  show: true,   isSearchable:false,  label: `entities.${this.service.name}.total`,          value: (d)=> d.total, type: "func"},
    // {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.keterangan`,           value: "deskripsi"}
  ]

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showDialogConfirmDeleteFinishFailedSelected:false,
      showForm:false,
      formData: {},
      data: [],
      permissionMap: {}
    }
  }

  componentDidMount() {
    this.firstCall = false;

    var pList = []
    if(this.props.auth && this.props.auth.servicePermission) pList = this.props.auth.servicePermission
    var pMap = pList.reduce(function(pv, cv) {
      pv[cv.method+"__"+cv.clientPath] = cv
      return pv
    }, {})

    this.setState({permissionMap: pMap})
  }

  _barActions = () => {
    var arr = [

      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => {

          window.location.hash = window.location.hash.split('?')[0]
          this.fetchData()
        }
      }
    ]

    if(this.state.permissionMap['POST__/transaction_type']) {
      arr.push({
        label:'word.create',
        iconClassName:'mdi mdi-plus',
        onClick:() => this.addItem()
      })
    }

    return arr
  }


}
