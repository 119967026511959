/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from "react";
import {
  Appbar,
  Notification,
  rdxConnect,
  navService,
  Companies
} from "../../libs/react-mpk";
import utilsService from "./../../services/utilsService";
import companyService from "./../../services/companyService";
import { Button } from "react-md";
import t from "counterpart";
import cookie from "react-cookies";
import http from "../../services/http.service";

class Main extends Component {
  constructor() {
    super();
    this.state = {
      isReady: false
    };
  }

  componentWillMount() {
    if (cookie.load("MPK_ACCESS_TOKEN")) {
      http.defaults.headers.common["Authorization"] = `Bearer ${cookie.load(
        "MPK_ACCESS_TOKEN"
      )}`;
    }

    utilsService.setReqOptionsCompanyId(null);
    this.props.authActions.setProperties({
      company: null,
      meInCompany: null
    });
  }

  redirectToCompany = async (company, subscription) => {
    var companyId = company.id;
    var subscriptionId = subscription.id;
    // utilsService.setReqOptionsCompanyId(company.id);
    // utilsService.setReqOptionsSubscriptionId(subscription.id);
    // GET COMPANY
    // try {
    //   let res = await companyService.me(companyId, subscriptionId);
    //   let { company } = res.data;
    //   this.props.authActions.setProperties({
    //     company,
    //     meInCompany: res.data
    //   });
    //   navService.redirectTo(`/company/${company.id}/${subscription.id}/init`)
    // } catch(error){
    //   this.props.toastActions.izi(
    //     t.translate('word.failed'),
    //     error.message[this.props.global.loceleCode],
    //     'warning'
    //   )
    // }
    // GET COMPANY
  };

  render() {
    let { auth, global } = this.props;
    global.username = auth.user.username;
    return (
      <div className="mpk-layout column fill">
        <Appbar
          notification={<Notification newFeeds={0} />}
          appName="halona"
          showVersion
          fixedVersion={false}
          profileMenu={[
            {
              label: "Profile",
              materialIcon: "perm_identity",
              onClick: () => {
                console.log("bisa");
              }
            }
          ]}
          appsShortcut={[]}
          consoleShortcut={Object.keys(this.props.global.appConsole).map(
            key => {
              return this.props.global.appConsole[key];
            }
          )}
          translate={true}
        />
        {/* {auth.isLoggedIn && auth.user.role.isAdmin ? (
          <div className="mpk-layout fill mpk-relative-position mpk-content">
            <Sidebar
              isLoading={!auth.isLoggedIn}
              menus={auth.user.menus}
              getValueByLocaleCode={true}
            />
            <Router history={this.props.router.history}>
              {routes(this.props.actions, this.props.global)}
            </Router>
          </div>
        ) : ( */}
        <Companies
          className="mpk-animation slide-in"
          onSelectCompany={this.redirectToCompany}
          footer={
            <div className="mpk-margin-N margin-top margin-bottom">
              <Button
                raised
                secondary
                onClick={() => {
                  window.open(
                    `${this.props.global.appConsole.userAccount.url}/main/access/company/add`
                  );
                }}
              >
                {t.translate("custom.words.addNewCompany")}
              </Button>
            </div>
          }
        />
        {/* )} */}
      </div>
    );
  }
}

export default rdxConnect(Main);
