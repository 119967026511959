import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import RekeningKoranService from './RekeningKoran.service';
import ListView from '../../../components/entity/listView';

export class RekeningKoranView extends ListView {
  viewType    = 2;
  editDialog  = false;
  addDialog   = false;
  service     = RekeningKoranService
  stream      = true;

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tanggal`,           value: 'transactionDateTime',},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.kodeBilling`,       value: (d)=> {
      if(d.isDebit) {return ''} else {return d.referenceNo}
    }, type: 'func'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.kodeSakti`,         value: (d)=> {
      if(d.isCredit) {return ''} else {return d.transactionCode}
    }, type: 'func'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.debit`,             value: "isDebit", type: 'boolean'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.kredit`,            value: "isCredit", type: 'boolean'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.amount`,             value: "amount", type: 'number'},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.saldo`,             value: "balance", type: 'number'},

    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.keterangan`,        value: "localDateTime"}
  ]

  translate=false


  titleHeader() {
    return ""
  }


  beforeFetch(params) {
    params.id = this.props.lphId
  }

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}


export default connect(RekeningKoranService.stateConnectSetting(), RekeningKoranService.actionConnectSetting())(RekeningKoranView)
