import React, { Component } from 'react';
import LoaderInfo from '../components/LoaderInfo';

export default ((WrappedComponent, text='loading..', milis=1000) => {
  class timeout extends Component{
    constructor(){
      super();
      this.state = {
        onReady:false
      }
    }

    componentDidMount(){
      setTimeout(() => {this.setState({onReady:true})}, milis);
    }

    render(){
      let { onReady } = this.state;
      return (
        <div>{ onReady ? (
            <WrappedComponent {...this.props}/>
          ) : (
            <LoaderInfo text={text}/>
          )
        }
        </div>
      )
    }
  }

  return timeout;
}) 
