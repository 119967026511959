import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import PernyataanSaldoService from './PernyataanSaldoService';
import ListView from '../../../components/entity/listView';

export class PernyataanSaldoView extends ListView {
  viewType    = 2;
  editDialog  = false;
  addDialog   = false;
  service     = PernyataanSaldoService
  stream      = true;
  selectableRows=false

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.tanggal`,           value: "date"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.saldoAwal`,       value: "openingBalance", type: "number"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.debit`,          value: "debit", type: "number"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.credit`,          value: "credit", type: "number"},
    {isSortable: true,  show: true,   isSearchable:true,  label: `entities.${this.service.name}.saldoAkhir`,          value: "closeBalance", type: "number"}
  ]

  translate=false

  componentDidMount() {
    this.firstCall = false;
    console.log(this.props.setParent, 'asdasd')
    this.props.setParent('pernyataanSaldoFetch', this.fetchData)
  }


  titleHeader() {
    return ""
  }


  beforeFetch(params) {
    params.id = this.props.parentId
  }

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}


export default connect(PernyataanSaldoService.stateConnectSetting(), PernyataanSaldoService.actionConnectSetting())(PernyataanSaldoView)
