/**
 * @author Ismail Sunny
 * @email ismiletea@gmail.com
 * @create date 2018-01-17 10:39:10
 * @modify date 2018-01-17 10:39:10
 * @desc [description]
*/
import axios from 'axios';
import env from 'env';
import izitoast from 'izitoast';
import hosts from './hosts'
import utilsService from './utilsService'
import cookie from 'react-cookies';
import jwt from 'jsonwebtoken';
import moment from 'moment'

import { loadProgressBar } from 'axios-progress-bar'

loadProgressBar()

izitoast.settings({
  timeout: 10000,
  pauseOnHover: true,
  resetOnHover: true,
});

export const configureHttp = function() {
  axios.defaults.baseURL = env.host.default
  // EPPT CORE
  // let token = localStorage.getItem('token');
  // if(token) {
  //   token = JSON.parse(token);
  //   axios.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`
  //   axios.defaults.withCredentials = true;
  // }
  // EPPT CORE
  console.log('cookie', cookie.load('MPK_ACCESS_TOKEN'))
  if(cookie.load('MPK_ACCESS_TOKEN')){
    axios.defaults.headers.common['Authorization'] = `Bearer ${cookie.load('MPK_ACCESS_TOKEN')}`
  }

  //handling error
  axios.interceptors.request.use(function (config) {
    // console.log("URL ACCESS => ", config.url)
    // console.log("config =>", config)
    config.baseURL = ''
    if(config.url.indexOf('iams') != -1 || config.url.indexOf('portals') != -1){
      // Request Core
    } else if (config.url.indexOf('eppt') != -1){
      var location = window.location.pathname.split('init')[0].split('/')
      var companyId = location[2]
      var subId = location[3]
      config.headers['X-COMPANY-ID'] = companyId
      config.headers['X-SUBSCRIPTION-ID'] = subId
      config.headers['Authorization'] = "Bearer " + cookie.load('MPK_ACCESS_TOKEN')
    } else {
      var baseURL = env.host.default
      if(hosts.getAll() && hosts.getAll().apiGateway && hosts.getAll().apiGateway.host && hosts.getAll().apiGateway.baseUrl) baseURL = hosts.getAll().apiGateway.host + hosts.getAll().apiGateway.baseUrl
      var location = window.location.pathname.split('init')[0].split('/')
      var companyId = location[2]
      var subId = location[3]
      config.headers['X-COMPANY-ID'] = companyId
      config.headers['X-SUBSCRIPTION-ID'] = subId
      config.headers['Authorization'] = "Bearer " + cookie.load('MPK_ACCESS_TOKEN')
      // OFFLINE MODE
      // let jwtData = {
      //   iss: 'iss',
      //   aud: ['aud'],
      //   jti: 'jti',
      //   isValid: true,
      //   exp: moment().add(1,'day').valueOf(),
      //   iat: new Date().valueOf(),
      //   sub: 'jhon',
      //   metadata: {
      //     id:'1',
      //     name:'Jhon',
      //     email:'jhon@system.com',
      //     username:'jhon',
      //     npwp:'123456789012345',
      //     mobilePhone:'0192039821023',
      //     role:'user',
      //     isAdmin: 'true'
      //   }
      // };
      // config.headers['X-Pajakku-Profile'] = jwt.sign(jwtData, '2ykCBn9zaEDQHDuyUrbLhqo2kRBiV0wy4vRCPrkw');
      // OFFLINE MODE
      config.baseURL = baseURL
    }
    return config;
  }, function (error) {

    izitoast.error({title: 'Warning', message: error})
    return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    console.log(error.statusCode, error.response, "axios", error.config)
    // refresh token
    if(error.response && error.response.status && (error.response.status === 401)){
      if(error.response.data == 'Failed') {

      } else {
        if(error.response.headers['www-authenticate'] && error.response.headers['www-authenticate'].search('expired') >= 0) {
          const originalRequest = error.config;
          return axios.get('/api/iams/refreshToken/'+cookie.load('MPK_REFRESH_TOKEN'), {headers: {Authorization: 'Bearer '+cookie.load('MPK_ACCESS_TOKEN')}}).then(res => {
            cookie.save('MPK_ACCESS_TOKEN', res.data.access_token, {path: '/'})
            cookie.save('MPK_REFRESH_TOKEN', res.data.refresh_token, {path: '/'})

            axios.defaults.headers.common['Authorization'] = `Bearer ${cookie.load('MPK_ACCESS_TOKEN')}`

            return axios(originalRequest);
          }).catch(e => {
            // console.log(e, 'asdsad')
            // window.location = "error?errorMessage=Request failed with status code 401&status=401&statusText=Unauthorized"
          })
        }

      }

    }
    // if(error.response && error.response.status && (error.response.status === 400)){
    //   const originalRequest = error.config;
    //   if(!error.response.data) {
    //     window.location = "error?errorMessage=Request failed with status code 400&status=400&statusText=Bad Request"
    //   }
    // }


    // other error
    var err = error.message;
    var title = 'Warning';
    if(error.response) {
      if(error.response.data) {
        err = error.response.data.error_description;
        if(error.response.statusText) err = error.response.statusText;
        if(error.response.data.message)  err = error.response.data.message;
        if(error.response.data.detail) err = error.response.data.detail
        if(error.response.data.title) title = error.response.data.title
        if(error.response.data.error_description) err = error.response.data.error_description
        if(error.response.data.fieldErrors) {
          if(error.response.data.message === 'error.validation') {
            title = "Error Validation"
            err = '';
            error.response.data.fieldErrors.forEach(element => {
              err += `${element.field} ${element.message}<br/>`
            });
          }
        }

        if(error.response.data.errorMessage) {
          err = error.response.data.errorMessage.id
        }
      }
    }


    if( !(error.response && error.response.config && error.response.config.params && error.response.config.params.disableToast)) izitoast.error({title: title, message: err})

    return Promise.reject(error);
  });
}


export default axios;
