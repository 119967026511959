import moment from 'moment';
import ApiService from '../../../services/api.service';

class PembayaranAllService extends ApiService {
  name= 'payment-all';
  path= 'mpn_payment';

  // apiMockup= {
  //   find: [{
  //     kodeBilling: '123124124124',
  //     tipeBilling: 'DJP',
  //     namaPembayaran: 'Entah Sifulan',
  //     jmlSetoran: '220000',
  //     ntl: "123213123",
  //     ntpn: "123123123",
  //     tglBayar: '01/01/2019',
  //     tglBuku: '01/01/2019',
  //     status: 'PAID'
  //   }]
  // }

  api = {
    getRefund: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/refund/${id}`, {params: {disableToast: true}})
      return res
    },
    createRefund: async (id, data) => {
      var res = await this.http.post(`${this.apiLocation}/refund/${id}`, data)
      return res
    },
    uppdateRefund: async (id, data) => {
      var res = await this.http.put(`${this.apiLocation}/refund/${id}`,data)
      return res
    },
    deleteRefund: async (id) => {
      var res = await this.http.delete(`${this.apiLocation}/refund/${id}`)
      return res
    },
    refunded: async (id, data) => {
      var res = await this.http.put(`${this.apiLocation}/refund/${id}/refunded`, data)
      return res
    },
    resend: async (payment_code) => {
      var res = await this.http.get(`${this.apiLocation}/mpn_payment/${payment_code}/resend`)
      return res
    },
    getByCode: async (payment_code) => {
      var res = await this.http.get(`${this.apiLocation}/mpn_payment/manual/${payment_code}/code`)
      return res
    },
    getByOrderNo: async (orderNo) => {
      var res = await this.http.get(`${this.apiLocation}/mpn_payment/manual/${orderNo}`)
      return res
    },
    report: async (startDate, endDate)=> {
      var res = await this.http.get(`${this.apiLocation}/report/mpn_payment?startDate=${startDate}&endDate=${endDate}`)

      return res
    },
    submitManualPayment: async (orderNo, bookDate, transactionDate)=> {
      const transactionDateTimeStamp = new Date(transactionDate).getTime();
      const bookDateString = moment(bookDate).format("MMDD")
      var res = await this.http.get(`${this.apiLocation}/mpn_payment/manual/${orderNo}/${bookDateString}/${transactionDateTimeStamp}/pay`)
      return res
    },
    download: async (filename)=> {
      var res = await this.http.get(`${this.apiLocation}/report/${filename}/download`, {responseType: 'blob'})

      return res
    }
  }
}

export default new PembayaranAllService().init()
